import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {withStyles, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Album from './Album';
import DeleteConfirmationDialog from '../../../components/DeleteConfirmationDialog';
import TablePagination from '@material-ui/core/TablePagination';
import { checkAuthorization } from "../../../components/AuthService";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3
    },
    container: {
        margin: "1%"
    }
});

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    spacer: {
        flex: "1 1 100%"
    },
    title: {
        flex: "0 0 auto"
    }
});

class EnhancedTableToolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 'desc',
			orderBy: props.orderBy,
			selected: [],
			data: [],
			allData: [],
			page: 0,
			rowsPerPage: 5,
			confirmDialogOpen: false,
			deleteItems: []
		};
	}
	render() {
		const { classes, tableTitle } = this.props;

		return (
			<Fragment>
				<Toolbar className={classes.root}>
					<div className={classes.title}>
						<Typography variant="title" id="tableTitle">
							{tableTitle}
						</Typography>
					</div>
					<div className={classes.spacer} />
				</Toolbar>
			</Fragment>
		);
	}
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    tableTitle: PropTypes.string.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class AlbumsListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 'desc',
			orderBy: props.orderBy,
			selected: [],
			data: [],
			allData: [],
			page: 0,
			rowsPerPage: 5,
			confirmDialogOpen: false,
			deleteItems: []
		};
	}
	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};
	componentWillReceiveProps(nextProps) {
		if (nextProps.data !== this.state.data) {
			this.setState({ data: nextProps.data, allData: nextProps.data });
		}
	}
	handleDeleteSelected = () => {
		this.props.deleteSelectedAlbums(JSON.stringify(this.state.selected));
	};
	isSelected = id => this.state.selected.indexOf(id) !== -1;

	handleCloseConfirmation = () => {
		this.setState({ confirmDialogOpen: false, deleteItems: [] });
	};

	deleteAlbum = () => {
		let toBeDeleted = this.state.deleteItems;
		this.handleCloseConfirmation();
		this.props.handleDelete(toBeDeleted);
		this.setState({ selected: [] });
	};

	handleDeleteButton = toBeDeleted => {
		if (!checkAuthorization('can_delete_albums')) {
			this.props.handleSnackBarMessage("You don't have permission to delete albums", 'error');
		} else {
		this.handleOpenConfirmation(toBeDeleted);
		}
	};

	handleOpenConfirmation = toBeDeleted => {
		this.setState({ deleteItems: toBeDeleted, confirmDialogOpen: true });
	};
	render() {
		const { classes, data, handelGalleries, handleEdit } = this.props;
		const { order, orderBy, selected, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		return (
			<div>
				<DeleteConfirmationDialog
					isOpen={this.state.confirmDialogOpen}
					id={this.state.deleteItems}
					handleCloseDeleteConfirmationDialog={this.handleCloseConfirmation}
					title="Deleting Album"
					text="Are you sure you want to perform this action?"
					handleConfirmDelete={this.deleteAlbum}
				/>
				<Paper className={classes.root}>
					<EnhancedTableToolbar
						tableTitle="Albums"
					/>
					<div className={classes.container}>
						<Grid container spacing={8}>
							{data && data.length > 0 ? (
								data
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((album, index) => (
										<Album
											key={index}
											album={album}
											handleEdit={handleEdit}
											handelGalleries={handelGalleries}
											delete={() => this.handleDeleteButton(album.id)}
										/>
									))
							) : (
								<Fragment />
							)}
						</Grid>
					</div>
					<TablePagination
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{ 'aria-label': 'Previous Page' }}
						nextIconButtonProps={{ 'aria-label': 'Next Page' }}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
		);
	}
}

AlbumsListing.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    handleEdit: PropTypes.func.isRequired
};

export default withStyles(styles)(AlbumsListing);
