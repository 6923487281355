import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { isLoggedIn } from "./components/AuthService";
import Login from "./pages/login/Login";
import Logout from "./pages/logout/Logout";
import MainCategories from "./pages/services/main/MainCategories";
import ServicesCategories from "./pages/services/categories/Categories";
import ServicesItems from "./pages/services/items/Items";
import ServicesRequests from "./pages/services/requests/Requests";
import Admins from "./pages/admins/Admins";
import Roles from "./pages/roles/Roles";
import Layout from "./components/Layout";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import Notifications from "./pages/notifications/Notifications";
import Users from "./pages/users/Users";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/settings/Settings";
import GeneralFeedback from "./pages/generalfeedback/GeneralFeedback";
import ServicesFeedback from "./pages/servicesfeedback/ServicesFeedback";
import FeedbackReasons from "./pages/feedbackreasons/FeedbackReasons";
import Projects from "./pages/projects/Projects";
import Events from "./pages/events/Events";
import News from "./pages/news/News";
import PhoneCategories from "./pages/phoneCategories/PhoneCategories";
import PhoneItems from "./pages/phoneItems/PhoneItems";
// import Polls from "./pages/polls/Polls";
import Albums from "./pages/albums/albums/Albums";
import PrivilegesCategories from "./pages/privileges/categories/Categories";
import Privileges from "./pages/privileges/Privileges";
import GuestInvitations from "./pages/guestInvitations/GuestInvitations";
// import TodoCategories from "./pages/thingsTodo/thingsTodoCategories/TodoCategories";
// import TodoItems from "./pages/thingsTodo/thingsTodoItems/TodoItems";
import Guidelines from "./pages/guidelines/Guidelines";
import WorkPermits from "./pages/workPermits/WorkPermits";
import TimeRanges from "./pages/timeRanges/TimeRanges";
import ConstructionUpdates from "./pages/constructionupdates/ConstructionUpdates";
import Submissions from "./pages/submissions/Submissions";

// Routes
const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Dashboard />
  },
  {
    path: "/login",
    component: () => <Login />
  },
  {
    path: "/logout",
    component: () => <Logout />
  },
  {
    path: "/admins",
    component: () => <Admins />
  },
  {
    path: "/roles",
    component: () => <Roles />
  },
  {
    path: "/notifications",
    component: () => <Notifications />
  },
  {
    path: "/services",
    component: () => <MainCategories />
  },
  {
    path: "/servicesCategories/:id",
    component: () => <ServicesCategories />
  },
  {
    path: "/workPermits",
    component: () => <WorkPermits />
  },
  {
    path: "/timeRanges",
    component: () => <TimeRanges />
  },
  {
    path: "/servicesItems/:id",
    component: () => <ServicesItems />
  },
  {
    path: "/servicesRequests",
    component: () => <ServicesRequests />
  },
  // {
  //   path: "/todoCategories",
  //   component: () => <TodoCategories />
  // },
  // {
  //   path: "/todoItems",
  //   component: () => <TodoItems />
  // },
  // {
  //   path: "/todoItems/:id",
  //   component: () => <TodoItems />
  // },
  {
    path: "/users",
    component: () => <Users />
  },
  {
    path: "/dashboard",
    component: () => <Dashboard />
  },
  {
    path: "/settings",
    component: () => <Settings />
  },
  {
    path: "/generalfeedback",
    component: () => <GeneralFeedback />
  },
  {
    path: "/servicesfeedback",
    component: () => <ServicesFeedback />
  },
  {
    path: "/feedbackreasons",
    component: () => <FeedbackReasons />
  },
  {
    path: "/projects",
    component: () => <Projects />
  },
  {
    path: "/news",
    component: () => <News />
  },
  {
    path: "/events",
    component: () => <Events />
  },
  {
    path: "/phoneCategories",
    component: () => <PhoneCategories />
  },
  // {
  //   path: "/polls",
  //   component: () => <Polls />
  // },
  {
    path: "/privilegescategories",
    component: () => <PrivilegesCategories />
  },
  {
    path: "/privileges/:id",
    component: () => <Privileges />
  },
  {
    path: "/phoneItems/:id",
    component: () => <PhoneItems />
  },
  {
    path: "/albums",
    component: () => <Albums />
  },
  {
    path: "/guestInvitations",
    component: () => <GuestInvitations />
  },
  {
    path: "/guidelines",
    component: () => <Guidelines />
  },
  {
    path: "/constructionupdates",
    component: () => <ConstructionUpdates />
  },
  {
    path: "/submissions",
    component: () => <Submissions />
  }
];

ReactDOM.render(
  <Router>
    {!isLoggedIn() ? (
      <Login />
    ) : (
      <Layout>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              render={props => <route.component {...props} />}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </Layout>
    )}
  </Router>,
  document.getElementById("root")
);

registerServiceWorker();
