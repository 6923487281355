import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { API_URL, headers } from "../../constants/Constants";
import axios from "axios";
const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "100%"
  }
});

class SecondStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segement_device: props.segement_device,
      segement_gender: props.segement_gender,
      minAge: props.minAge,
      maxAge: props.maxAge,
      ageChecked: props.ageChecked,
      minAgeError: null,
      maxAgeError: null,
      user_type: props.user_type,
      filtrationType: props.filtrationType,
      users: [],
      user_id: props.user_id
    };
  }

  handleDeviceChange = event => {
    let value = event.target.value;
    this.setState({ segement_device: value }, () => {
      this.props.handleDialogDeviceChange(value);
    });
  };

  handleUserTypeChange = event => {
    let values = event.target.value;
    if (values.length <= 0) {
      alert("You must choose at least (1) user type");
    } else {
      this.setState({ user_type: values }, () => {
        this.props.handleUserTypeChange(values);
      });
    }
  };

  handleGenderChange = event => {
    let value = event.target.value;
    this.setState({ segement_gender: value }, () => {
      this.props.handleDialogGenderChange(value);
    });
  };

  handleminAgeChange = event => {
    let value = event.target.value;
    if (value > 13) {
      this.props.enableNextStepButton();
      this.setState({ minAge: value, minAgeError: null }, () => {
        this.props.handleDialogMinAgeChange(value);
      });
    } else {
      this.props.disableNextButton();
      this.setState({ minAgeError: "Cannot be below 13" });
    }
  };

  handlemaxAgeChange = event => {
    let value = event.target.value;
    if (value > this.state.minAge) {
      this.props.enableNextStepButton();
      this.setState({ maxAge: value, maxAgeError: null }, () => {
        this.props.handleDialogMaxAgeChange(value);
      });
    } else {
      this.props.disableNextButton();
      this.setState({ maxAgeError: "Max age cannot be below min age" });
    }
  };

  handleAgeChecked = event => {
    let value = event.target.checked;
    if (value)
      this.setState({ ageChecked: value, minAge: 13, maxAge: 99 }, () => {
        this.props.handleDialogAgeChecked(value);
      });
    else {
      this.setState({ ageChecked: value, minAge: 13, maxAge: 99 }, () => {
        this.props.handleDialogAgeChecked(value);
      });
    }
  };

  handleFiltrationType = event => {
    let value = parseInt(event.target.value);
    this.setState({ filtrationType: value }, () => {
      this.props.handleFilterationType(value);
    });
  };

  handleUserChange = event => {
    let value = event.target.value;
    this.setState({ user_id: value }, () => {
      this.props.handleUserChange(value);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <FormControl className={classes.fullLength}>
          <InputLabel htmlFor="filtrationType">
            Select User Filtration Method
          </InputLabel>
          <Select
            value={this.state.filtrationType}
            onChange={this.handleFiltrationType}
            input={<Input name="filtrationType" />}
          >
            <MenuItem value={0} selected={true}>
              Default
            </MenuItem>
            <MenuItem value={1}>Specific User</MenuItem>
          </Select>
        </FormControl>
        {this.state.filtrationType === 1 && (
          <FormControl className={classes.fullLength}>
            <InputLabel htmlFor="user_id">Please Select User</InputLabel>
            <Select
              value={this.state.user_id}
              onChange={this.handleUserChange}
              input={<Input name="user_id" />}
            >
              {this.props.users.map(user => (
                <MenuItem value={user.id}>
                  {user.full_name ? user.full_name : user.phone_number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {this.state.filtrationType === 0 && (
          <React.Fragment>
            <FormControl className={classes.halfLength}>
              <InputLabel htmlFor="segement_device">
                Please Select Devices Type
              </InputLabel>
              <Select
                value={this.state.segement_device}
                onChange={this.handleDeviceChange}
                input={<Input name="segement_device" />}
              >
                <MenuItem selected={true} value={0}>
                  ALL
                </MenuItem>
                <MenuItem value={1}>IOS</MenuItem>
                <MenuItem value={2}>Andriod</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.halfLength}>
              <InputLabel htmlFor="segement_gender">
                Please Select Gender
              </InputLabel>
              <Select
                value={this.state.segement_gender}
                onChange={this.handleGenderChange}
                input={<Input name="segement_gender" />}
              >
                <MenuItem selected={true} value={0}>
                  ALL
                </MenuItem>
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.halfLength}>
              <InputLabel htmlFor="segement_device">
                Please Select Owner Type
              </InputLabel>
              <Select
                value={this.state.user_type}
                onChange={this.handleUserTypeChange}
                input={<Input name="segement_device" />}
                multiple={true}
              >
                <MenuItem value={1}>Owners</MenuItem>
                <MenuItem value={2}>Secondary(Home mates)</MenuItem>
                <MenuItem value={3}>Guests</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.fullLength}>
              <FormControlLabel
                label="Select All ages"
                control={
                  <Checkbox
                    checked={this.state.ageChecked}
                    onChange={this.handleAgeChecked}
                  />
                }
              />
            </FormControl>
            {!this.state.ageChecked && (
              <FormControl className={classes.halfLength}>
                <TextField
                  label="Minimum Age"
                  value={this.state.minAge}
                  onChange={this.handleminAgeChange}
                  type="number"
                  error={this.state.minAgeError}
                  helperText={this.state.minAgeError}
                />
              </FormControl>
            )}
            {!this.state.ageChecked && (
              <FormControl className={classes.halfLength}>
                <TextField
                  label="Maximuim Age"
                  value={this.state.maxAge}
                  onChange={this.handlemaxAgeChange}
                  type="number"
                  error={this.state.maxAgeError}
                  helperText={this.state.maxAgeError}
                />
              </FormControl>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(SecondStep);
