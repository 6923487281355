import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { isImage } from "../../../components/FileValidation";
import { BASE_URL } from "../../../constants/Constants";
import TinyMce from "../../../components/TinyMce";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },
  uploadInput: {
    display: "none"
  },
  imageError: {
    marginTop: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class CategoriesFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      categories: [],
      id: null,
      title: "",
      title_ar: "",
      type: 0,
      icon: null,
      home_icon: null,
      sla_time: 0,
      cc_emails: "",
      parent_id: 0,
      visibility: [],
      project_id: 0,
      description: "",
      in_home_screen: 0,
      escalation_emails: "",
      is_active: 0,
      sort: 0,
      uploadedIcon: null,
      uploadedHomeIcon: null,
      errors: {
        title: [],
        title_ar: [],
        type: [],
        icon: [],
        home_icon: [],
        sla_time: [],
        cc_emails: [],
        parent_id: [],
        visibility: [],
        project_id: [],
        description: [],
        in_home_screen: [],
        escalation_emails: [],
        is_active: [],
        sort: []
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.categories != this.state.categories)
      this.setState({ categories: nextProps.categories });

    if (nextProps.category != null) {
      let category = nextProps.category;
      this.setState({
        id: category.id,
        title: category.title,
        title_ar: category.title_ar,
        icon: category.icon,
        home_icon: category.home_icon,
        is_active: parseInt(category.is_active),
        sort: parseInt(category.sort),
        type: parseInt(category.type),
        sla_time: parseInt(category.sla_time),
        cc_emails: category.cc_emails,
        parent_id: parseInt(category.parent_id),
        visibility: category.visibility.split(","),
        project_id: parseInt(category.project_id),
        description: category.description,
        in_home_screen: parseInt(category.in_home_screen),
        escalation_emails: category.escalation_emails
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      title: "",
      title_ar: "",
      type: 0,
      icon: null,
      home_icon: null,
      sla_time: 0,
      cc_emails: "",
      parent_id: 0,
      visibility: [],
      project_id: 0,
      description: "",
      in_home_screen: 0,
      escalation_emails: "",
      is_active: 0,
      sort: 0,
      uploadedIcon: null,
      uploadedHomeIcon: null
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleTypeChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.type = ["Please Enter Valid Value"];
    else errors.type = "";
    this.setState({ type: value });
  };

  handleParentChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.parent_id = ["Please Enter Valid Value"];
    else errors.parent_id = "";
    this.setState({ parent_id: value });
  };

  handleProjectChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.project_id = ["Please Enter Valid Value"];
    else errors.project_id = "";
    this.setState({ project_id: value });
  };

  handleVisibilityChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.visibility = ["Please Enter Valid Value"];
    else errors.visibility = "";
    this.setState({ visibility: value });
  };

  handleTitleChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.title = ["Please Enter Valid Value"];
    else errors.title = "";
    this.setState({ title: value });
  };

  handleArabicTitleChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.title_ar = ["Please Enter Valid Value"];
    else errors.title_ar = "";
    this.setState({ title_ar: value });
  };

  handleSlaTimeChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.sla_time = ["Please Enter Valid Value"];
    else errors.sla_time = "";
    this.setState({ sla_time: value });
  };

  handleDescriptionChange = value => {
    let errors = this.state.errors;
    if (value === "") errors.description = ["Please Enter Valid Value"];
    else errors.description = "";
    this.setState({ description: value.target.getContent() });
  };

  handleCCEmailsChange = value => {
    let errors = this.state.errors;
    if (value === "") errors.cc_emails = ["Please Enter Valid Value"];
    else errors.cc_emails = "";
    this.setState({ cc_emails: value.target.getContent() });
  };

  handleEscalationEmailsChange = value => {
    let errors = this.state.errors;
    if (value === "") errors.escalation_emails = ["Please Enter Valid Value"];
    else errors.escalation_emails = "";
    this.setState({ escalation_emails: value.target.getContent() });
  };

  handleIconChange = event => {
    let file = event.target.files[0];
    let errors = this.state.errors;
    if (file) {
      if (isImage(file)) {
        errors.icon = [];
        this.setState({
          icon: file,
          uploadedIcon: file
        });
      } else {
        errors.icon = ["Please Upload Valid icon"];
      }
    } else {
      errors.icon = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleHomeIconChange = event => {
    let file = event.target.files[0];
    let errors = this.state.errors;
    if (file) {
      if (isImage(file)) {
        errors.home_icon = [];
        this.setState({
          home_icon: file,
          uploadedHomeIcon: file
        });
      } else {
        errors.home_icon = ["Please Upload Valid icon"];
      }
    } else {
      errors.home_icon = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleIsActiveChange = event => {
    this.state.is_active == 0
      ? this.setState({ is_active: 1 })
      : this.setState({ is_active: 0 });
  };

  handleInHomeChange = event => {
    this.state.in_home_screen == 0
      ? this.setState({ in_home_screen: 1 })
      : this.setState({ in_home_screen: 0 });
  };

  handleSortChange = event => {
    let value = event.target.value;
    if (value > 0) this.setState({ sort: value });
    else {
      let errors = this.state.errors;
      errors.sort = ["Sort number must be more than or equal 0"];
      this.setState({ errors: errors });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("title_ar", this.state.title_ar);
    formData.append("is_active", this.state.is_active);
    formData.append("type", this.state.type);
    // formData.append("sla_time", this.state.sla_time);
    formData.append("cc_emails", this.state.cc_emails);
    formData.append("parent_id", this.state.parent_id);
    // formData.append("visibility", this.state.visibility);
    formData.append("project_id", this.state.project_id);
    // formData.append("description", this.state.description);
    formData.append("in_home_screen", this.state.in_home_screen);
    // formData.append("escalation_emails", this.state.escalation_emails);
    formData.append("sort", this.state.sort);

    if (this.state.uploadedIcon) {
      formData.append("icon", this.state.uploadedIcon);
    }

    if (this.state.uploadedHomeIcon) {
      formData.append("home_icon", this.state.uploadedHomeIcon);
    }
    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {
      title,
      title_ar,
      icon,
      home_icon,
      type,
      parent_id,
      visibility,
      project_id,
      description,
      in_home_screen,
      is_active,
      sort
    } = this.state;
    if (
      title === "" ||
      title_ar === "" ||
      icon === "" ||
      home_icon === "" ||
      type === "" ||
      parent_id === "" ||
      visibility === "" ||
      project_id === "" ||
      description === "" ||
      in_home_screen === "" ||
      is_active === "" ||
      sort === ""
    ) {
      disabled = true;
    }

    let mainIcon = "";
    if (this.state.uploadedIcon)
      mainIcon = URL.createObjectURL(this.state.uploadedIcon);
    else if (this.state.icon) mainIcon = BASE_URL + this.state.icon;
    else mainIcon = mainIcon = "temp.png";

    let homeIcon = "";
    if (this.state.uploadedHomeIcon)
      homeIcon = URL.createObjectURL(this.state.uploadedHomeIcon);
    else if (this.state.home_icon) homeIcon = BASE_URL + this.state.home_icon;
    else homeIcon = homeIcon = "temp.png";

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="categoryForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Title"
                      value={this.state.title}
                      onChange={event => this.handleTitleChange(event)}
                      error={
                        this.state.errors.title &&
                        this.state.errors.title.length > 0
                      }
                      helperText={
                        this.state.errors.title && this.props.errors.title[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Arabic Title"
                      value={this.state.title_ar}
                      onChange={event => this.handleArabicTitleChange(event)}
                      error={
                        this.state.errors.title_ar &&
                        this.state.errors.title_ar.length > 0
                      }
                      helperText={
                        this.state.errors.title_ar &&
                        this.props.errors.title_ar[0]
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Sla Time"
                      type="number"
                      value={this.state.sla_time}
                      onChange={event => this.handleSlaTimeChange(event)}
                      error={
                        this.state.errors.sla_time &&
                        this.state.errors.sla_time.length > 0
                      }
                      helperText={
                        this.state.errors.sla_time &&
                        this.state.errors.sla_time[0]
                      }
                    />
                  </Grid> */}

                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Sort"
                      type="number"
                      value={this.state.sort}
                      onChange={event => this.handleSortChange(event)}
                      error={
                        this.state.errors.sort &&
                        this.state.errors.sort.length > 0
                      }
                      helperText={
                        this.state.errors.sort && this.state.errors.sort[0]
                      }
                    />
                  </Grid>
                  {/* 
                  <Grid item xs={6}>
                    <InputLabel htmlFor="type-simple">Type</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.type}
                      onChange={this.handleTypeChange}
                      error={
                        this.state.errors.type &&
                        this.state.errors.type.length > 0
                      }
                      helperText={this.state.errors.type}
                      inputProps={{
                        name: "type",
                        id: "type-simple"
                      }}
                    >
                      <MenuItem value={1}>Items</MenuItem>
                      <MenuItem value={2}>Freetext</MenuItem>
                      <MenuItem value={3}>Guest Entry</MenuItem>
                      <MenuItem value={4}>Work Permit</MenuItem>
                    </Select>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <InputLabel htmlFor="visibility-simple">
                      Visibility
                    </InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.visibility}
                      onChange={this.handleVisibilityChange}
                      multiple
                      error={
                        this.state.errors.visibility &&
                        this.state.errors.visibility.length > 0
                      }
                      helperText={this.state.errors.visibility}
                      inputProps={{
                        name: "visibility",
                        id: "visibility-simple"
                      }}
                    >
                      <MenuItem value={1}>Owner</MenuItem>
                      <MenuItem value={2}>Guest</MenuItem>
                      <MenuItem value={3}>Secondary</MenuItem>
                    </Select>
                  </Grid> */}
                  <Grid item xs={6}>
                    <InputLabel htmlFor="project-simple">Project</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.project_id}
                      onChange={this.handleProjectChange}
                      error={
                        this.state.errors.project_id &&
                        this.state.errors.project_id.length > 0
                      }
                      helperText={this.state.errors.project_id}
                      inputProps={{
                        name: "project_id",
                        id: "project-simple"
                      }}
                    >
                      {this.props.projects.map(project => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <InputLabel htmlFor="parent-simple">Parent</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.parent_id}
                      onChange={this.handleParentChange}
                      error={
                        this.state.errors.parent_id &&
                        this.state.errors.parent_id.length > 0
                      }
                      helperText={this.state.errors.parent_id}
                      inputProps={{
                        name: "parent_id",
                        id: "parent-simple"
                      }}
                    >
                      {this.props.categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid> */}
                  <Grid item xs={6}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="main-icon-input"
                      type="file"
                      onChange={event => this.handleIconChange(event)}
                    />
                    <label htmlFor="main-icon-input">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Upload Icon
                      </Button>
                    </label>
                    <img className={classes.preview} src={mainIcon} alt="" />
                    {this.state.errors.icon && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                      >
                        {this.state.errors.icon[0]}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="home-icon-input"
                      type="file"
                      onChange={event => this.handleHomeIconChange(event)}
                    />
                    <label htmlFor="home-icon-input">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Upload Home Icon
                      </Button>
                    </label>
                    <img className={classes.preview} src={homeIcon} alt="" />
                    {this.state.errors.home_icon && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                      >
                        {this.state.errors.home_icon[0]}
                      </Typography>
                    )}
                    {homeIcon && (
                      <Button
                        onClick={() => {
                          this.setState(
                            {
                              home_icon: null,
                              uploadedHomeIcon: null
                            },
                            () => {
                              console.log(
                                this.state.home_icon,
                                this.state.uploadedHomeIcon
                              );
                            }
                          );
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <label htmlFor="IsActive">Is Active</label>
                    <Checkbox
                      id="IsActive"
                      checked={this.state.is_active}
                      onClick={event => this.handleIsActiveChange(event)}
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
                    <label htmlFor="InHome">In Home</label>
                    <Checkbox
                      id="InHome"
                      checked={this.state.in_home_screen}
                      onClick={event => this.handleInHomeChange(event)}
                    />
                  </Grid> */}
                  {/* <TinyMce
                    title="Description"
                    data={this.state.description}
                    handleChange={this.handleDescriptionChange}
                    error={
                      this.state.errors.description &&
                      this.state.errors.description.length > 0
                    }
                    helperText={
                      this.state.errors.description &&
                      this.state.errors.description[0]
                    }
                  />
                  <TinyMce
                    title="cc_emails"
                    data={this.state.cc_emails}
                    handleChange={this.handleCCEmailsChange}
                    error={
                      this.state.errors.cc_emails &&
                      this.state.errors.cc_emails.length > 0
                    }
                    helperText={
                      this.state.errors.cc_emails &&
                      this.state.errors.cc_emails[0]
                    }
                  />
                  <TinyMce
                    title="escalation_emails"
                    data={this.state.escalation_emails}
                    handleChange={this.handleEscalationEmailsChange}
                    error={
                      this.state.errors.escalation_emails &&
                      this.state.errors.escalation_emails.length > 0
                    }
                    helperText={
                      this.state.errors.escalation_emails &&
                      this.state.errors.escalation_emails[0]
                    }
                  /> */}
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

CategoriesFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(CategoriesFormDialog);
