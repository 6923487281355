import React, { Component, Fragment } from "react";
import axios from "axios";
import Table from "./NotificationsListing.js";
import FAB from "../../components/FAB";
import NotificationDialog from "./NotificationDialog";
import SnackBar from "../../components/SnackBar";
import RefreshLoader from "../../components/RefreshLoader";
import Loader from "../../components/Loader";
import { API_URL, headers } from "../../constants/Constants";
import Recipients from "./Recipients";
import { checkAuthorization } from "../../components/AuthService";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      openNotificationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: false,
      isRefreshing: false,
      usersNotifications: [],
      recipientsDialogOpen: false,
      resendID: null,
      users: [],
      modules: []
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "notifications", { headers: headers })
      .then(response => {
        this.setState(
          {
            notifications: response.data.data
          },
          () => {
            this.handleLoading(false);
          }
        );
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_notifications")) {
      this.setState({
        snackBarMessage: "You don't have permission to Add Notification",
        snackBarOpen: true,
        snackBarType: "error"
      });
    } else {
      this.getUserAndModules();
      // this.setState({ openNotificationDialog: true });
    }
  };

  handleCloseNotificationDialog = () => {
    this.setState({ openNotificationDialog: false });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  handleUpdate = notification => {
    let newData = this.state.notifications;
    newData.push(notification);
    this.setState({ notifications: newData });
  };

  createNotification = formData => {
    this.handleRefreshing(true);
    axios
      .post(API_URL + "notifications", formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 201) {
          this.handleUpdate(response.data.data);
          this.handleRefreshing(false);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.handleRefreshing(false);
          this.setState({
            snackBarMessage: "Something went wrong, Please try again later",
            snackBarOpen: true,
            snackBarType: "error"
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          snackBarMessage: "Something went wrong, Please try again later",
          snackBarOpen: true,
          snackBarType: "error"
        });
        this.handleRefreshing(false);
      });
  };

  handleDeleteNoification = itemsIDs => {
    let formData = new FormData();
    formData.append("records", JSON.stringify(itemsIDs));
    this.handleRefreshing(true);
    axios
      .post(API_URL + "notifications/batchDelete", formData, {
        headers: headers
      })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          this.removeDeletedFromData(itemsIDs);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else if (response.res === 0) {
          this.handleRefreshing(false);
          this.setState({
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error"
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  removeDeletedFromData = items => {
    let data = this.state.notifications;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ notifications: newData });
    this.handleRefreshing(false);
  };

  showSnackBarMessage = (message, type) => {
    this.setState({
      snackBarMessage: message,
      snackBarOpen: true,
      snackBarType: type
    });
  };

  showRecipients = id => {
    let notifications = this.state.notifications.find(notification => {
      return notification.id === id;
    });
    this.setState({
      usersNotifications: notifications.users,
      recipientsDialogOpen: true
    });
  };

  handleRecipientsClose = () => {
    this.setState({ recipientsDialogOpen: false, usersNotifications: [] });
  };

  handleResend = id => {
    this.setState({ resendID: id });
    let formData = new FormData();
    formData.append("id", id);
    axios
      .post(API_URL + "notifications/resend", formData, {
        headers: headers
      })
      .then(response => {
        this.setState({ resendID: null });
        if (response.status === 201) {
          this.handleUpdate(response.data.data);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: "Something went wrong, Please try again later",
            snackBarOpen: true,
            snackBarType: "error"
          });
        }
      })
      .catch(err => {
        this.setState({
          snackBarMessage: "Something went wrong, Please try again later",
          snackBarOpen: true,
          snackBarType: "error",
          resendID: null
        });
      });
  };

  getUserAndModules = () => {
    this.setState({ isRefreshing: true });
    axios
      .get(API_URL + "notifications/getUserAndModules", { headers: headers })
      .then(response => {
        let data = response.data.data;
        this.setState({
          users: data.users,
          modules: data.modules,
          openNotificationDialog: true,
          isRefreshing: false
        });
      });
  };

  render() {
    return (
      <Fragment>
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        <Table
          data={this.state.notifications}
          handleDeleteNoification={this.handleDeleteNoification}
          showSnackBarMessage={this.showSnackBarMessage}
          showRecipients={this.showRecipients}
          resendID={this.state.resendID}
          handleResend={this.handleResend}
        />
        <Recipients
          recipientsDialogOpen={this.state.recipientsDialogOpen}
          handleRecipientsClose={this.handleRecipientsClose}
          usersNotifications={this.state.usersNotifications}
        />
        {checkAuthorization("can_add_notifications") && (
          <FAB callback={this.fabCallback} />
        )}
        <NotificationDialog
          isOpen={this.state.openNotificationDialog}
          users={this.state.users}
          modules={this.state.modules}
          handleClose={this.handleCloseNotificationDialog}
          dialogTitle={"Add New Notifications"}
          handleDialogLoading={this.handleRefreshing}
          handleUpdateNotifications={this.handleUpdate}
          createNotification={this.createNotification}
        />
        {this.state.isRefreshing && <RefreshLoader />}
        {this.state.isLoading && <Loader />}
      </Fragment>
    );
  }
}

export default Notifications;
