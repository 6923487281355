import React, { Component, Fragment } from "react";
import axios from "axios";
import SnackBar from "../../components/SnackBar";
import GeneralFeedbackFormDialog from "./GeneralFeedbackFormDialog";
import GeneralFeedbackListing from "./GeneralFeedbackListing";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers, BASE_URL } from "../../constants/Constants";
import { checkAuthorization } from "../../components/AuthService";

class GeneralFeedback extends Component {
  state = {
    feedback: [],
    currentFeedback: null,
    openFeedbackFormDialog: false,
    openDeleteConfirmationDialog: false,
    snackBarOpen: false,
    snackBarMessage: "",
    snackBarType: "success",
    isLoading: true,
    isRefreshing: false,
    dialogTitle: "View Feedback",
    selectedFeedback: []
  };

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "generalfeedback", { headers: headers })
      .then(response => {
        this.setState({ feedback: response.data.data }, () => {
          this.handleLoading(false);
        });
      })
      .catch(function(error) {
        this.handleLoading(false);
        console.log(error);
      });
  };

  handleCloseFeedbackFormDialog = () => {
    this.setState({ openFeedbackFormDialog: false });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  updateInState = item => {
    let newData = this.state.feedback;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == item.id) {
        newData[i] = item;
        break;
      }
    }
    this.setState({ feedback: newData, isRefreshing: false });
  };

  handleUpdateFeedback = formData => {
    this.setState({ isRefreshing: true });
    formData.append("_method", "PUT");
    axios
      .post(
        API_URL + "generalfeedback/" + this.state.currentFeedback.id,
        formData,
        { headers: headers }
      )
      .then(response => {
        if (response.status === 500) {
          this.setState({
            openFeedbackFormDialog: false,
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentFeedback: null
          });
        } else if (
          response.status === 200 &&
          response.data.message === "form_error"
        ) {
          this.setState({
            isRefreshing: false,
            errors: response.data,
            openFeedbackFormDialog: true
          });
        } else if (response.status === 200) {
          this.updateInState(response.data);
          this.setState(
            {
              openFeedbackFormDialog: false,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success",
              currentFeedback: null
            },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleEdit = currFeedback => {
    // if (!checkAuthorization("feedback_permissions", "can_edit_feedback")) {
    //   this.setState({
    //     snackBarMessage: "You don't have permission to view feedback",
    //     snackBarType: "error",
    //     snackBarOpen: true
    //   });
    // } else {
    this.setState({
      dialogTitle: "Edit Feedback",
      currentFeedback: currFeedback,
      openFeedbackFormDialog: true
    });
    // }
  };

  handleDelete = currFeedback => {
    // if (!checkAuthorization("feedback_permissions", "can_delete_feedback")) {
    //   this.setState({
    //     snackBarMessage: "You don't have permission to delete feedback",
    //     snackBarType: "error",
    //     snackBarOpen: true
    //   });
    // } else {
    this.setState({
      currentFeedback: currFeedback,
      openDeleteConfirmationDialog: true
    });
    // }
  };

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };

  handleConfirmDelete = () => {
    this.handleCloseDeleteConfirmationDialog();
    this.handleRefreshing(true);

    axios
      .delete(API_URL + "generalfeedback/" + this.state.currentFeedback.id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 500) {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false
          });
        } else {
          this.setState(
            {
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  handleDeleteFeedback = itemsIDs => {
    let formData = new FormData();
    formData.append("items", JSON.stringify(itemsIDs));
    this.handleRefreshing(true);
    axios
      .post(API_URL + "generalfeedback/batchDelete", formData, {
        headers: headers
      })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          this.removeDeletedFromData(itemsIDs);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else if (response.res === 0) {
          this.handleRefreshing(false);
          this.setState({
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error"
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  removeDeletedFromData = items => {
    let data = this.state.feedback;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ feedback: newData });
    this.handleRefreshing(false);
  };

  // handleExport = selectedFeedback => {
  //   this.setState({
  //     selectedFeedback: selectedFeedback
  //   });
  //   console.log("response of selected", selectedFeedback);
  //   let formData = new FormData();
  //   formData.append("exItemsIDs", JSON.stringify(selectedFeedback));
  //   axios
  //     .post(API_URL + "generalfeedback/export", formData)
  //     .then(response => {
  //       console.log("response of excel", response.data);
  //       const link = document.createElement("a");
  //       link.href = BASE_URL + response.data.message;
  //       link.target = "__blank";
  //       link.setAttribute("download", "generalfeedback.xlsx");
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch(error => {
  //       this.handleRefreshing(false);
  //       console.log(error);
  //     });
  // };

  render() {
    return (
      <Fragment>
        <GeneralFeedbackListing
          tableTitle="General Feedback"
          data={this.state.feedback}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          handleExport={this.handleExport}
          handleDeleteFeedback={this.handleDeleteFeedback}
        />
        <GeneralFeedbackFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseFeedbackFormDialog}
          isOpen={this.state.openFeedbackFormDialog}
          currFeedback={this.state.currentFeedback}
          handleUpdateFeedback={this.handleUpdateFeedback}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
        />
        <DeleteConfirmationDialog
          title="Delete Feedback"
          text="Are you sure that you want to permanently delete this feedback?"
          isOpen={this.state.openDeleteConfirmationDialog}
          handleCloseDeleteConfirmationDialog={
            this.handleCloseDeleteConfirmationDialog
          }
          handleConfirmDelete={this.handleConfirmDelete}
        />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default GeneralFeedback;
