import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TinyMce from "../../../components/TinyMce";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { DateFormatInput } from "material-ui-next-pickers";
import { API_URL, headers } from "../../../constants/Constants";
import axios from "axios";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },
  uploadInput: {
    display: "none"
  },
  imageError: {
    marginTop: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RequestsFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      requests: [],
      user: [],
      item: [],
      category: [],
      unit: [],
      id: null,
      user_id: 0,
      user_project_id: 0,
      user_unit_id: 0,
      service_request_id: 0,
      service_item_id: 0,
      service_category_id: 0,
      state: 0,
      date: new Date(),
      time_range: "",
      admin_notes: "",
      user_comment: "",
      errors: {
        user_id: [],
        user_project_id: [],
        user_unit_id: [],
        service_request_id: [],
        service_item_id: [],
        service_category_id: [],
        date: [],
        time_range: [],
        admin_notes: [],
        user_comment: []
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.requests != this.state.requests)
      this.setState({ requests: nextProps.requests });

    if (nextProps.request != null) {
      let request = nextProps.request;
      this.setState({
        id: request.id,
        user: request.user,
        category: request.category,
        item: request.item,
        unit: request.unit,
        user_id: request.user_id,
        user_project_id: request.user_project_id,
        user_unit_id: request.user_unit_id,
        service_request_id: request.service_request_id,
        service_item_id: request.service_item_id,
        service_category_id: request.service_category_id,
        state: request.state,
        date: new Date(request.date),
        time_range: request.time_range,
        admin_notes: request.admin_notes,
        user_comment: request.user_comment
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      user_id: 0,
      user_project_id: 0,
      user_unit_id: 0,
      service_request_id: 0,
      service_item_id: 0,
      service_category_id: 0,
      date: new Date(),
      time_range: "",
      admin_notes: "",
      user_comment: "",
      state: 0,
      requests: []
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleUserChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.user_id = ["Please Enter Valid Value"];
    else errors.user_id = "";
    this.setState({ user_id: value });
  };

  handleUnitChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.user_unit_id = ["Please Enter Valid Value"];
    else errors.user_unit_id = "";
    this.setState({ user_unit_id: value });
    this.handleProjectChange();
  };

  handleProjectChange = () => {
    let unit = this.props.units[this.state.user_unit_id];
    this.setState({ user_project_id: unit.project_id });
  };

  handleCategoryChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.service_category_id = ["Please Enter Valid Value"];
    else errors.service_category_id = "";
    this.setState({ service_category_id: value });
  };

  handleItemChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.service_item_id = ["Please Enter Valid Value"];
    else errors.service_item_id = "";
    this.setState({ service_item_id: value });
  };

  handleDateChange = date => {
    let value = date;
    let errors = this.state.errors;
    if (value == "") errors.date = ["Please Enter Valid Value"];
    else errors.date = "";
    this.setState({ date: value });
  };

  handleTimeRangeChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.time_range = ["Please Enter Valid Value"];
    else errors.time_range = "";
    this.setState({ time_range: value });
  };

  handleAdminNotesChange = value => {
    let errors = this.state.errors;
    if (value === "") errors.admin_notes = ["Please Enter Valid Value"];
    else errors.admin_notes = "";
    this.setState({ admin_notes: value.target.getContent() });
  };

  handleUserCommentChange = value => {
    let errors = this.state.errors;
    if (value === "") errors.user_comment = ["Please Enter Valid Value"];
    else errors.user_comment = "";
    this.setState({ user_comment: value.target.getContent() });
  };

  handleStateChange = event => {
    this.state.state == 0
      ? this.setState({ state: 1 })
      : this.setState({ state: 0 });
  };

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("user_id", this.state.user_id);
    formData.append("user_project_id", this.state.user_project_id);
    formData.append("user_unit_id", this.state.user_unit_id);
    formData.append("service_category_id", this.state.service_category_id);
    formData.append("service_item_id", this.state.service_item_id);
    let date = new Date(this.state.date);
    let formatedDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    formData.append("date", formatedDate);
    formData.append("time_range", this.state.time_range);
    formData.append("admin_notes", this.state.admin_notes);
    formData.append("user_comment", this.state.user_comment);
    formData.append("state", this.state.state);
    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {
      user_id,
      user_project_id,
      user_unit_id,
      service_category_id,
      date,
      time_range,
      admin_notes,
      user_comment
    } = this.state;
    if (
      user_id === "" ||
      user_project_id === "" ||
      user_unit_id === "" ||
      service_category_id === "" ||
      date === "" ||
      time_range === ""
    ) {
      disabled = true;
    }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="requestForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  {this.props.request && (
                    <Grid item xs={12}>
                      <InputLabel htmlFor="state-simple">State</InputLabel>
                      <Select
                        fullWidth={true}
                        value={this.state.state}
                        onChange={this.handleStateChange}
                        error={
                          this.state.errors.state &&
                          this.state.errors.state.length > 0
                        }
                        helperText={this.state.errors.state}
                        inputProps={{
                          name: "state",
                          id: "state-simple"
                        }}
                      >
                        <MenuItem key={1} value={0}>
                          pending
                        </MenuItem>
                        <MenuItem key={2} value={1}>
                          Fullfilled
                        </MenuItem>
                      </Select>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <InputLabel htmlFor="user-simple">User</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.user_id}
                      onChange={this.handleUserChange}
                      error={
                        this.state.errors.user_id &&
                        this.state.errors.user_id.length > 0
                      }
                      helperText={this.state.errors.user_id}
                      inputProps={{
                        name: "user_id",
                        id: "user-simple"
                      }}
                    >
                      {this.props.users.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="unit-simple">Units</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.user_unit_id}
                      onChange={this.handleUnitChange}
                      error={
                        this.state.errors.user_unit_id &&
                        this.state.errors.user_unit_id.length > 0
                      }
                      helperText={this.state.errors.user_unit_id}
                      inputProps={{
                        name: "user_unit_id",
                        id: "unit-simple"
                      }}
                    >
                      {this.props.units.map(unit => {
                        if (unit.user_id == this.state.user_id)
                          return (
                            <MenuItem key={unit.id} value={unit.id}>
                              {unit.unit_code}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="category-simple">Category</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.service_category_id}
                      onChange={this.handleCategoryChange}
                      error={
                        this.state.errors.service_category_id &&
                        this.state.errors.service_category_id.length > 0
                      }
                      helperText={this.state.errors.service_category_id}
                      inputProps={{
                        name: "service_category_id",
                        id: "project-simple"
                      }}
                    >
                      {this.props.categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="item-simple">Item</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.service_item_id}
                      onChange={this.handleItemChange}
                      error={
                        this.state.errors.service_item_id &&
                        this.state.errors.service_item_id.length > 0
                      }
                      helperText={this.state.errors.service_item_id}
                      inputProps={{
                        name: "service_item_id",
                        id: "item-simple"
                      }}
                    >
                      {this.props.items.map(item => {
                        if (item.category_id == this.state.service_category_id)
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <DateFormatInput
                      name="date-input"
                      label="Request Date"
                      value={this.state.date}
                      onChange={this.handleDateChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="time-simple">Time Range</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.time_range}
                      onChange={this.handleTimeRangeChange}
                      error={
                        this.state.errors.time_range &&
                        this.state.errors.time_range.length > 0
                      }
                      helperText={this.state.errors.time_range}
                      inputProps={{
                        name: "time_range",
                        id: "time-simple"
                      }}
                    >
                      <MenuItem key={1} value="12 pm : 2 pm">
                        12 pm : 2 pm
                      </MenuItem>
                      <MenuItem key={2} value="2 pm : 4 pm">
                        2 pm : 4 pm
                      </MenuItem>
                      <MenuItem key={3} value="6 pm : 8 pm">
                        6 pm : 8 pm
                      </MenuItem>
                      <MenuItem key={4} value="10 pm : 12 am">
                        10 pm : 12 am
                      </MenuItem>
                    </Select>
                  </Grid>
                  <TinyMce
                    title="Admin Notes"
                    data={this.state.admin_notes}
                    handleChange={this.handleAdminNotesChange}
                    error={
                      this.state.errors.admin_notes &&
                      this.state.errors.admin_notes.length > 0
                    }
                    helperText={
                      this.state.errors.admin_notes &&
                      this.state.errors.admin_notes[0]
                    }
                  />
                  <TinyMce
                    title="User Comment"
                    data={this.state.user_comment}
                    handleChange={this.handleUserCommentChange}
                    error={
                      this.state.errors.user_comment &&
                      this.state.errors.user_comment.length > 0
                    }
                    helperText={
                      this.state.errors.user_comment &&
                      this.state.errors.user_comment[0]
                    }
                  />
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

RequestsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(RequestsFormDialog);
