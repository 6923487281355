import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import PrivilegesFormDialog from "./PrivilegesFormDialog";
import PrivilegesListing from "./PrivilegesListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
import { withRouter } from "react-router-dom";

class Privileges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privileges: [],
      category_id: this.props.match.params.id,
      currentPrivilege: null,
      selectedPrivilege: [],
      openPrivilegesFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Privilege",
      reset: false,
      errors: {
        store_name: [],
        store_image: [],
        store_phone: [],
        store_website: [],
        store_address: [],
        store_location: [],
        decription: [],
        is_active: [],
        sort: []
      }
    };
  }

  componentDidMount() {
    this.getAll();
    console.log(this.props);
  }

  getAll = () => {
    axios
      .get(API_URL + "privileges/category/" + this.state.category_id, {
        headers: headers
      })
      .then(response => {
        return response.data;
      })
      .then(response => {
        let privileges = response.data;
        if (privileges)
          this.setState({ privileges: privileges, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_privileges")) {
      this.setState({
        snackBarMessage: "You don't have permission to create privileges",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Add Privilege",
        openPrivilegesFormDialog: true,
        reset: true
      });
    }
  };

  handleClosePrivilegeFormDialog = () => {
    this.setState({
      openPrivilegesFormDialog: false,
      currentPrivilege: null,
      errors: {
        store_name: [],
        store_image: [],
        store_phone: [],
        store_website: [],
        store_address: [],
        store_location: [],
        decription: [],
        is_active: [],
        sort: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = privilege => {
    let newData = this.state.privileges;
    newData.push(privilege);
    this.setState({ privileges: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "privileges", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openPrivilegesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPrivilege: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openPrivilegesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = privilege => {
    let newData = this.state.privileges;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === privilege.id) {
        newData[i] = privilege;
        break;
      }
    }
    this.setState({ privileges: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentPrivilege.id;
    axios
      .post(API_URL + "privileges/" + id, formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openPrivilegesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPrivilege: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openPrivilegesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = privilege => {
    if (!checkAuthorization("can_edit_privileges")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit privileges",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Privilege",
        currentPrivilege: privilege,
        openPrivilegesFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = () => {
    this.handleRefreshing(true);
    let id = this.state.currentPrivilege.id;
    axios
      .delete(API_URL + "privileges/" + id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openPrivilegesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPrivilege: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openPrivilegesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.privileges;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ privileges: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedPrivileges = selectedPrivilege => {
    if (!checkAuthorization("can_delete_privileges")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete privileges",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedPrivilege: selectedPrivilege
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted.length < 1) {
      let formData = new FormData();
      formData.append("itemsids", JSON.stringify(toBeDeleted));
      axios
        .post(API_URL + "privileges/batchDelete", formData, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openPrivilegesFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentPrivilege: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openPrivilegesFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    } else {
      axios
        .delete(API_URL + "privileges/" + toBeDeleted, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openPrivilegesFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentPrivilege: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openPrivilegesFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <PrivilegesListing
          tableTitle="Privileges"
          data={this.state.privileges}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <PrivilegesFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleClosePrivilegeFormDialog}
          isOpen={this.state.openPrivilegesFormDialog}
          privilege={this.state.currentPrivilege}
          category_id={this.state.category_id}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          privileges={this.state.privileges}
          errors={this.state.errors}
          reset={this.state.reset}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default withRouter(Privileges);
