import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import FeedbackReasonsFormDialog from "./FeedbackReasonsFormDialog";
import FeedbackReasonsListing from "./FeedbackReasonsListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
import { withRouter } from "react-router-dom";

class FeedbackReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [],
      errors: {
        reason: []
      },
      currentReason: null,
      selectedReasons: [],
      openFeedbackReasonsFormDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Reason"
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "feedbackreasons", {
        headers: headers
      })
      .then(response => {
        let reasons = response.data.data;
        this.setState({ reasons: reasons, isLoading: false });
      })
      .catch(function(error) {
        this.handleLoading(false);
        console.log(error);
      });
  };

  fabCallback = () => {
    // if (!checkAuthorization("can_create_reaon")) {
    //   this.setState({
    //     snackBarMessage: "You don't have permission to create Reaons",
    //     snackBarType: "error",
    //     snackBarOpen: true
    //   });
    // } else {
    this.setState({
      dialogTitle: "Add Reason",
      openFeedbackReasonsFormDialog: true,
      reset: true
    });
    // }
  };

  handleCloseFeedbackReasonsFormDialog = () => {
    this.setState({
      openFeedbackReasonsFormDialog: false,
      currentReason: null,
      errors: {
        Title: [],
        Description: [],
        Sort: [],
        Image: [],
        isActive: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  handleSnackBarMessage = (msg, type) => {
    this.setState({
      snackBarMessage: msg,
      snackBarType: type,
      snackBarOpen: true
    });
  };

  addToState = reason => {
    let newData = this.state.reasons;
    newData.push(reason);
    this.setState({ reasons: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "feedbackreasons", formData, { headers: headers })
      .then(response => {
        if (response.status === 200) {
          this.addToState(response.data.data);
          this.setState({
            openFeedbackReasonsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            isRefreshing: false
          });
        } else {
          this.setState({
            snackBarMessage: "Please try again",
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentReason: null,
            reset: true
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  updateInState = reason => {
    let newData = this.state.reasons;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === reason.id) {
        newData[i] = reason;
        break;
      }
    }
    this.setState({ reasons: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    let reason = this.state.currentReason;
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(
        API_URL + "feedbackreasons/" + this.state.currentReason.id,
        formData,
        {
          headers: headers
        }
      )
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openFeedbackReasonsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            isRefreshing: false
          });
        } else {
          this.setState({
            snackBarMessage: "Please try again",
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentReason: null,
            reset: true
          });
        }
      })
      .catch(error => {
        this.setState({
          reset: false,
          isRefreshing: false,
          currentReason: reason,
          errors: error.response.data.message,
          openFeedbackReasonsFormDialog: true
        });
      });
  };

  handleEdit = reason => {
    // if (!checkAuthorization("can_edit_reason")) {
    //   this.setState({
    //     snackBarMessage: "You don't have permission to edit reasons",
    //     snackBarType: "error",
    //     snackBarOpen: true
    //   });
    // } else {
    this.setState({
      dialogTitle: "Edit Reasons",
      currentReason: reason,
      openFeedbackReasonsFormDialog: true
    });
    // }
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.reasons;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ reasons: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedReasons = selectedReasons => {
    // if (!checkAuthorization("can_delete_reason")) {
    //   this.setState({
    //     snackBarMessage: "You don't have permission to delete reasons",
    //     snackBarType: "error",
    //     snackBarOpen: true
    //   });
    // } else {
    this.setState({
      openDeleteSelectedConfirmationDialog: true,
      selectedReasons: selectedReasons
    });
    // }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted instanceof Array) {
      let id;
      for (id of toBeDeleted) {
        axios
          .delete(API_URL + "feedbackreasons/" + id, {
            headers: headers
          })
          .then(response => {
            if (response.status === 200) {
              this.setState({
                snackBarOpen: true,
                snackBarType: "success",
                snackBarMessage: response.message
              });
              this.removeDeletedFromData(toBeDeleted);
            } else if (response.status === false) {
              this.setState({
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: "Please try again"
              });
            }
          });
      }
    } else {
      axios
        .delete(API_URL + "feedbackreasons/" + toBeDeleted, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "success",
              snackBarMessage: response.message
            });
            this.removeDeletedFromData(toBeDeleted);
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarType: "error",
              snackBarMessage: "Please try again"
            });
          }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <FeedbackReasonsListing
          tableTitle="FeedBack Reasons"
          data={this.state.reasons}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
          handleSnackBarMessage={this.handleSnackBarMessage}
        />
        <FeedbackReasonsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseFeedbackReasonsFormDialog}
          isOpen={this.state.openFeedbackReasonsFormDialog}
          reason={this.state.currentReason}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          errors={this.state.errors}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default withRouter(FeedbackReasons);
