import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Recipients extends Component {
  getCustomerName = (firstName, lastName) => {
    let name = "Not Avaliable";
    if (firstName && lastName) name = firstName + " " + lastName;
    else if (firstName && !lastName) name = firstName;
    return name;
  };
  render() {
    const { classes, usersNotifications } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.recipientsDialogOpen}
        onClose={this.props.handleRecipientsClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.props.handleRecipientsClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Notification Recipients
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" padding="none">
                  Name
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Phone
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Platform
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Sent
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Read
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersNotifications.map(item => (
                <TableRow>
                  <TableCell component="th" scope="row" padding="none">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.phone}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.platform}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.send}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.read}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}
export default withStyles(styles)(Recipients);
