import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RoleFormDialog extends Component {
  state = {
    isOpen: null,
    ID: null,
    RoleName: "",
    Permissions: [],
    admins_permissions:[],
    newRolePermissions:[],
    errors: {
      role_name: []
      },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (nextProps.errors !== this.state.errors)
      this.setState({ errors: nextProps.errors });

    let { role, permissions } = nextProps;
    let admins_permissions = [];
    let newRolePermissions = [];
    if(role !== null){
      role.permissions.forEach(element => {
        admins_permissions[element.name] = 1;
        newRolePermissions.push(element.name);
      });
    }  
    this.setState({
      ID: role !== null ? role.id : null,
      RoleName: role !== null ? role.name : "",
      Permissions: permissions !== null ? permissions !== "" ? permissions : [] : [],
      admins_permissions : admins_permissions,
      newRolePermissions : newRolePermissions
    });
    
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.handleClose();
    });
  };

  handleCheckboxChange = event => {
    let permission = event.target.name;
    let permissions = this.state.admins_permissions;
    let newRolePermissions = this.state.newRolePermissions;
    let checked = Number(event.target.checked);
    if (checked === 1){
      newRolePermissions.push(permission);
    } else{
      var index = newRolePermissions.indexOf(permission);
      if (index > -1) {
        newRolePermissions.splice(index, 1);
      }
    }
    
    if (!permissions[permission]) permissions[permission] = {};
    permissions[permission] = checked;
    this.setState({
      admins_permissions: permissions,
      newRolePermissions:newRolePermissions
    });
  };

  handleChange = event => {
    this.setState({ RoleName: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    var rolesModalForm = new FormData();
    rolesModalForm.append("permissions", JSON.stringify(this.state.newRolePermissions));
    rolesModalForm.append("role_name",this.state.RoleName);

    if (this.state.ID) {
      rolesModalForm.append("_method", 'put');
      this.props.updateRole(rolesModalForm,this.state.ID);
    } else {
      this.props.addRole(rolesModalForm);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={this.handleSubmit}
                disabled={this.state.RoleName.trim() === ""}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <form id="rolesForm" action="/" onSubmit={this.handleSubmit}>
              <Grid container spacing={24} className={classes.container}>
                <TextField
                  id="roleName"
                  name="roleName"
                  label="Role Name"
                  placeholder="Eg. Modified,Limited"
                  fullWidth={true}
                  className={classes.textField}
                  margin="normal"
                  value={this.state.RoleName}
                  onChange={this.handleChange}
                  error={
                    this.state.errors.role_name &&
                    this.state.errors.role_name.length > 0
                  }
                  helperText={
                    this.state.errors.role_name && this.state.errors.role_name[0]
                  }
                />
                <List>
                  <ListItem>
                    {/* <ListItemText primary="Admins Permissions" /> */}
                    <Grid container>
                      <Grid item xs>
                        <Typography gutterBottom variant="h6">
                          Admins Permissions
                        </Typography>
                    {this.state.Permissions
                      .map(n => {
                        return (
                        <Grid>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={n.permission}
                                onChange={this.handleCheckboxChange}
                                checked={
                                  this.state.admins_permissions !==
                                  undefined
                                    ? this.state.admins_permissions[n.permission] === 1
                                      ? true
                                      : false
                                    : false
                                }
                              />
                            }
                            label={n.name}
                          />
                        </Grid>
                        );
                      })}
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Grid>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

RoleFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  role: PropTypes.object
};

export default withStyles(styles)(RoleFormDialog);
