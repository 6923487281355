import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import ConstructionUpdatesFormDialog from "./ConstructionUpdatesFormDialog";
import ConstructionUpdatesListing from "./ConstructionUpdatesListing";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";

class ConstructionUpdates extends Component {
  state = {
    construction: [],
    albums: [],
    currentConstructionUpdates: null,
    openConstructionUpdatesFormDialog: false,
    snackBarOpen: false,
    snackBarMessage: "",
    snackBarType: "success",
    isLoading: true,
    isRefreshing: false,
    dialogTitle: "Construction Updates Edit"
  };

  componentDidMount() {
    axios.all([this.getAll(), this.getAllAlbums()]);
  }
  getAll = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "constructionupdates", { headers: headers })
      .then(response => {
        this.setState({ construction: response.data.data }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  getAllAlbums = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "albums", { headers: headers })
      .then(response => {
        this.setState({ albums: response.data.data }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  handleCloseConstructionUpdatesFormDialog = () => {
    this.setState({
      openConstructionUpdatesFormDialog: false,
      currentConstructionUpdates: null,
      Value: ""
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  updateInState = item => {
    let newData = this.state.construction;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == item.id) {
        newData[i] = item;
        break;
      }
    }
    this.setState({ construction: newData, isRefreshing: false });
  };

  handleUpdateConstructionUpdates = formData => {
    this.setState({ isRefreshing: true });
    formData.append("_method", "PUT");
    axios
      .post(
        API_URL +
          "constructionupdates/" +
          this.state.currentConstructionUpdates.id,
        formData,
        { headers: headers }
      )
      .then(response => {
        if (response.status === 500) {
          this.setState({
            openConstructionUpdatesFormDialog: false,
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentConstructionUpdates: null
          });
        } else if (
          response.status === 200 &&
          response.data.message === "form_error"
        ) {
          this.setState({
            isRefreshing: false,
            errors: response.data,
            openConstructionUpdatesFormDialog: true
          });
        } else if (response.status === 200) {
          this.updateInState(response.data);
          this.setState(
            {
              openConstructionUpdatesFormDialog: false,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success",
              currentConstructionUpdates: null
            },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  handleEdit = currConstructionUpdates => {
    // if (!checkAuthorization("construction_permissions", "can_edit_construction")) {
    //   this.setState({
    //     snackBarMessage: "You don't have permission to edit construction updates",
    //     snackBarType: "error",
    //     snackBarOpen: true
    //   });
    // } else {
    this.setState({
      dialogTitle: "Edit Construction Updates",
      currentConstructionUpdates: currConstructionUpdates,
      openConstructionUpdatesFormDialog: true
    });
    // }
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  render() {
    return (
      <Fragment>
        <ConstructionUpdatesListing
          tableTitle="Construction Updates"
          data={this.state.construction}
          orderBy="ID"
          handleEdit={this.handleEdit}
        />
        <ConstructionUpdatesFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseConstructionUpdatesFormDialog}
          isOpen={this.state.openConstructionUpdatesFormDialog}
          currConstructionUpdates={this.state.currentConstructionUpdates}
          handleUpdateConstructionUpdates={this.handleUpdateConstructionUpdates}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          albums={this.state.albums}
        />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default ConstructionUpdates;
