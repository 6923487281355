import React, { Component, Fragment } from "react";
import axios from "axios";
import SnackBar from "../../components/SnackBar";
import GuestInvitationsListing from "./GuestInvitationsListing";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
import { withRouter } from "react-router-dom";

class GuestInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guestInvitations: [],
      currentGuestInvitation: null,
      openGuestInvitationFormDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Guest Invitation Details"
    };
  }

  componentDidMount() {
    this.handleLoading(true);
    this.getAll();
  }

  getAll = () => {
    axios
      .get(API_URL + "guestInvitations", {
        headers: headers
      })
      .then(response => {
        let guestInvitations = response.data.message;
        console.log(guestInvitations);
        this.setState({ guestInvitations: guestInvitations, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  handleSnackBarMessage = (msg, type) => {
    this.setState({
      snackBarMessage: msg,
      snackBarType: type,
      snackBarOpen: true
    });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  render() {
    return (
      <Fragment>
        <GuestInvitationsListing
          tableTitle="Guest Invitations"
          data={this.state.guestInvitations}
          orderBy="id"
          handleSnackBarMessage={this.handleSnackBarMessage}
        />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default withRouter(GuestInvitations);
