import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ExportIcon from "@material-ui/icons/CloudDownload";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Filters from "../../components/Filters";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import { checkAuthorization } from "../../components/AuthService";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import ViewMates from "@material-ui/icons/Group";
import BlockIcon from "@material-ui/icons/Block";
import Sync from "@material-ui/icons/Autorenew";

function getSorting(order, orderBy) {
  if (orderBy === "id") {
    return order === "desc"
      ? (a, b) => (parseInt(b[orderBy]) < parseInt(a[orderBy]) ? -1 : 1)
      : (a, b) => (parseInt(a[orderBy]) < parseInt(b[orderBy]) ? -1 : 1);
  } else if (orderBy === "created_at" || orderBy === "updated_at") {
    return order === "desc"
      ? (a, b) => (new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1)
      : (a, b) => (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1);
  }
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columnData = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "id",
    sortable: true,
  },
  {
    id: "full_name",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
    sortable: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: true,
    label: "Phone",
    sortable: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sortable: true,
  },
  {
    id: "CreatedOn",
    numeric: false,
    disablePadding: true,
    label: "Created_On",
    sortable: false,
  },
  {
    id: "UpdatedOn",
    numeric: false,
    disablePadding: true,
    label: "Updated_On",
    sortable: false,
  },
  {
    id: "controls",
    numeric: false,
    disablePadding: true,
    label: "Controls",
    sortable: false,
  },
];

let filters = [
  {
    Field: "full_name",
    Type: "TEXT",
    Values: "",
    Operator: "TEXT_EQUAL",
    Title: "Full Name",
  },
  {
    Field: "email",
    Type: "TEXT",
    Values: "",
    Operator: "TEXT_EQUAL",
    Title: "Email",
  },
  {
    Field: "phone_number",
    Type: "TEXT",
    Values: "",
    Operator: "TEXT_EQUAL",
    Title: "Phone Number",
  },
];

class EnhancedTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map((column) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.sortable ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <div> {column.label}</div>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  root2: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      allData: [],
      selectedData: [],
      openDeleteConfirmationDialog: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.selectedData);
    this.setState({
      data: nextProps.data,
      allData: nextProps.data,
      selectedData: nextProps.selectedData,
    });
  }
  render() {
    const { numSelected, classes } = this.props;
    return (
      <div>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subheading">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="title" id="tableTitle">
                Users
                {/* <IconButton aria-label="Sync" onClick={this.props.sync}>
                    <Sync />
                  </IconButton> */}
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  onClick={this.props.handleDeleteSelected}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <React.Fragment />
            )}
          </div>

          <div className={classes.actions}>
            {numSelected > 0 ? (
              <ExcelFile
                element={
                  <Tooltip title="Export Selected">
                    <IconButton aria-label="Export Selected">
                      <ExportIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ExcelSheet data={this.state.selectedData} name="Users">
                  <ExcelColumn label="Full Name" value="full_name" />
                  <ExcelColumn label="Phone Number" value="phone_number" />
                  <ExcelColumn label="Email" value="email" />
                  {/* <ExcelColumn
                    label="Feedback Type"
                    value="feedback_type"
                  /> */}
                  {/* <ExcelColumn
                    label="Feedback Type"
                    value={col => (col.feedback_type ? "Text" : "Photo")}
                  />
                  <ExcelColumn
                    label="Status"
                    value={col => (col.status === 1  ? "In Progress" : col.status === 2 ?  "Pending" : "Closed")}
                  /> */}

                  {/* <ExcelColumn
                    label="Status"
                    value="status"
                  /> */}

                  <ExcelColumn label="Created On" value="created_at" />
                  <ExcelColumn label="Updated On" value="updated_at" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <ExcelFile
                element={
                  <Tooltip title="Export">
                    <IconButton aria-label="Export">
                      <ExportIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ExcelSheet data={this.state.data} name="Users">
                  <ExcelColumn label="Full Name" value="full_name" />
                  <ExcelColumn label="Phone Number" value="phone_number" />
                  <ExcelColumn label="Email" value="email" />

                  {/* <ExcelColumn
                    label="Feedback Type"
                    value="feedback_type"
                  /> */}
                  {/* <ExcelColumn
                    label="Status"
                    value="status"
                  /> */}

                  <ExcelColumn label="Created On" value="created_at" />
                  <ExcelColumn label="Updated On" value="updated_at" />
                </ExcelSheet>
              </ExcelFile>
            )}
          </div>
          {/* <IconButton aria-label="Sync" onClick={this.props.sync}>
            <Sync />
          </IconButton> */}
        </Toolbar>
        <div className={classes.root2}>
          <Filters
            allData={this.props.allData}
            filters={filters}
            handleDataChange={this.props.handleDataChange}
          />
        </div>
      </div>
    );
  }
}
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  resendButton: {
    marginLeft: theme.spacing.unit * 2,
  },
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "created_at",
      selected: [],
      selectedData: [],
      sending: [],
      data: [],
      allData: [],
      page: 0,
      rowsPerPage: 5,
      open: false,
      deleteItems: [],
      confirmDialogOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data, allData: nextProps.data });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState((state) => ({
        selected: state.data.map((n) => n.id),
        selectedData: state.data,
      }));
      return;
    }
    this.setState({ selected: [], selectedData: [] });
  };

  handleClick = (event, users) => {
    let id = users.id;
    const { selected, selectedData } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newSelectedData = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedData = newSelectedData.concat(selectedData, users);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected, selectedData: newSelectedData });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleCloseConfirmation = () => {
    this.setState({ confirmDialogOpen: false, deleteItems: [] });
  };

  handleDataChange = (data) => {
    this.setState({ data: data });
  };

  deleteFromState = (toBeDeleted) => {
    let newData = [];
    for (let i = 0; i < this.state.allData.length; i++) {
      let check = true;
      for (let j = 0; j < toBeDeleted.length; j++) {
        if (this.state.allData[i].id === toBeDeleted[j]) {
          check = false;
          break;
        }
      }
      if (check) {
        newData.push(this.state.allData[i]);
      }
    }
    let newShow = [];
    for (let i = 0; i < this.state.data.length; i++) {
      let check = true;
      for (let j = 0; j < toBeDeleted.length; j++) {
        if (this.state.data[i].id === toBeDeleted[j]) {
          check = false;
          break;
        }
      }
      if (check) {
        newShow.push(this.state.allData[i]);
      }
    }
    this.setState(
      { allData: newData, data: newShow, open: false, toBeDeleted: [] },
      () => {}
    );
    if (toBeDeleted === this.state.selected) {
      this.setState({ selected: [] });
    }
  };

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };

  handleConfirmDelete = () => {
    let selected = this.state.selected;
    this.handleCloseDeleteConfirmationDialog();
    this.setState({ selected: [] }, () => {
      this.props.handleDeleteUsers(selected);
    });
  };

  deleteUser = () => {
    if (!checkAuthorization("can_delete_users")) {
      this.props.showSnackBarMessage(
        "You don't have permission to delete user",
        "error"
      );
    } else {
      this.setState({ openDeleteConfirmationDialog: true });
    }
  };

  handleToggleActive = (id) => {
    if (!checkAuthorization("can_edit_users")) {
      this.props.showSnackBarMessage(
        "You don't have permission to deactivate/activate users",
        "error"
      );
    } else {
      this.props.handleToggleActive(id);
    }
  };

  render() {
    const { classes, handleView, handleDelete } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <div>
        <Paper className={classes.root}>
          <DeleteConfirmationDialog
            title="Delete User"
            text="Are you sure that you want to permanently delete this user?"
            isOpen={this.state.openDeleteConfirmationDialog}
            handleCloseDeleteConfirmationDialog={
              this.handleCloseDeleteConfirmationDialog
            }
            handleConfirmDelete={this.handleConfirmDelete}
          />

          <EnhancedTableToolbar
            numSelected={selected.length}
            allData={this.state.allData}
            data={this.state.data}
            selectedData={this.state.selectedData}
            handleDataChange={this.handleDataChange}
            handleDeleteSelected={() => this.deleteUser()}
            delete={() => this.handleDeleteButton(this.state.selected)}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={(event, property) =>
                  this.handleRequestSort(event, property)
                }
                rowCount={data.length}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onClick={(event) => this.handleClick(event, n)}
                          />
                        </TableCell>
                        <TableCell padding="10">{n.id}</TableCell>
                        <TableCell padding="none">{n.full_name}</TableCell>
                        <TableCell padding="none">{n.phone_number}</TableCell>
                        <TableCell padding="none">{n.email}</TableCell>
                        <TableCell padding="none">{n.created_at}</TableCell>
                        <TableCell padding="none">
                          {n.updated_at == null ? "N/A" : n.updated_at}
                        </TableCell>
                        <TableCell padding="none">
                          <Tooltip title="View" placement="right">
                            <IconButton onClick={() => handleView(n)}>
                              <ViewIcon style={{ fontSize: 25 }} />
                            </IconButton>
                          </Tooltip>
                          {n.is_active == 1 ? (
                            <Tooltip title="Deactivate" placement="right">
                              <IconButton
                                onClick={() => this.handleToggleActive(n.id)}
                              >
                                <BlockIcon
                                  color="error"
                                  style={{ fontSize: 25 }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Activate" placement="right">
                              <IconButton
                                onClick={() => this.handleToggleActive(n.id)}
                              >
                                <DoneIcon
                                  color="primary"
                                  style={{ fontSize: 25 }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Delete">
                            <IconButton
                              className={classes.button}
                              aria-label="Delete"
                              onClick={() => handleDelete([n.id])}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Delete">
                          <IconButton
                            className={classes.button}
                            aria-label="Delete"
                            onClick={() => handleDelete(n)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip> */}

                          {n.mates && n.mates.length > 0 && (
                            <Tooltip title="Show Mates">
                              <IconButton
                                className={classes.button}
                                aria-label="Show Mates"
                                onClick={() => this.props.handleShowMates(n)}
                              >
                                <ViewMates />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip>
                            <IconButton
                              aria-label="SyncUser"
                              onClick={() => this.props.syncWithOrchestra(n)}
                            >
                              <Sync />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(EnhancedTable));
