import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import AdminsFormDialog from "./AdminsFormDialog";
import AdminsListing from "./AdminsListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";

class Admins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admins: [],
      currentAdmin: null,
      selectedAdmins: [],
      roles: [],
      openAdminsFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Administrator Account",
      reset: false,
      errors: {
        FirstName: [],
        LastName: [],
        Email: [],
        Password: [],
        ConfirmPassword: []
      }
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios.all([this.getAll(), this.getAllRoles()]);
  }

  getAll = () => {
    axios
      .get(API_URL + "admins", {
        headers: headers
      })
      .then(response => {
        let admins = response.data;

        admins = admins.map(item => {
          if (item["first_name"] && item["last_name"]) {
            item["FullName"] = item["first_name"] + " " + item["last_name"];
          } else {
            item["FullName"] = "";
          }
          return item;
        });
        this.setState({ admins: admins, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getAllRoles = () => {
    axios
      .get(API_URL + "roles", {
        headers: headers
      })
      .then(response => {
        this.setState({ roles: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_create_admins")) {
      this.setState({
        snackBarMessage: "You don't have permission to create admins",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Create New Administrator Account",
        openAdminsFormDialog: true,
        reset: true
      });
    }
  };

  handleCloseAdminsFormDialog = () => {
    this.setState({
      openAdminsFormDialog: false,
      errors: {
        FirstName: [],
        LastName: [],
        Email: [],
        Password: [],
        ConfirmPassword: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = item => {
    let newData = this.state.admins;
    item["FullName"] = item["first_name"] + " " + item["last_name"];
    newData.push(item);
    this.setState({ item: newData, isRefreshing: false });
  };

  handleSubmitAdmin = formData => {
    this.setState({ isRefreshing: true, reset: false });

    axios
      .post(API_URL + "admins", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openAdminsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        }
      })
      .catch(error => {
        if (typeof error.response.data.message == "object") {
          this.setState({
            reset: false,
            isRefreshing: false,
            openAdminsFormDialog: true,
            errors: error.response.data.message
          });
        } else {
          this.setState({
            openAdminsFormDialog: false,
            snackBarMessage: error.response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentAdmin: null,
            reset: true
          });
        }
      });
  };

  updateInState = item => {
    item["FullName"] = item["first_name"] + " " + item["last_name"];
    let newData = this.state.admins;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === item.id) {
        newData[i] = item;
        break;
      }
    }
    this.setState({ admins: newData, isRefreshing: false });
  };

  handleUpdateAdmin = formData => {
    this.setState({ isRefreshing: true, reset: false });

    formData.append("_method", "put");
    axios
      .post(API_URL + "admins/" + this.state.currentAdmin.id, formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openAdminsFormDialog: false,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            currentAdmin: null
          });
        }
      })
      .catch(error => {
        if (typeof error.response.data.message == "object") {
          this.setState({
            reset: false,
            isRefreshing: false,
            openAdminsFormDialog: true,
            errors: error.response.data.message
          });
        } else {
          this.setState({
            openAdminsFormDialog: false,
            snackBarMessage: error.response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            reset: true
          });
        }
      });
  };

  handleEdit = admin => {
    if (!checkAuthorization("can_edit_admins")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit admins",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Administrator Account",
        currentAdmin: admin,
        openAdminsFormDialog: true,
        reset: true
      });
    }
  };

  removeDeletedFromData = items => {
    let data = this.state.admins;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ admins: newData });
    this.handleRefreshing(false);
  };

  handleDelete = admin => {
    if (!checkAuthorization("can_delete_admins")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete admins",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        currentAdmin: admin,
        openDeleteConfirmationDialog: true
      });
    }
  };

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);

    if (toBeDeleted instanceof Array) {
      let formData = new FormData();
      formData.append("itemsIDs", JSON.stringify(toBeDeleted));

      axios
        .post(API_URL + "admins/BatchDelete", formData, {
          headers: headers
        })
        .then(response => {
          return response.data;
        })
        .then(response => {
          if (response.res === 1) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "success",
              snackBarMessage: response.data.message
            });
            this.removeDeletedFromData(toBeDeleted);
          } else if (response.res === 0) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "error",
              snackBarMessage: response.data.message
            });
          }
        });
    } else {
      axios
        .delete(API_URL + "admins/" + toBeDeleted, { headers: headers })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "success",
              snackBarMessage: response.data.message
            });
            this.removeDeletedFromData(toBeDeleted);
          }
        })
        .catch(error => {
          this.setState({
            snackBarOpen: true,
            snackBarType: "error",
            snackBarMessage: error.response.data.message
          });
        });
    }
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  deleteSlectedAdmins = selectedAdmins => {
    if (!checkAuthorization("can_delete_admins")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete admins",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedAdmins: selectedAdmins
      });
    }
  };

  handleConfirmDeleteSelected = () => {
    let selectedAdmins = this.state.selectedAdmins;
    this.handleCloseDeleteSelectedConfirmationDialog();
    this.handleRefreshing(true);
    let formData = new FormData();
    formData.append("itemsIDs", JSON.stringify(selectedAdmins));
    axios
      .post(API_URL + "admins/BatchDelete", formData, { headers: headers })
      .then(response => {
        return response.data;
      })
      .then(response => {
        if (response.res === 0) {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false
          });
        } else {
          this.removeDeletedFromData(selectedAdmins);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            isRefreshing: false
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleCloseDeleteSelectedConfirmationDialog = () => {
    this.setState({ openDeleteSelectedConfirmationDialog: false });
  };

  handleSnackBarMessage = (message, type) => {
    this.setState({
      snackBarOpen: true,
      snackBarType: type,
      snackBarMessage: message
    });
  };

  render() {
    return (
      <Fragment>
        <AdminsListing
          tableTitle="Administrators"
          data={this.state.admins}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
          deleteSlectedAdmins={this.deleteSlectedAdmins}
          handleSnackBarMessage={this.handleSnackBarMessage}
        />
        <AdminsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseAdminsFormDialog}
          isOpen={this.state.openAdminsFormDialog}
          roles={this.state.roles}
          admin={this.state.currentAdmin}
          handleSubmitAdmin={this.handleSubmitAdmin}
          handleUpdateAdmin={this.handleUpdateAdmin}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          reset={this.state.reset}
          errors={this.state.errors}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default Admins;
