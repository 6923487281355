import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import DashboardListing from "./DashboardListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers, BASE_URL } from "../../constants/Constants";
import Button from "@material-ui/core/Button";
import CloudDownload from "@material-ui/icons/CloudDownload";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getServiceRequest: [],
      CustomersCount: 0,
      currentServicesCount: 0,
      unReadfeedbackCount: 0,
      unReadServicesCount: 0,
      recentGeneralFeedback: [],
      recentServicesFeedback: [],
      customersDemographics: [],
      customersPlatforms: [],
      highestServicesRequested: [],
      lowestServicesRequested: [],
      servicesRequested: [],
      highestRatedServices: [],
      lowestRatedServices: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios.all([
      this.recentGeneralFeedback(),
      this.recentServicesFeedback(),
      this.servicesRequested(),
      this.getServiceRequest(),
      this.highestRatedServices(),
      this.lowestRatedServices(),
      this.getCustomersCount(),
      this.customersDemographics(),
      this.customersPlatforms(),
      this.highestServicesRequested(),
      this.currentServicesCount(),
      this.unReadfeedbackCount(),
      this.unReadServicesCount(),
      this.lowestServicesRequested(),
      this.customersTypes()
    ]);
    this.interval = setInterval(this.recentGeneralFeedback, 180000);
    this.interval = setInterval(this.recentServicesFeedback, 180000);
    this.interval = setInterval(this.servicesRequested, 180000);
    this.interval = setInterval(this.getServiceRequest, 180000);
    this.interval = setInterval(this.getCustomersCount, 180000);
    this.interval = setInterval(this.customersDemographics, 180000);
    this.interval = setInterval(this.customersPlatforms, 180000);
    this.interval = setInterval(this.highestServicesRequested, 180000);
    this.interval = setInterval(this.currentServicesCount, 180000);
    this.interval = setInterval(this.unReadfeedbackCount, 180000);
    this.interval = setInterval(this.unReadServicesCount, 180000);
    this.interval = setInterval(this.lowestServicesRequested, 180000);
    this.interval = setInterval(this.highestRatedServices, 180000);
    this.interval = setInterval(this.lowestRatedServices, 180000);
    this.interval = setInterval(this.customersTypes, 180000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getCustomersCount = () => {
    axios
      .get(API_URL + "users/customerCount", {
        headers: headers
      })
      .then(response => {
        this.setState({ CustomersCount: response.data.data });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  currentServicesCount = () => {
    axios
      .get(API_URL + "servicesRequests/getSerivcesCount", {
        headers: headers
      })
      .then(response => {
        this.setState({ currentServicesCount: response.data.data });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  unReadfeedbackCount = () => {
    axios
      .get(API_URL + "generalfeedback/getPendingCount", {
        headers: headers
      })
      .then(response => {
        this.setState({ unReadfeedbackCount: response.data.data });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  unReadServicesCount = () => {
    axios
      .get(API_URL + "servicesfeedback/getPendingCount", {
        headers: headers
      })
      .then(response => {
        this.setState({ unReadServicesCount: response.data.data });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  getServiceRequest = () => {
    axios
      .get(API_URL + "servicesRequests", { headers: headers })
      .then(response => {
        this.setState({ getServiceRequest: response.data.data }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  recentGeneralFeedback = () => {
    // this.handleLoading(true);
    axios
      .get(API_URL + "generalfeedback", { headers: headers })
      .then(response => {
        let recentGeneralFeedback = response.data.data;
        this.setState({ recentGeneralFeedback: recentGeneralFeedback }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  recentServicesFeedback = () => {
    // this.handleLoading(true);
    axios
      .get(API_URL + "servicesfeedback", { headers: headers })
      .then(response => {
        let recentServicesFeedback = response.data.data;
        this.setState(
          { recentServicesFeedback: recentServicesFeedback },
          () => {
            this.handleLoading(false);
          }
        );
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  customersDemographics = () => {
    // this.handleLoading(true);
    axios
      .get(API_URL + "users/customersDemographics", {
        headers: headers
      })
      .then(response => {
        let demographicsData = response.data;
        let malesCount = demographicsData.data["male"];
        let femalesCount = demographicsData.data["female"];
        let undefinedCount = demographicsData.data["undefined"];
        let result = {
          labels: ["Males", "Females", "Not Specified"],
          datasets: [
            {
              label: "Customers Demographics",
              backgroundColor: [
                "rgb(32, 79, 140)",
                "rgb(181, 156, 217)",
                "rgb(217, 217, 217)"
              ], // borderColor: "rgba(255,99,132,1)",
              borderWidth: 0,
              // hoverBackgroundColor: "rgba(255,99,132,0.4)",
              // hoverBorderColor: "rgb(144,152,172,100)",
              data: [malesCount, femalesCount, undefinedCount]
            }
          ]
        };
        this.setState({ customersDemographics: result });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  customersPlatforms = () => {
    // this.handleLoading(true);
    axios
      .get(API_URL + "users/customersPlatforms", {
        headers: headers
      })
      .then(response => {
        let platformsData = response.data;
        let iosCount = platformsData.data["ios"];
        let androidCount = platformsData.data["android"];
        // let otherCount = platformsData.data["other"];
        let result = {
          // labels: ["IOS", "Android", "Other"],
          labels: ["IOS", "Android"],

          datasets: [
            {
              label: "Customers Platforms",
              // backgroundColor: "rgba(255,99,132,0.2)",
              backgroundColor: [
                "rgba(166, 177, 183,100)",
                "rgba(91,206,72,100)"
              ],
              // borderColor: "rgba(255,99,132,1)",
              borderWidth: 0,
              // hoverBackgroundColor: "rgba(71,171,108,0.6)",
              // hoverBorderColor: "rgb(144,152,172,100)",
              // data: [iosCount, androidCount, otherCount]
              data: [iosCount, androidCount]
            }
          ]
        };
        this.setState({ customersPlatforms: result });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  highestServicesRequested = () => {
    axios
      .get(API_URL + "servicesRequests/servicesRequestedCount", {
        headers: headers
      })
      .then(response => {
        let items = response.data.data;
        let sortingItems = items.sort(function(a, b) {
          return b.count - a.count;
        });
        this.setState({ highestServicesRequested: sortingItems }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  lowestServicesRequested = () => {
    axios
      .get(API_URL + "servicesRequests/servicesRequestedCount", {
        headers: headers
      })
      .then(response => {
        let items = response.data.data;
        let sortingItems = items.sort(function(a, b) {
          return a.count - b.count;
        });
        this.setState({ lowestServicesRequested: sortingItems }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  servicesRequested = () => {
    // this.handleLoading(true);
    axios
      .get(API_URL + "servicesRequests/servicesRequestedCount", {
        headers: headers
      })
      .then(response => {
        let services = response.data.data;
        let servicesName = [];
        let servicesCount = [];
        let name = "";
        services.slice(0, 5).map(item => {
          if (item["name"] == null) {
            name = "N/A";
          } else {
            name = item["name"];
          }
          let count = item["count"];
          servicesName.push(name);
          servicesCount.push(count);
        });
        let result = {
          labels: servicesName,
          datasets: [
            {
              label: "Services Requested ",
              // backgroundColor: "rgba(255,99,132,0.2)",
              backgroundColor: [
                "rgb(255, 130, 28)",
                "rgb(64, 196, 255)",
                "rgb(233, 237, 242)",
                "rgb(94,114,228,100)",
                "rgb(41, 97, 255)"
              ],
              // borderColor: "rgba(255,99,132,1)",
              borderWidth: 0,
              // hoverBackgroundColor: "rgba(255,99,132,0.4)",
              // hoverBorderColor: "rgb(144,152,172,100)",
              data: servicesCount
            }
          ]
        };
        this.setState({ servicesRequested: result });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  highestRatedServices = () => {
    axios
      .get(API_URL + "servicesfeedback/ratedServices", {
        headers: headers
      })
      .then(response => {
        let items = response.data.data;
        let sortingItems = items.sort(function(a, b) {
          return b.avg - a.avg;
        });
        this.setState({ highestRatedServices: sortingItems });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  lowestRatedServices = () => {
    axios
      .get(API_URL + "servicesfeedback/ratedServices", {
        headers: headers
      })
      .then(response => {
        let items = response.data.data;
        let sortingItems = items.sort(function(a, b) {
          return a.avg - b.avg;
        });
        this.setState({ lowestRatedServices: sortingItems });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  customersTypes = () => {
    axios
      .get(API_URL + "users/usersTypes", {
        headers: headers
      })
      .then(response => {
        let items = response.data.data;
        console.log(items);
        let result = {
          labels: ["Owners", "Guests", "Home Mates"],
          datasets: [
            {
              label: "Customers Types",
              backgroundColor: [
                "rgb(32, 79, 140)",
                "rgb(181, 156, 217)",
                "rgb(217, 217, 217)"
              ],
              borderWidth: 0,
              data: [
                items[0].users_count,
                items[1].users_count,
                items[2].users_count
              ]
            }
          ]
        };
        this.setState({ usersTypes: result });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  render() {
    return (
      <Fragment>
        <DashboardListing
          tableTitle="Recent Customers"
          CustomersCount={this.state.CustomersCount}
          currentServicesCount={this.state.currentServicesCount}
          unReadfeedbackCount={this.state.unReadfeedbackCount}
          unReadServicesCount={this.state.unReadServicesCount}
          recentGeneralFeedback={this.state.recentGeneralFeedback}
          recentServicesFeedback={this.state.recentServicesFeedback}
          customersDemographics={this.state.customersDemographics}
          highestRatedServices={this.state.highestRatedServices}
          lowestRatedServices={this.state.lowestRatedServices}
          customersPlatforms={this.state.customersPlatforms}
          highestServicesRequested={this.state.highestServicesRequested}
          lowestServicesRequested={this.state.lowestServicesRequested}
          servicesRequested={this.state.servicesRequested}
          getServiceRequest={this.state.getServiceRequest}
          usersTypes={this.state.usersTypes}
          orderBy="ID"
        />

        {this.state.isLoading && <Loader />}
      </Fragment>
    );
  }
}

export default Dashboard;
