import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TinyMce from "../../../components/TinyMce";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },
  uploadInput: {
    display: "none"
  },
  imageError: {
    marginTop: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ItemsFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      items: [],
      id: null,
      title: "",
      title_ar: "",
      description: "",
      price: "",
      is_active: 0,
      sort: "",
      errors: {
        title: [],
        title_ar: [],
        description: [],
        price: [],
        is_active: [],
        sort: []
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.items != this.state.items)
      this.setState({ items: nextProps.items });

    if (nextProps.item != null) {
      let item = nextProps.item;
      this.setState({
        id: item.id,
        title: item.title,
        title_ar: item.title_ar,
        description: item.description,
        sort: parseInt(item.sort),
        price: parseInt(item.price),
        is_active: parseInt(item.is_active)
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      title: "",
      title_ar: "",
      description: "",
      price: "",
      is_active: 0,
      sort: ""
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handlePriceChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.price = ["Please Enter Valid Value"];
    else errors.price = "";
    this.setState({ price: value });
  };

  handleTitleChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.title = ["Please Enter Valid Value"];
    else errors.title = "";
    this.setState({ title: value });
  };

  handleArabicTitleChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.title_ar = ["Please Enter Valid Value"];
    else errors.title_ar = "";
    this.setState({ title_ar: value });
  };

  handleDescriptionChange = value => {
    let errors = this.state.errors;
    if (value === "") errors.description = ["Please Enter Valid Value"];
    else errors.description = "";
    this.setState({ description: value.target.getContent() });
  };

  handleIsActiveChange = event => {
    this.state.is_active == 0
      ? this.setState({ is_active: 1 })
      : this.setState({ is_active: 0 });
  };

  handleSortChange = event => {
    let value = event.target.value;
    if (value > 0) this.setState({ sort: value });
    else {
      let errors = this.state.errors;
      errors.sort = ["Sort number must be more than or equal 0"];
      this.setState({ errors: errors });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("title_ar", this.state.title_ar);
    formData.append("is_active", this.state.is_active);
    formData.append("price", this.state.price);
    formData.append("category_id", this.props.category_id);
    formData.append("description", this.state.description);
    formData.append("sort", this.state.sort);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const { title, title_ar, price, description, is_active, sort } = this.state;
    if (
      title === "" ||
      title_ar === "" ||
      price === "" ||
      description === "" ||
      is_active === "" ||
      sort === ""
    ) {
      disabled = true;
    }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="itemForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Title"
                      value={this.state.title}
                      onChange={event => this.handleTitleChange(event)}
                      error={
                        this.state.errors.title &&
                        this.state.errors.title.length > 0
                      }
                      helperText={
                        this.state.errors.title && this.props.errors.title[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Arabic Title"
                      value={this.state.title_ar}
                      onChange={event => this.handleArabicTitleChange(event)}
                      error={
                        this.state.errors.title_ar &&
                        this.state.errors.title_ar.length > 0
                      }
                      helperText={
                        this.state.errors.title_ar &&
                        this.props.errors.title_ar[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Sort"
                      price="number"
                      value={this.state.sort}
                      onChange={event => this.handleSortChange(event)}
                      error={
                        this.state.errors.sort &&
                        this.state.errors.sort.length > 0
                      }
                      helperText={
                        this.state.errors.sort && this.state.errors.sort[0]
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Price"
                      price="number"
                      value={this.state.price}
                      onChange={event => this.handlePriceChange(event)}
                      error={
                        this.state.errors.price &&
                        this.state.errors.price.length > 0
                      }
                      helperText={
                        this.state.errors.price && this.state.errors.price[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <label htmlFor="IsActive">Is Active</label>
                    <Checkbox
                      id="IsActive"
                      checked={this.state.is_active}
                      onClick={event => this.handleIsActiveChange(event)}
                    />
                  </Grid>
                  <TinyMce
                    title="Description"
                    data={this.state.description}
                    handleChange={this.handleDescriptionChange}
                    error={
                      this.state.errors.description &&
                      this.state.errors.description.length > 0
                    }
                    helperText={
                      this.state.errors.description &&
                      this.state.errors.description[0]
                    }
                  />
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

ItemsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(ItemsFormDialog);
