import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { TimeFormatInput } from "material-ui-next-pickers";
import moment from "moment";
import { red } from "@material-ui/core/colors";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },
  uploadInput: {
    display: "none"
  },
  imageError: {
    marginTop: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class TimeRangesListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      ranges: [],
      timeError: 0,
      id: null,
      from: null,
      to: null,
      is_active: 0,
      errors: {
        from: [],
        to: [],
        is_active: []
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.ranges != this.state.ranges)
      this.setState({ ranges: nextProps.ranges });

    if (nextProps.request != null) {
      let request = nextProps.request;
      this.setState({
        id: request.id,
        from: new Date(moment(request.from, "HH:mm:ss")),
        to: new Date(moment(request.to, "HH:mm:ss")),
        is_active: request.is_active
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      ranges: [],
      from: null,
      timeError: 0,
      to: null,
      is_active: 0
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleToChange = date => {
    this.setState({ to: date }, () => {
      let timeDiff = moment
        .duration(
          moment(this.state.to, "YYYY-MM-DD HH:mm:ss").diff(
            moment(this.state.from, "YYYY-MM-DD HH:mm:ss")
          )
        )
        .asHours();
      if (timeDiff <= 0) {
        this.setState({ timeError: 1 });
      } else {
        this.setState({ timeError: 0 });
      }
    });
  };

  handleFromChange = date => {
    this.setState({ from: date }, () => {
      let timeDiff = moment
        .duration(
          moment(this.state.to, "YYYY-MM-DD HH:mm:ss").diff(
            moment(this.state.from, "YYYY-MM-DD HH:mm:ss")
          )
        )
        .asHours();
      if (timeDiff <= 0) {
        this.setState({ timeError: 1 });
      } else {
        this.setState({ timeError: 0 });
      }
    });
  };

  handleIsActiveChange = event => {
    this.state.is_active == 0
      ? this.setState({ is_active: 1 })
      : this.setState({ is_active: 0 });
  };

  handleSubmit = event => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("from", moment(this.state.from).format("LT"));
    formData.append("to", moment(this.state.to).format("LT"));
    formData.append("is_active", this.state.is_active);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const { from, to, is_active, timeError } = this.state;
    if (from === "" || to === "" || is_active === "" || timeError === 1) {
      disabled = true;
    }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="requestForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <label htmlFor="From">From :</label>
                    <TimeFormatInput
                      name="time-input"
                      value={this.state.from}
                      onChange={this.handleFromChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="To">To :</label>
                    <TimeFormatInput
                      name="time-input"
                      value={this.state.to}
                      onChange={this.handleToChange}
                    />
                  </Grid>
                  {this.state.timeError ? (
                    <Grid item xs={12}>
                      <h6 style={{ color: "red" }}>
                        From must be earlier than To
                      </h6>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={6}>
                    <label htmlFor="IsActive">Is Active</label>
                    <Checkbox
                      id="IsActive"
                      checked={this.state.is_active}
                      onClick={event => this.handleIsActiveChange(event)}
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

TimeRangesListing.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(TimeRangesListing);
