import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    minWidth: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
});
class StepsResult extends Component {
  getUserDisplay = () => {
    let user = this.props.users.find(item => {
      return item.id === this.props.user_id;
    });
    return user.full_name ? user.full_name : user.phone_number;
  };
  render() {
    const { classes } = this.props;
    let devices = "ALL";
    switch (this.props.device) {
      case 0:
        devices = "ALL";
        break;
      case 1:
        devices = "IOS ONLY";
        break;
      case 2:
        devices = "Android ONLY";
        break;
      default:
        break;
    }

    let gender = "ALL";
    switch (this.props.gender) {
      case 0:
        gender = "ALL";
        break;
      case 1:
        gender = "Male";
        break;
      case 2:
        gender = "Female";
        break;
      default:
        break;
    }

    return (
      <div className={classes.root}>
        {this.props.filtrationType == 1 ? (
          <ExpansionPanel expanded={true}>
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                Notification Content
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>User: {this.getUserDisplay()}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <React.Fragment>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary>
                <Typography className={classes.heading}>
                  Notification Content
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Message:{" "}
                  {this.props.message !== ""
                    ? this.props.message
                    : "Pre Defined Content"}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary>
                <Typography className={classes.heading}>
                  Gender Filteration
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  You Selected:
                  {gender}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary>
                <Typography className={classes.heading}>
                  Selected Devices
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>You Selected: {devices}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary>
                <Typography className={classes.heading}>Target Ages</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  From {this.props.min} To {this.props.max}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary>
                <Typography className={classes.heading}>Schedule</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  {!this.props.scheduleType && this.props.sendDateTime
                    ? moment(this.props.sendDateTime).format("MMM Do YYYY")
                    : "Send Now"}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(StepsResult);
