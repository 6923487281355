import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import UsersFormDialog from "./UsersFormDialog";
import UsersListing from "./UsersListing";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers, BASE_URL } from "../../constants/Constants";
import Mates from "./Mates";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      currentUser: null,
      selectedUsers: [],
      openUsersFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      matesDialogOpen: false,
      userMates: []
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "users", { headers: headers })
      .then(response => {
        let users = response.data.data;
        this.setState({ users: users }, () => {
          this.handleLoading(false);
        });
      })
      .catch(error => {
        this.handleLoading(false);
        console.log(error);
      });
  };

  handleCloseUsersFormDialog = () => {
    this.setState({
      openUsersFormDialog: false,
      currentUser: null
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  handleDelete = user => {
    if (!checkAuthorization("can_delete_users")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete users",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        currentUser: user,
        openDeleteConfirmationDialog: true
      });
    }
  };

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };


  handleConfirmDelete = () => {
    this.handleCloseDeleteConfirmationDialog();
    this.handleRefreshing(true);

    axios
      .delete(API_URL + "users/" + this.state.currentUser, {
        headers: headers
      })
      .then(response => {
        if (response.status === 500) {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false
          });
        } else {
          this.setState(
            {
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  handleDeleteUsers = itemsIDs => {
    let formData = new FormData();
    formData.append("items", JSON.stringify(itemsIDs));
    this.handleRefreshing(true);
    axios
      .post(API_URL + "users/batchDelete", formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.removeDeletedFromData(itemsIDs);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            });
        } else if (response.res === 0) {
          this.handleRefreshing(false);
          this.setState({
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error"
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  removeDeletedFromData = items => {
    let data = this.state.users;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ feedback: newData });
    this.handleRefreshing(false);
  };

  handleSnackBarMessage = (message, type) => {
    this.setState({
      snackBarMessage: message,
      snackBarType: type,
      snackBarOpen: true
    });
  };

  toggleActive = id => {
    if (id) {
      this.handleRefreshing(true);
      axios
        .post(API_URL + "users/toogleActive/" + id, null, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.updateInState(response.data.data);
            this.handleSnackBarMessage(response.data.message, "success");
          } else {
            this.handleRefreshing(false);
            this.handleSnackBarMessage(response.message, "error");
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.handleSnackBarMessage(
        "Something Went Wrong! Please try again later",
        "error"
      );
    }
  };

  handleView = user => {
    if (user) {
      if (!checkAuthorization("can_view_users")) {
        this.setState({
          snackBarMessage: "You don't have permission to view users",
          snackBarType: "error",
          snackBarOpen: true
        });
      } else {
        this.setState({
          openUsersFormDialog: true,
          reset: true,
          currentUser: user
        });
      }
    } else {
      this.setState({
        snackBarMessage: "Something Went Wrong Please Try again later",
        snackBarType: "error",
        snackBarOpen: true
      });
    }
  };

  updateInState = user => {
    let newData = this.state.users;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == user.id) {
        newData[i] = user;
        break;
      }
    }
    this.setState({ users: newData, isRefreshing: false });
  };

  handleExport = selectedUsers => {
    this.setState({
      selectedUsers: selectedUsers
    });
    console.log(selectedUsers);
    let formData = new FormData();
    formData.append("exItemsids", JSON.stringify(selectedUsers));
    axios
      .post(API_URL + "users/export", formData)
      .then(response => {
        console.log(response.data);
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = BASE_URL + response.data.data;
        link.target = "__blank";
        link.setAttribute("download", "Users.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        this.handleRefreshing(false);
        console.log(error);
      });
  };

  showMates = user => {
    this.setState({
      userMates: user.mates,
      matesDialogOpen: true
    });
  };

  handleMatesClose = () => {
    this.setState({ matesDialogOpen: false, userMates: [] });
  };

  // sync = () => {
  //   this.handleRefreshing(true);
  //   axios
  //     .get(API_URL + "users/sync/", {
  //       headers: headers
  //     })
  //     .then(response => {
  //       if (response.status === 200) {
  //         this.handleSnackBarMessage(response.data.message, "success");
  //       } else {
  //         this.handleSnackBarMessage(
  //           "Something Went Wrong Please Try again later",
  //           "error"
  //         );
  //       }
  //       this.handleRefreshing(false);
  //     })
  //     .catch(err => {
  //       this.handleRefreshing(false);
  //       console.log(err);
  //     });
  // };

  syncWithOrchestra = user => {
    this.handleRefreshing(true);
    axios
      .get(API_URL + "users/syncUserWithOrchestra/" + user.id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.handleSnackBarMessage("Data synced successfully", "success");
        } else {
          this.handleSnackBarMessage(
            "Something Went Wrong Please Try again later",
            "error"
          );
        }
        this.handleRefreshing(false);
      })
      .catch(err => {
        this.handleRefreshing(false);
        console.log(err);
      });
  };

  render() {
    return (
      <Fragment>
        <UsersListing
          tableTitle="Users"
          data={this.state.users}
          orderBy="id"
          // handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
          // deleteSlectedUsers={this.deleteSlectedUsers}
          handleSnackBarMessage={this.handleSnackBarMessage}
          handleToggleActive={this.toggleActive}
          handleView={this.handleView}
          handleExport={this.handleExport}
          handleShowMates={this.showMates}
          userMates={this.state.userMates}
          // sync={this.sync}
          handleDelete={this.handleDelete}
          handleDeleteUsers={this.handleDeleteUsers}
          syncWithOrchestra={this.syncWithOrchestra}
        />
        <Mates
          mates={this.state.userMates}
          open={this.state.matesDialogOpen}
          handleClose={this.handleMatesClose}
        />
        <UsersFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseUsersFormDialog}
          isOpen={this.state.openUsersFormDialog}
          user={this.state.currentUser}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
        />

        <DeleteConfirmationDialog
          title="Delete User"
          text="Are you sure that you want to permanently delete this User?"
          isOpen={this.state.openDeleteConfirmationDialog}
          handleCloseDeleteConfirmationDialog={
            this.handleCloseDeleteConfirmationDialog
          }
          handleConfirmDelete={this.handleConfirmDelete}
        />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default Users;
