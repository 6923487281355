import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import EventsFormDialog from "./EventsFormDialog";
import EventsListing from "./EventsListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
import { withRouter, Link } from "react-router-dom";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      projects: [],
      albums: [],
      errors: {
      title: [],
      description: [],
      cover_photo: [],
      from: [],
      to: [],
      project_id: [],
      album_id: []
      },
      currentEvent: null,
      selectedEvents: [],
      openEventsFormDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Event"
    };
  }

  componentDidMount() {
    this.handleLoading(true);
    this.getAll();
    this.getProjects();
    this.getAlbums();
  }

  getAll = () => {
    axios
      .get(API_URL + "events/all", {
        headers: headers
      })
      .then(response => {
        let events = response.data;
        console.log(events);
        this.setState({ events: events, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getProjects = () => {
    axios
      .get(API_URL + "projects", {
        headers: headers
      })
      .then(response => {
        let projects = response.data.data;
        this.setState({ projects: projects, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getAlbums = () => {
    axios
      .get(API_URL + "albums", {
        headers: headers
      })
      .then(response => {
        let albums = response.data.data;
        this.setState({ albums: albums, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_events")) {
      this.setState({
        snackBarMessage: "You don't have permission to create Events",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
    this.setState({
      dialogTitle: "Add Event",
      openEventsFormDialog: true,
      reset: true
    });
    }
  };

  handleCloseEventFormDialog = () => {
    this.setState({
      openEventsFormDialog: false,
      currentEvent: null,
      errors: {
        Title: [],
        Description: [],
        CoverPhoto: [],
        From: [],
        TO: [],
        ProjectID: [],
        album_id: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = event => {
    let newData = this.state.events;
    newData.push(event);
    this.setState({ events: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "event", formData, { headers: headers })

      .then(response => {
        if (response.status === 201) {
            this.addToState(response.data.data);
            this.setState({
              currentEvent: null,
              reset: true,
              openEventsFormDialog: false,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              isRefreshing: false,
              snackBarType: "success"
            });
        }}).catch(error => {
            if (error.response.status === 401){
                this.setState({
                    reset: false,
                    isRefreshing: false,
                    openEventsFormDialog: true,
                    errors: error.response.data.message
                });
            }
            else{
            this.setState({
                snackBarOpen: true,
                openEventsFormDialog: true,
                snackBarType: "error",
                snackBarMessage: error.response.statusText,
                isRefreshing: false
              });
            }
        });
  };

  updateInState = event => {
    let newData = this.state.events;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === event.id) {
        newData[i] = event;
        break;
      }
    }
    this.setState({ events: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "event/" + this.state.currentEvent.id, formData, {
        headers: headers
      })
      .then(response => {
         if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            currentEvent: null,
            reset: true,
            isRefreshing: false,
            openEventsFormDialog: false,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        }
      })
      .catch(error => {
        if (error.response.status === 401){
            this.setState({
                reset: false,
                isRefreshing: false,
                openEventsFormDialog: true,
                errors: error.response.data.message
            });
        }
        else{
        this.setState({
            snackBarOpen: true,
            openEventsFormDialog: true,
            snackBarType: "error",
            snackBarMessage: error.response.statusText,
            isRefreshing: false
          });
        }
    });
  };

  handleEdit = event => {
    if (!checkAuthorization("can_edit_events")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit events",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit events",
        currentEvent: event,
        openEventsFormDialog: true
      });
    }
  };


  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.events;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ events: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedEvents = selectedEvents => {
    if (!checkAuthorization("events")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete events",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedEvents: selectedEvents
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted instanceof Array) {
    toBeDeleted.forEach(id =>  {
        axios
          .delete(API_URL + "event/" + id, {
            headers: headers
          })
          .then(response => {
            if (response.status === 200) {
                this.setState({
                  snackBarOpen: true,
                  snackBarType: "success",
                  snackBarMessage: response.data.message,
                });
                this.removeDeletedFromData(toBeDeleted);
              } 
          }).catch (error => {
            if (error.response.status === 500) {
            this.setState({
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: error.response.statusText,
                isRefreshing: false
              });
            }
        });
      })
    } else {
      axios
        .delete(API_URL + "event/" + toBeDeleted, { headers: headers })
        .then(response => {
            console.log(response);
          if (response.status === 200) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "success",
              snackBarMessage: response.data.message,
            });
            this.removeDeletedFromData(toBeDeleted);
          } 
        }).catch (error => {
            if (error.response.status === 500) {
            this.setState({
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: error.response.statusText,
                isRefreshing: false
              });
            }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <EventsListing
          tableTitle="Events"
          data={this.state.events}
          orderBy="ID"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <EventsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseEventFormDialog}
          isOpen={this.state.openEventsFormDialog}
          event={this.state.currentEvent}
          projects={this.state.projects}
          albums={this.state.albums}
          reset={this.state.reset}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          errors={this.state.errors}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default withRouter(Events);
