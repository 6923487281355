import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TinyMce from "../../components/TinyMceForm";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ImageUploader from "../../components/ImageUploader";
import { BASE_URL } from "../../constants/Constants.js";
import { isImage } from "../../components/FileValidation";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },

  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },

  uploadInput: {
    display: "none"
  },
  addMargins: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProjectsFormDialog extends Component {
  state = {
    isOpen: false,
    ID: null,
    Title: "",
    Description: "",
    Image: "",
    Logo: "",
    IsActive: 0,
    IsActiveSwitch: false,
    uploadedImage: null,
    uploadedLogo: null,
    errors: {
      Title: [],
      Type: [],
      Description: [],
      image: [],
      logo: [],
      IsActive: []
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors !== this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.project !== null) {
      let project = nextProps.project;
      console.log(project);
      let isActiveSwitch = false;
      if (project.is_active === 1 || project.is_active === "1")
        isActiveSwitch = true;
      console.log(isActiveSwitch);
      this.setState({
        ID: project.id,
        Title: project.title,
        Description: project.description,
        Image: project.image,
        Logo: project.logo,
        IsActive: project.is_active,
        IsActiveSwitch: isActiveSwitch
      });
    } else {
      this.handleReset();
    }
  }

  handleReset = () => {
    this.setState({
      ID: null,
      Title: "",
      Description: "",
      Image: "",
      Logo: "",
      IsActive: 0,
      IsActiveSwitch: false,
      uploadedImage: null,
      uploadedLogo: null
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleTitleChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value === "") errors.Title = ["Please Enter Valid Value"];
    else errors.Title = "";
    this.setState({ Title: value, errors: errors });
  };

  handleDescriptionChange = value => {
    this.setState({ Description: value });
  };

  handleImageChange = event => {
    let file = event.target.files[0];
    let errors = this.state.errors;
    if (file) {
      if (isImage(file)) {
        errors.image = [];
        this.setState({
          Image: file,
          uploadedImage: file
        });
      } else {
        errors.image = ["Please Upload Valid Image"];
      }
    } else {
      errors.image = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };
  handleIsActiveChange = event => {
    this.setState({
      IsActive: event.target.checked,
      IsActiveSwitch: event.target.checked
    });
  };
  handleLogoChange = event => {
    let file = event.target.files[0];
    let errors = this.state.errors;
    if (file) {
      if (isImage(file)) {
        errors.logo = [];
        this.setState({
          Logo: file,
          uploadedLogo: file
        });
      } else {
        errors.logo = ["Please Upload Valid Logo"];
      }
    } else {
      errors.logo = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleSubmit = event => {
    let isActive = 0;
    if (this.state.IsActiveSwitch === true) isActive = 1;

    event.preventDefault();
    let formData = new FormData();
    formData.append("title", this.state.Title);
    formData.append("description", this.state.Description);
    if (this.state.uploadedImage) {
      formData.append("image", this.state.uploadedImage);
    }
    if (this.state.uploadedLogo) {
      formData.append("logo", this.state.uploadedLogo);
    }
    formData.append("is_active", isActive);
    if (this.state.ID) {
      formData.append("ID", this.state.ID);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {
      Title,
      CategoryID,
      Description,
      Image,
      Logo,
      IsActive
    } = this.state;
    if (
      Title === "" ||
      CategoryID === "" ||
      Description === "" ||
      Image === "" ||
      Logo === "" ||
      IsActive === ""
    ) {
      disabled = true;
    }

    let mainImage = "";
    if (this.state.uploadedImage)
      mainImage = URL.createObjectURL(this.state.uploadedImage);
    else if (this.state.Image) mainImage = BASE_URL + this.state.Image;
    else mainImage = mainImage = "temp.png";

    let mainLogo = "";
    if (this.state.uploadedLogo)
      mainLogo = URL.createObjectURL(this.state.uploadedLogo);
    else if (this.state.Logo) mainLogo = BASE_URL + this.state.Logo;
    else mainLogo = mainLogo = "temp.png";

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="projectForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Title"
                      value={this.state.Title}
                      onChange={event => this.handleTitleChange(event)}
                      error={
                        this.state.errors.Title &&
                        this.state.errors.Title.length > 0
                      }
                      helperText={
                        this.state.errors.Title && this.state.errors.Title[0]
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.IsActiveSwitch}
                          onChange={this.handleIsActiveChange}
                          value="IsActiveSwitch"
                          color="secondary"
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="image-input"
                      type="file"
                      onChange={event => this.handleImageChange(event)}
                    />
                    <label htmlFor="image-input">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Upload Image
                      </Button>
                    </label>
                    <img className={classes.preview} src={mainImage} alt="" />
                    {this.state.errors.image && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                      >
                        {this.state.errors.image[0]}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="logo-input"
                      type="file"
                      onChange={event => this.handleLogoChange(event)}
                    />
                    <label htmlFor="logo-input">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Upload Logo
                      </Button>
                    </label>
                    <img className={classes.preview} src={mainLogo} alt="" />
                    {this.state.errors.logo && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                      >
                        {this.state.errors.logo[0]}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <TinyMce
                  title="Description"
                  data={this.state.Description}
                  handleChange={this.handleDescriptionChange}
                  error={
                    this.state.errors.Description &&
                    this.state.errors.Description.length > 0
                  }
                  helperText={
                    this.state.errors.Description &&
                    this.state.errors.Description[0]
                  }
                />
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

ProjectsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(ProjectsFormDialog);
