// Local Configuration
// Local Configuration API_URL Esraa
// export const API_URL =
//   window.location.protocol + "//localhost/kijamiiwork/HydePark/api/public/";
// Local Configuration
// export const BASE_URL =
//   window.location.protocol + "//localhost/kijamiiwork/HydePark/api/";

// Local Configuration API_URL Mohamed Osama
// export const API_URL = window.location.protocol + "//hyde.test/";
// export const BASE_URL = window.location.protocol + "//hyde.test/";

// Local Configuration API_URL Khaled
// export const API_URL = window.location.protocol + "//localhost:9000/";
// export const BASE_URL = window.location.protocol + "//localhost:9000/";

// Live Configuration
export const API_URL = "https://api.hpd-app.com/";
export const BASE_URL = "https://api.hpd-app.com/";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("jwtToken"),
};

export const firebaseConfig = {
  apiKey: "AIzaSyDX24YVePZfiUD4Z0Sc7aSPxj1Vy0QRMzs",
  authDomain: "hyde-park-community-app.firebaseapp.com",
  databaseURL: "https://hyde-park-community-app.firebaseio.com",
  projectId: "hyde-park-community-app",
  storageBucket: "hyde-park-community-app.appspot.com",
  messagingSenderId: "143261014951",
  appId: "1:143261014951:web:6cf07ee69fe14d15",
};
