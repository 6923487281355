import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ExportIcon from "@material-ui/icons/CloudDownload";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Filters from "../../components/Filters";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";

function getSorting(order, orderBy) {
  if (orderBy === "id") {
    return order === "desc"
      ? (a, b) => (parseInt(b[orderBy]) < parseInt(a[orderBy]) ? -1 : 1)
      : (a, b) => (parseInt(a[orderBy]) < parseInt(b[orderBy]) ? -1 : 1);
  } else if (orderBy === "created_at" || orderBy === "updated_at") {
    return order === "desc"
      ? (a, b) => (new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1)
      : (a, b) => (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1);
  }
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columnData = [
  {
    id: "owner_full_name",
    numeric: false,
    disablePadding: false,
    label: "Owner Full Name",
    sortable: false
  },
  {
    id: "guest_name",
    numeric: false,
    disablePadding: false,
    label: "Guest Name",
    sortable: false
  },
  {
    id: "unit_number",
    numeric: false,
    disablePadding: false,
    label: "Unit Number",
    sortable: false
  },
  {
    id: "phase_name",
    numeric: false,
    disablePadding: false,
    label: "Phase Name",
    sortable: false
  },
  {
    id: "building_number",
    numeric: false,
    disablePadding: false,
    label: "Building Number",
    sortable: false
  },
  {
    id: "scanned",
    numeric: false,
    disablePadding: false,
    label: "Scanned",
    sortable: false
  },
  {
    id: "gate_number",
    numeric: false,
    disablePadding: false,
    label: "Gate Number ",
    sortable: false
  },
  {
    id: "project_name",
    numeric: false,
    disablePadding: false,
    label: "Project Name ",
    sortable: false
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created On",
    sortable: true
  },
  {
    id: "scanned_at",
    numeric: false,
    disablePadding: false,
    label: "Scanned At",
    sortable: true
  }
];

let filters = [
  {
    Field: "owner_full_name",
    Title: "Owner Full Name",
    Type: "TEXT",
    Values: "",
    Operator: "TEXT_EQUAL"
  },
  {
    Field: "gate_number",
    Title: "Gate Number",
    Type: "TEXT",
    Values: "",
    Operator: "TEXT_EQUAL"
  },
  {
    Field: "scanned",
    Title: "Scanned",
    Type: "SELECT",
    Values: "",
    Operator: "EQUALS",
    Options: [
      { title: "Scanned", value: 1 },
      { title: "Not Scanned", value: 0 }
    ]
  },
  {
    Field: "created_at",
    Label: "Created On",
    Type: "DATE_RANGE",
    Values: [],
    Operator: "DATE_BETWEEN"
  }
];

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.sortable ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <div> {column.label}</div>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  root2: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  root3: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      allData: [],
      selectedData: []
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.selectedData);
    this.setState({
      data: nextProps.data,
      allData: nextProps.data,
      selectedData: nextProps.selectedData
    });
  }
  render() {
    const { numSelected, classes } = this.props;
    return (
      <div>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subheading">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="title" id="tableTitle">
                Guest Invitations
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 ? (
              <ExcelFile
                element={
                  <Tooltip title="Export Selected">
                    <IconButton aria-label="Export Selected">
                      <ExportIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ExcelSheet
                  data={this.state.selectedData}
                  name="Guest Invitations"
                >
                  <ExcelColumn
                    label="Owner Full Name"
                    value="owner_full_name"
                  />
                  <ExcelColumn label="Guest Name" value="guest_name" />
                  <ExcelColumn label="Unit Number" value="unit_number" />
                  <ExcelColumn label="Phase Name" value="phase_name" />
                  <ExcelColumn
                    label="Building Number"
                    value="building_number"
                  />
                  <ExcelColumn
                    label="Scanned"
                    value={col => (col.scanned ? "Yes" : "No")}
                  />
                  <ExcelColumn
                    label="Gate Number"
                    value={col => (col.scanned ? col.gate_number : "N/A")}
                  />
                  <ExcelColumn label="Project Name" value="project_name" />
                  <ExcelColumn label="Created On" value="created_at" />
                  <ExcelColumn
                    label="Scanned At"
                    value={col => (col.scanned ? col.updated_at : "N/A")}
                  />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <ExcelFile
                element={
                  <Tooltip title="Export">
                    <IconButton aria-label="Export">
                      <ExportIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ExcelSheet data={this.state.data} name="Guest Invitations">
                  <ExcelColumn
                    label="Owner Full Name"
                    value="owner_full_name"
                  />
                  <ExcelColumn label="Guest Name" value="guest_name" />
                  <ExcelColumn label="Unit Number" value="unit_number" />
                  <ExcelColumn label="Phase Name" value="phase_name" />
                  <ExcelColumn
                    label="Building Number"
                    value="building_number"
                  />
                  <ExcelColumn
                    label="Scanned"
                    value={col => (col.scanned ? "Yes" : "No")}
                  />
                  <ExcelColumn
                    label="Gate Number"
                    value={col => (col.scanned ? col.gate_number : "N/A")}
                  />
                  <ExcelColumn label="Project Name" value="project_name" />
                  <ExcelColumn label="Created On" value="created_at" />
                  <ExcelColumn
                    label="Scanned At"
                    value={col => (col.scanned ? col.updated_at : "N/A")}
                  />
                </ExcelSheet>
              </ExcelFile>
            )}
          </div>
        </Toolbar>
        <div className={classes.root2}>
          <Filters
            allData={this.props.allData}
            filters={filters}
            handleDataChange={this.props.handleDataChange}
          />
        </div>
      </div>
    );
  }
}
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  tableWrapper: {
    overflowX: "auto"
  },
  resendButton: {
    marginLeft: theme.spacing.unit * 2
  }
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "created_at",
      selected: [],
      selectedData: [],
      sending: [],
      data: [],
      allData: [],
      page: 0,
      rowsPerPage: 5,
      open: false,
      deleteItems: [],
      confirmDialogOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data, allData: nextProps.data });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({
        selected: state.data.map(n => n.id),
        selectedData: state.data
      }));
      return;
    }
    this.setState({ selected: [], selectedData: [] });
  };

  handleClick = (event, guestInvitation) => {
    let id = guestInvitation.id;
    const { selected, selectedData } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newSelectedData = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedData = newSelectedData.concat(selectedData, guestInvitation);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected, selectedData: newSelectedData });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleCloseConfirmation = () => {
    this.setState({ confirmDialogOpen: false, deleteItems: [] });
  };

  handleDataChange = data => {
    this.setState({ data: data });
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            allData={this.state.allData}
            data={this.state.data}
            selectedData={this.state.selectedData}
            handleDataChange={this.handleDataChange}
            delete={() => this.handleDeleteButton(this.state.selected)}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={(event, property) =>
                  this.handleRequestSort(event, property)
                }
                rowCount={data.length}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onClick={event => this.handleClick(event, n)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.owner_full_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.guest_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.unit_number}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.phase_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.building_number}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.scanned == 1 ? (
                            <DoneIcon color="primary" />
                          ) : (
                            <CloseIcon color="error" />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.gate_number === null ? "N/A" : n.gate_number}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.project_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.created_at === null ? "N/A" : n.created_at}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.scanned == 0 ? "N/A" : n.updated_at}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(EnhancedTable));
