import React, { Component, Fragment } from "react";
import axios from "axios";
import SnackBar from "../../components/SnackBar";
import SubmissionsFormDialog from "./SubmissionsFormDialog";
import SubmissionsListing from "./SubmissionsListing";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers, BASE_URL } from "../../constants/Constants";
import { checkAuthorization } from "../../components/AuthService";

class Submissions extends Component {
  state = {
    submissions: [],
    currentSubmission: null,
    openSubmissionFormDialog: false,
    openDeleteConfirmationDialog: false,
    snackBarOpen: false,
    snackBarMessage: "",
    snackBarType: "success",
    isLoading: true,
    isRefreshing: false,
    dialogTitle: "View Submission",
  };

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    this.handleLoading(true);
    axios
      .get(API_URL + "ownersSubmissions", { headers: headers })
      .then((response) => {
        this.setState({ submissions: response.data.data }, () => {
          this.handleLoading(false);
        });
      })
      .catch(function(error) {
        this.handleLoading(false);
        console.log(error);
      });
  };

  handleCloseFeedbackFormDialog = () => {
    this.setState({ openSubmissionFormDialog: false });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  updateInState = (item) => {
    let newData = this.state.submissions;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == item.id) {
        newData[i] = item;
        break;
      }
    }
    this.setState({ submissions: newData, isRefreshing: false });
  };

  handleUpdate = (formData) => {
    this.setState({ isRefreshing: true });
    formData.append("_method", "PUT");
    axios
      .post(
        API_URL + "ownersSubmissions/" + this.state.currentSubmission.id,
        formData,
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 500) {
          this.setState({
            openSubmissionFormDialog: false,
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentSubmission: null,
          });
        } else if (
          response.status === 200 &&
          response.data.message === "form_error"
        ) {
          this.setState({
            isRefreshing: false,
            errors: response.data,
            openSubmissionFormDialog: true,
          });
        } else if (response.status === 200) {
          this.updateInState(response.data);
          this.setState(
            {
              openSubmissionFormDialog: false,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success",
              currentSubmission: null,
            },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleEdit = (currFeedback) => {
    if (!checkAuthorization("can_edit_owners_submissions")) {
      this.setState({
        snackBarMessage: "You don't have permission to view owners submission",
        snackBarType: "error",
        snackBarOpen: true,
      });
    } else {
      this.setState({
        dialogTitle: "Edit Submission",
        currentSubmission: currFeedback,
        openSubmissionFormDialog: true,
      });
    }
  };

  handleDelete = (currFeedback) => {
    if (!checkAuthorization("can_delete_owners_submissions")) {
      this.setState({
        snackBarMessage:
          "You don't have permission to delete owners submission",
        snackBarType: "error",
        snackBarOpen: true,
      });
    } else {
      this.setState({
        currentSubmission: currFeedback,
        openDeleteConfirmationDialog: true,
      });
    }
  };

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };

  handleConfirmDelete = () => {
    this.handleCloseDeleteConfirmationDialog();
    this.handleRefreshing(true);

    axios
      .delete(
        API_URL + "ownersSubmissions/" + this.state.currentSubmission.id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.status === 500) {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
          });
        } else {
          this.setState(
            {
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success",
            },
            () => {
              this.getAll();
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleLoading = (value) => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = (value) => {
    this.setState({ isRefreshing: value });
  };

  handleBatchDelete = (itemsIDs) => {
    let formData = new FormData();
    formData.append("records", JSON.stringify(itemsIDs));
    this.handleRefreshing(true);
    axios
      .post(API_URL + "ownersSubmissions/batchDelete", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          this.removeDeletedFromData(itemsIDs);
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
          });
        } else if (response.res === 0) {
          this.handleRefreshing(false);
          this.setState({
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  removeDeletedFromData = (items) => {
    let data = this.state.submissions;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find((item) => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ submissions: newData });
    this.handleRefreshing(false);
  };

  render() {
    return (
      <Fragment>
        <SubmissionsListing
          tableTitle="Ownership request"
          data={this.state.submissions}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          handleExport={this.handleExport}
          handleBatchDelete={this.handleBatchDelete}
        />
        <SubmissionsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseFeedbackFormDialog}
          isOpen={this.state.openSubmissionFormDialog}
          currentSubmission={this.state.currentSubmission}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
        />
        <DeleteConfirmationDialog
          title="Delete Submission"
          text="Are you sure that you want to permanently delete this submission?"
          isOpen={this.state.openDeleteConfirmationDialog}
          handleCloseDeleteConfirmationDialog={
            this.handleCloseDeleteConfirmationDialog
          }
          handleConfirmDelete={this.handleConfirmDelete}
        />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default Submissions;
