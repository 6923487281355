import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import WorkPermitsFormDialog from "./WorkPermitsFormDialog";
import WorkPermitsListing from "./WorkPermitsListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
class WorkPermits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permits: [],
      users: [],
      units: [],
      user_id: 0,
      currentPermits: null,
      selectedPermits: [],
      openWorkPermitsFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Permits",
      reset: false,
      errors: {
        user_id: [],
        user_project_id: [],
        user_unit_id: [],
        workers_count: [],
        is_expired: []
      }
    };
  }

  componentDidMount() {
    axios
      .all([this.getAll(), this.getUsers(), this.getUnits()])
      .then(responses => {
        this.setState({
          permits: responses[0].data.data,
          users: responses[1].data.data,
          units: responses[2].data.data,
          isLoading: false
        });
      });
  }

  getUsers = () => {
    return axios.get(API_URL + "users", { headers: headers });
  };

  getUnits = () => {
    return axios.get(API_URL + "units", { headers: headers });
  };

  getAll = () => {
    return axios.get(API_URL + "workPermits", { headers: headers });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_permits")) {
      this.setState({
        snackBarMessage: "You don't have permission to create permits",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Add Permits",
        openWorkPermitsFormDialog: true,
        reset: true
      });
    }
  };

  handleClosePermitsFormDialog = () => {
    this.setState({
      openWorkPermitsFormDialog: false,
      currentPermits: null,
      errors: {
        user_id: [],
        user_project_id: [],
        user_unit_id: [],
        workers_count: [],
        is_expired: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = request => {
    let newData = this.state.permits;
    newData.push(request);
    this.setState({ permits: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "workPermits", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openWorkPermitsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPermits: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openWorkPermitsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = request => {
    let newData = this.state.permits;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === request.id) {
        newData[i] = request;
        break;
      }
    }
    this.setState({ permits: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentPermits.id;
    axios
      .post(API_URL + "workPermits/" + id, formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openWorkPermitsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPermits: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openWorkPermitsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = request => {
    if (!checkAuthorization("can_edit_permits")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit permits",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Permits",
        currentPermits: request,
        openWorkPermitsFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = () => {
    this.handleRefreshing(true);
    let id = this.state.currentPermits.id;
    axios
      .delete(API_URL + "workPermits/" + id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openWorkPermitsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPermits: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openWorkPermitsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.permits;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ permits: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedWorkPermits = selectedPermits => {
    if (!checkAuthorization("can_delete_permits")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete permits",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedPermits: selectedPermits
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted.length < 1) {
      let formData = new FormData();
      formData.append("itemsids", JSON.stringify(toBeDeleted));
      axios
        .post(API_URL + "workPermits/batchDelete", formData, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openWorkPermitsFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentPermits: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openWorkPermitsFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    } else {
      axios
        .delete(API_URL + "workPermits/" + toBeDeleted, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openWorkPermitsFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentPermits: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openWorkPermitsFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <WorkPermitsListing
          tableTitle="WorkPermits"
          data={this.state.permits}
          users={this.state.users}
          units={this.state.units}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <WorkPermitsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleClosePermitsFormDialog}
          isOpen={this.state.openWorkPermitsFormDialog}
          request={this.state.currentPermits}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          permits={this.state.permits}
          users={this.state.users}
          units={this.state.units}
          errors={this.state.errors}
          reset={this.state.reset}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isRefreshing && <RefreshLoader />}
        {this.state.isLoading && <Loader />}
      </Fragment>
    );
  }
}

export default WorkPermits;
