import React, {Component, Fragment} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Filters from "../../components/Filters";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircle from "@material-ui/icons/CheckCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {checkAuthorization} from "../../components/AuthService";

const columnData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
    sortable: true
  },
  {
    id: "FullName",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
    sortable: true
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sortable: true
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: false
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
    sortable: false
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Created at",
    sortable: true
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: true,
    label: "Updated at",
    sortable: true
  },
  {
    id: "controls",
    numeric: false,
    disablePadding: true,
    label: "Controls",
    sortable: false
  }
];

let filters = [
  {
    Field: "FullName",
    Title: "Full Name",
    Type: "TEXT",
    Values: "",
    Operator: "TEXT_EQUAL"
  },
  {
    Field: "is_active",
    Title: "Active",
    Type: "SELECT",
    Values: "",
    Operator: "EQUALS",
    Options: [{ title: "Active", value: 1 }, { title: "Inactive", value: 0 }]
  },
  {
    Field: "is_super_admin",
    Title: "Super Admin",
    Type: "SELECT",
    Values: "",
    Operator: "EQUALS",
    Options: [{ title: "YES", value: 1 }, { title: "NO", value: 0 }]
  }
];

function getSorting(order, orderBy) {
  if (orderBy === "id") {
    return order === "desc"
      ? (a, b) => (parseInt(b[orderBy], 10) < parseInt(a[orderBy], 10) ? -1 : 1)
      : (a, b) =>
          parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10) ? -1 : 1;
  } else if (orderBy === "created_at" || orderBy === "updated_at") {
    return order === "desc"
      ? (a, b) => (new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1)
      : (a, b) => (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1);
  } else {
    return order === "desc"
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  }
}

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.sortable ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <div>{column.label}</div>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  root2: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  root3: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class EnhancedTableToolbar extends Component {
  state = {
    status: "All",
    start: "",
    end: "",
    open: false
  };

  render() {
    const { numSelected, classes, tableTitle } = this.props;
    return (
      <Fragment>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subheading">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="title" id="tableTitle">
                {tableTitle}
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  onClick={() => this.props.delete()}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Fragment />
            )}
          </div>
        </Toolbar>
        <div className={classes.root2}>
          <Filters
            allData={this.props.allData}
            filters={filters}
            handleDataChange={this.props.handleDataChange}
          />
        </div>
      </Fragment>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 640
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: props.orderBy,
      selected: [],
      data: [],
      allData: [],
      page: 0,
      rowsPerPage: 5,
      confirmDialogOpen: false,
      deleteItems: []
    };
  }

  /**
   *
   * @TODO: We need to enhance this by not using componentWillReceiveProps
   * since it will get deprecated soon in the next version of React
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data) {
      this.setState({ data: nextProps.data, allData: nextProps.data });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    this.props.deleteSlectedAdmins(JSON.stringify(this.state.selected));
  };

  handleDataChange = data => {
    this.setState({ data: data });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleCloseConfirmation = () => {
    this.setState({ confirmDialogOpen: false, deleteItems: [] });
  };

  deleteAdmins = () => {
    let toBeDeleted = this.state.deleteItems;
    this.handleCloseConfirmation();
    this.props.handleDelete(toBeDeleted);
    this.setState({ selected: [] });
  };

  handleDeleteButton = toBeDeleted => {
    if (!checkAuthorization("can_delete_admins")) {
      this.props.handleSnackBarMessage(
        "You don't have permission to delete admins",
        "error"
      );
    } else {
      this.handleOpenConfirmation(toBeDeleted);
    }
  };

  handleOpenConfirmation = toBeDeleted => {
    this.setState({ deleteItems: toBeDeleted, confirmDialogOpen: true });
  };
  render() {
    const { classes, tableTitle, handleEdit } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <DeleteConfirmationDialog
          isOpen={this.state.confirmDialogOpen}
          id={this.state.deleteItems}
          handleCloseDeleteConfirmationDialog={this.handleCloseConfirmation}
          title="Deleting Admins"
          text="Are you sure you want to perform this action?"
          handleConfirmDelete={this.deleteAdmins}
        />
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            allData={this.state.allData}
            handleDataChange={this.handleDataChange}
            numSelected={selected.length}
            tableTitle={tableTitle}
            handleStatus={status => this.handleChangeSentFilter(status)}
            handleDate={(start, end) => this.handleDate(start, end)}
            handleDeleteSelected={() => this.handleDeleteSelected()}
            delete={() => this.handleDeleteButton(this.state.selected)}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                columnData={columnData}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onClick={event => this.handleClick(event, n.id)}
                          />
                        </TableCell>
                        <TableCell padding="none">{n.id}</TableCell>
                        <TableCell padding="none">
                          {n.FullName + " "}
                          {parseInt(n.is_super_admin, 10) === 1 ? (
                            <Tooltip title="Super Admin">
                              <VerifiedUser
                                color="action"
                                style={{ marginBottom: "-8px" }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell padding="none">{n.email}</TableCell>
                        <TableCell padding="none">
                          {parseInt(n.is_active, 10) === 1 ? (
                            <Tooltip title="Active">
                              <CheckCircle color="action" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Inactive">
                              <RemoveCircle color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell padding="none">
                          {n.roles.length === 0 ? "N/A" : n.roles[0].name}
                        </TableCell>
                        <TableCell padding="none">{n.created_at}</TableCell>
                        <TableCell padding="none">
                          {n.updated_at == null ? "N/A" : n.updated_at}
                        </TableCell>
                        <TableCell padding="none">
                          <Tooltip title="Edit">
                            <IconButton
                              className={classes.button}
                              aria-label="Edit"
                              onClick={() => handleEdit(n)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              className={classes.button}
                              aria-label="Delete"
                              onClick={() => this.handleDeleteButton(n.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableTitle: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default withStyles(styles)(EnhancedTable);
