import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {withStyles, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Item from './Item';
import DeleteConfirmationDialog from '../../../components/DeleteConfirmationDialog';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FAB from "../../../components/FAB";
import AlbumItemsFormDialog from "./AlbumItemsFormDialog"
import RefreshLoader from "../../../components/RefreshLoader";
import SnackBar from "../../../components/SnackBar";
import axios from "axios";
import { API_URL, headers } from "../../../constants/Constants";
import { checkAuthorization } from "../../../components/AuthService";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3
    },
    container: {
        margin: "1%"
    }
});

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    spacer: {
        flex: "1 1 100%"
    },
    title: {
        flex: "0 0 auto"
    }
});

class EnhancedTableToolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 'desc',
			orderBy: props.orderBy,
			selected: [],
			data: [],
			allData: [],
			page: 0,
			rowsPerPage: 5,
			confirmDialogOpen: false,
			deleteItems: []
		};
	}
	render() {
		const { classes, tableTitle } = this.props;

		return (
			<Fragment>
				<Toolbar className={classes.root}>
					<div className={classes.title}>
						<Typography variant="title" id="tableTitle">
							{tableTitle}
						</Typography>
					</div>
					<div className={classes.spacer} />
				</Toolbar>
			</Fragment>
		);
	}
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    tableTitle: PropTypes.string.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function Transition(props) {
    return <Slide direction="up" {...props}/>;
}

class AlbumItemsListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 'desc',
			orderBy: props.orderBy,
			selected: [],
			albumID : props.albumID,
			data: [],
			allData: [],
			page: 0,
			rowsPerPage: 5,
			confirmDialogOpen: false,
			deleteItems: [],
			isOpen: props.isOpen,
			openAlbumItemsFormDialog: false,
			isRefreshing: false,
			reset:true,
			snackBarOpen:false,
			errors: {
				asset:[]
			}
		};
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};
	componentWillReceiveProps(nextProps) {
		if (nextProps.data !== this.state.data) {
			this.setState({ data: nextProps.data, allData: nextProps.data, albumID:nextProps.albumID });
		}
	}
	handleDeleteSelected = () => {
		this.props.deleteSelectedAlbums(JSON.stringify(this.state.selected));
	};
	isSelected = id => this.state.selected.indexOf(id) !== -1;

	handleCloseConfirmation = () => {
		this.setState({ confirmDialogOpen: false, deleteItems: [] });
	};

	deleteItem = () => {
		let toBeDeleted = this.state.deleteItems;
		this.handleCloseConfirmation();
		this.props.handleDelete(toBeDeleted);
		this.setState({ selected: [] });
	};

	handleDeleteButton = toBeDeleted => {
		if (!checkAuthorization('can_delete_albums')) {
			this.props.handleSnackBarMessage("You don't have permission to delete albums", 'error');
		} else {
		this.handleOpenConfirmation(toBeDeleted);
		}
	};

	handleOpenConfirmation = toBeDeleted => {
		this.setState({ deleteItems: toBeDeleted, confirmDialogOpen: true });
	};

	handleClose = () => {
		this.setState(
			{
				isOpen: false
			},
			() => {
				this.props.handleClose();
			}
		);
	};

	handleCloseAlbumItemsFormDialog = () => {
		this.setState({
			openAlbumItemsFormDialog: false,
			errors: {
				asset:[]
			}
		});
	};

	fabCallback = () => {
			this.setState({
				openAlbumItemsFormDialog: true
			});
	};

	addToState = item => {
		let newData = this.state.data;
		newData.push(item);
		this.setState({ data: newData, isRefreshing: false });
	};

	handleAdd = formData => {
		this.setState({ isRefreshing: true, reset: false });
		axios
			.post(API_URL + 'gallery', formData, {
				headers: headers
			})
			.then(response => {
				console.log(response);
				if (response.status == 201) {
						this.setState({
							openAlbumItemsFormDialog: false,
							reset: true,
							snackBarMessage: response.data.message,
							snackBarOpen: true,
							snackBarType: 'success',
							isRefreshing: false
						});
						this.addToState(response.data.data);
					}
			})
			.catch(error =>{
				if (error.response.status == 401) {
					
					this.setState({
						reset: false,
						isRefreshing: false,
						openAlbumItemsFormDialog: true,
						errors: error.response.data.message
					});
				} else {
					this.setState({
						openAlbumItemsFormDialog: false,
						reset: true,
						snackBarMessage: error.response.statusText,
						snackBarOpen: true,
						snackBarType: 'error',
						isRefreshing: false
					});
				}			
			});
	};

	handleCloseSnackbar = () => {
		this.setState({ snackBarOpen: false });
	};

	
	render() {
		console.log(this.state.isOpen);
		const { classes, data, isOpen} = this.props;
		const { order, orderBy, selected, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
		return (
			<div>
			<Dialog fullScreen open={isOpen} onClose={this.handleClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
							<CloseIcon />
						</IconButton>
						<Typography variant="title" color="inherit" className={classes.flex}>
							{this.props.dialogTitle}
						</Typography>
					</Toolbar>
				</AppBar>
				<div className={classes.root}>
				<div>
				<DeleteConfirmationDialog
					isOpen={this.state.confirmDialogOpen}
					id={this.state.deleteItems}
					handleCloseDeleteConfirmationDialog={this.handleCloseConfirmation}
					title="Deleting Item"
					text="Are you sure you want to perform this action?"
					handleConfirmDelete={this.deleteItem}
				/>
				<FAB callback={this.fabCallback} />
				<SnackBar
					type={this.state.snackBarType}
					open={this.state.snackBarOpen}
					autoHideDuration={4000}
					message={this.state.snackBarMessage}
					onClose={this.handleCloseSnackbar}
				/>{' '}
				{this.state.isRefreshing && <RefreshLoader />}
				<AlbumItemsFormDialog
					dialogTitle={'Add New Item'}
					isOpen={this.state.openAlbumItemsFormDialog}
					albumID={this.state.albumID}
					handleAdd={this.handleAdd}
					handleClose={this.handleCloseAlbumItemsFormDialog}
					reset={this.state.reset}
					errors={this.state.errors}
				/>

				<Paper className={classes.root}>
					<EnhancedTableToolbar
						tableTitle="Album Items"
					/>
					<div className={classes.container}>
						<Grid container spacing={8}>
							{data && data.length > 0 ? (
								data
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((item, index) => (
										<Item
											key={index}
											item={item}
											delete={() => this.handleDeleteButton(item.id)}
										/>
									))
							) : (
								<Fragment />
							)}
						</Grid>
					</div>
					<TablePagination
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{ 'aria-label': 'Previous Page' }}
						nextIconButtonProps={{ 'aria-label': 'Next Page' }}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
				</div>
			</Dialog>
		</div>
		);
	}
}

AlbumItemsListing.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired
};

export default withStyles(styles)(AlbumItemsListing);
