import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { API_URL, headers } from "../../constants/Constants";
import axios from "axios";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },
  uploadInput: {
    display: "none"
  },
  imageError: {
    marginTop: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class WorkPermitsFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      permits: [],
      user: [],
      unit: [],
      id: null,
      user_id: 0,
      user_project_id: 0,
      user_unit_id: 0,
      workers_count: "",
      is_expired: 0,
      errors: {
        user_id: [],
        user_project_id: [],
        user_unit_id: [],
        workers_count: [],
        is_expired: []
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.permits != this.state.permits)
      this.setState({ permits: nextProps.permits });

    if (nextProps.request != null) {
      let request = nextProps.request;
      this.setState({
        id: request.id,
        user: request.user,
        unit: request.unit,
        user_id: request.user_id,
        user_project_id: request.user_project_id,
        user_unit_id: request.user_unit_id,
        workers_count: request.workers_count,
        is_expired: request.is_expired
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      permits: [],
      user: [],
      unit: [],
      user_id: 0,
      user_project_id: 0,
      user_unit_id: 0,
      workers_count: "",
      is_expired: 0
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleUserChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.user_id = ["Please Enter Valid Value"];
    else errors.user_id = "";
    this.setState({ user_id: value });
  };

  handleUnitChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.user_unit_id = ["Please Enter Valid Value"];
    else errors.user_unit_id = "";
    this.setState({ user_unit_id: value });
    this.handleProjectChange();
  };

  handleProjectChange = () => {
    let unit = this.props.units[this.state.user_unit_id];
    this.setState({ user_project_id: unit.project_id });
  };

  handleIsExpiredChange = event => {
    this.state.is_expired == 0
      ? this.setState({ is_expired: 1 })
      : this.setState({ is_expired: 0 });
  };

  handleWorkersCountChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.workers_count = ["Please Enter Valid Value"];
    else errors.workers_count = "";
    this.setState({ workers_count: value });
  };
  handleSubmit = event => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("user_id", this.state.user_id);
    formData.append("user_project_id", this.state.user_project_id);
    formData.append("user_unit_id", this.state.user_unit_id);
    formData.append("is_expired", this.state.is_expired);
    formData.append("workers_count", this.state.workers_count);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {
      user_id,
      user_project_id,
      user_unit_id,
      is_expired,
      workers_count
    } = this.state;
    if (
      user_id === "" ||
      user_project_id === "" ||
      user_unit_id === "" ||
      is_expired === "" ||
      workers_count === ""
    ) {
      disabled = true;
    }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="requestForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={4}>
                    <InputLabel htmlFor="user-simple">User</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.user_id}
                      onChange={this.handleUserChange}
                      error={
                        this.state.errors.user_id &&
                        this.state.errors.user_id.length > 0
                      }
                      helperText={this.state.errors.user_id}
                      inputProps={{
                        name: "user_id",
                        id: "user-simple"
                      }}
                    >
                      {this.props.users.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel htmlFor="unit-simple">Units</InputLabel>
                    <Select
                      fullWidth={true}
                      value={this.state.user_unit_id}
                      onChange={this.handleUnitChange}
                      error={
                        this.state.errors.user_unit_id &&
                        this.state.errors.user_unit_id.length > 0
                      }
                      helperText={this.state.errors.user_unit_id}
                      inputProps={{
                        name: "user_unit_id",
                        id: "unit-simple"
                      }}
                    >
                      {this.props.units.map(unit => {
                        if (unit.user_id == this.state.user_id)
                          return (
                            <MenuItem key={unit.id} value={unit.id}>
                              {unit.unit_code}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth={true}
                      label="Workers Count"
                      type="number"
                      value={this.state.workers_count}
                      onChange={event => this.handleWorkersCountChange(event)}
                      error={
                        this.state.errors.workers_count &&
                        this.state.errors.workers_count.length > 0
                      }
                      helperText={
                        this.state.errors.workers_count &&
                        this.state.errors.workers_count[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="IsExpired">Is Expired</label>
                    <Checkbox
                      id="IsExpired"
                      checked={this.state.is_expired}
                      onClick={event => this.handleIsExpiredChange(event)}
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

WorkPermitsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(WorkPermitsFormDialog);
