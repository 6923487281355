import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  fullLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "100%"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ServicesFeedbackFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    user_id: "",
    service_request_id: "",
    rating: "",
    comment: "",
    status: "",
    reason_id: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    let { currFeedback } = nextProps;

    this.setState({
      id: currFeedback !== null ? currFeedback.id : null,
      user_id:
        currFeedback !== null
          ? currFeedback.users !== null
            ? currFeedback.users.full_name
            : ""
          : "",
      service_request_id:
        currFeedback !== null ? currFeedback.service_request_id : "",
      rating: currFeedback !== null ? currFeedback.rating : "",
      comment: currFeedback !== null ? currFeedback.comment : "",
      status: currFeedback !== null ? currFeedback.status : "",
      reason_id:
        currFeedback !== null
          ? currFeedback.feedbackreasons !== null
            ? currFeedback.feedbackreasons.reason
            : ""
          : ""
    });
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.handleClose();
    });
  };

  handleStatusChange = event => {
    let value = event.target.value;
    this.setState({ status: value });
  };

  handleSubmit = event => {
    event.preventDefault();

    let formData = new FormData(document.getElementById("feedbackForm"));
    formData.append("status", this.state.status);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleClose();
      this.props.handleUpdateFeedback(formData);
    }
  };

  render() {
    const { classes } = this.props;
    console.log("id", this.state.id);
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Services Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>Feedback</DialogContentText>
            <form id="feedbackForm" action="/" onSubmit={this.handleSubmit}>
              <TextField
                id="user_id"
                name="user_id"
                label="User Name"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.user_id}
              />
              <TextField
                id="service_request_id"
                name="service_request_id"
                label="Service Request"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.service_request_id}
              />
              <TextField
                id="rating"
                name="rating"
                label="Rating"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.rating}
              />
              <TextField
                id="reason_id"
                name="reason_id"
                label="Reason"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.reason_id}
              />
              <TextField
                id="comment"
                name="comment"
                label="Comment"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.comment}
              />

              <FormControl classes="fullLength">
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleStatusChange}
                  inputProps={{ name: "status", id: "status" }}
                >
                  <MenuItem value={1}>Pending</MenuItem>
                  <MenuItem value={2}>In Progress</MenuItem>
                  <MenuItem value={3}>Closed</MenuItem>
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ServicesFeedbackFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  currFeedback: PropTypes.object
};

export default withStyles(styles)(ServicesFeedbackFormDialog);
