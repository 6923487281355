import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { BASE_URL } from "../../constants/Constants";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class UsersFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    full_name: "",
    phone_number: "",
    birth_date: "",
    email: "",
    gender: "",
    platform: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.user) {
      let user = nextProps.user;
      this.setState({
        id: user.id,
        full_name: user.full_name,
        phone_number: user.phone_number,
        email: user.email,
        birth_date: user.birth_date,
        gender: user.gender,
        platform: user.platform,
        profile_image: user.profile_image
      });
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.handleClose();
    });
  };

  render() {
    const { classes } = this.props;
    const {
      full_name,
      birth_date,
      phone_number,
      email,
      gender,
      platform,
      profile_image
    } = this.state;
    let title = "User";
    if (full_name) {
      title = full_name;
    }
    let genderText = "";
    if (gender) {
      if (gender == 1) genderText = "Male";
      else if (gender == 2) genderText = "Female";
    }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <form id="usersForm" action="/">
              <Grid container spacing={24} className={classes.container}>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="full_name"
                    name="full_name"
                    label="Full Name"
                    placeholder="Full Name"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    disabled
                    value={full_name}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    label="Phone Number"
                    placeholder="phone_number"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    disabled
                    value={phone_number}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="email"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    disabled
                    value={email}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="birth_date"
                    name="birth_date"
                    label="Birthday"
                    placeholder="yyyy-MM-dd"
                    className={classes.textField}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={birth_date}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="gender"
                    name="gender"
                    label="Gender"
                    placeholder="yyyy-MM-dd"
                    className={classes.textField}
                    margin="normal"
                    value={genderText}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="platform"
                    name="platform"
                    label="platform"
                    placeholder="yyyy-MM-dd"
                    className={classes.textField}
                    margin="normal"
                    value={platform}
                    disabled
                    fullWidth
                  />
                </Grid>
              </Grid>
              {profile_image && (
                <Grid item lg={6} md={6} xs={6}>
                  <img
                    src={BASE_URL + profile_image}
                    alt="profile"
                    style={{
                      borderRadius: "50%",
                      width: "350px",
                      height: "350px"
                    }}
                  />
                </Grid>
              )}
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

UsersFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  user: PropTypes.object
};

export default withStyles(styles)(UsersFormDialog);
