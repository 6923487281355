import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { BASE_URL } from "../../constants/Constants.js";
import { isicon } from "../../components/FileValidation";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  uploadInput: {
    display: "none"
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class PhoneItemsFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    name: "",
    phone_number: "",
    phone_categories_id: 0,
    icon: "",
    uploadedicon: null,
    phoneCategories: [],
    errors: {
      name: [],
      phone_number: [],
      phone_categories_id: [],
      icon: []
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.phoneCategories != this.state.phoneCategories)
      this.setState({ phoneCategories: nextProps.phoneCategories });

    let { phoneItem } = nextProps;
    if (phoneItem) {
      console.log(nextProps);
      this.setState({
        id: phoneItem.id,
        name: phoneItem.name,
        phone_number: phoneItem.phone_number,
        phone_categories_id: phoneItem.phone_categories_id,
        icon: phoneItem.icon
      });
    }
  }

  handleReset = () => {
    // console.log('handling');
    this.setState({
      id: null,
      name: "",
      phone_number: "",
      phone_categories_id: 0,
      icon: "",
      uploadedicon: null
    });
  };

  // handleClose = () => {
  //   console.log('closed');
  //   this.handleReset();
  //   this.setState(
  //     {
  //       isOpen: false
  //     },
  //     () => {
  //       this.props.handleClose();
  //     }
  //   );
  // };

  handleClose = () => {
    this.props.handleClose();
  };

  handlenameChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.name = ["Please Enter Valid Value"];
    else errors.name = "";
    this.setState({ name: value });
  };

  handlephone_numberChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.phone_number = ["Please Enter Valid Value"];
    else errors.phone_number = "";
    this.setState({ phone_number: value });
  };

  handleCategoryChange = event => {
    let value = event.target.value;
    this.setState({ phone_categories_id: value });
  };

  handleiconChange = event => {
    let errors = this.state.errors;
    let file = event.target.files[0];
    if (file) {
      if (isicon(file)) {
        errors.icon = [];
        this.setState({
          icon: file,
          uploadedicon: file
        });
      } else {
        errors.icon = ["Please Upload Valid icon"];
      }
    } else {
      errors.icon = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("phone_number", this.state.phone_number);
    formData.append("phone_categories_id", this.state.phone_categories_id);

    if (this.state.uploadedicon)
      formData.append("icon", this.state.uploadedicon);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const { name, phone_number, phone_categories_id, icon } = this.state;
    if (
      name === "" ||
      phone_number === "" ||
      phone_categories_id === "" ||
      icon === ""
    ) {
      disabled = true;
    }

    let mainicon = "";
    if (this.state.uploadedicon)
      mainicon = URL.createObjectURL(this.state.uploadedicon);
    else if (this.state.icon) mainicon = BASE_URL + this.state.icon;
    else mainicon = mainicon = "temp.png";

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                disabled={disabled}
                onClick={this.handleSubmit}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <form
              id="servicesItemsForm"
              action="/"
              onSubmit={this.handleSubmit}
            >
              <Grid container spacing={24} className={classes.container}>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Phone Directory Name"
                    placeholder="Phone Directory Name"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    onChange={this.handlenameChange}
                    value={this.state.name}
                    error={
                      this.state.errors.name &&
                      this.state.errors.name.length > 0
                    }
                    helperText={this.state.errors.name}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    label="Phone Number"
                    placeholder="Phone Number"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    onChange={this.handlephone_numberChange}
                    value={this.state.phone_number}
                    error={
                      this.state.errors.phone_number &&
                      this.state.errors.phone_number.length > 0
                    }
                    helperText={this.state.errors.phone_number}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      select
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      fullWidth={true}
                      label="Category"
                      value={this.state.phone_categories_id}
                      onChange={event => this.handleCategoryChange(event)}
                      error={
                        this.state.errors.phone_categories_id &&
                        this.state.errors.phone_categories_id.length > 0
                      }
                      helperText={
                        this.state.errors.phone_categories_id &&
                        this.state.errors.phone_categories_id[0]
                      }
                    >
                      <MenuItem value="">Please Select Category</MenuItem>
                      {this.props.phoneCategories.map(category => {
                        return (
                          <MenuItem
                            key={category.id}
                            value={category.id}
                            selected={
                              category.id == this.state.phone_categories_id
                            }
                          >
                            {category.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} xs={12} />
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={6}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={event => this.handleiconChange(event)}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Upload icon
                      </Button>
                    </label>
                    <img className={classes.preview} src={mainicon} />
                    {this.props.errors.icon && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                        className={classes.imageError}
                      >
                        {this.props.errors.icon[0]}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

PhoneItemsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
  // service: PropTypes.object
};

export default withStyles(styles)(PhoneItemsFormDialog);
