import React, { Component, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Button from "@material-ui/core/Button";
import Users from "../../pages/users/Users";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import People from "@material-ui/icons/People";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";

const styles = theme => ({
  root: {
    width: "99.5%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
    borderRadius: 20,
    border: 5,
    padding: "8px"
  },
  container: { padding: "8px" },
  paper: {
    width: "90%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
    borderRadius: 30,
    border: 5
  },
  table: {
    minWidth: 500
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  title: {
    marginTop: theme.spacing.unit * 3
  },
  button: {
    margin: theme.spacing.unit * 2,
    overflowX: "auto",
    align: "right",
    borderRadius: 15,
    border: 2,
    "&:hover": {
      color: "#fff"
    }
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  tableHead: {
    padding: " 12px",
    align: "left",
    backgroundColor: "#3D4144"
  },
  tableTitle: {
    color: "white",
    border: "1px solid #ddd",
    padding: "8px",
    fontSize: 13
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },

  cardTitle: {
    color: "white",
    marginTop: theme.spacing.unit * 2
  },
  charts: { margin: theme.spacing.unit * 2, padding: "8px" },
  dangergradient: {
    margin: theme.spacing.unit * 2,
    minWidth: 300,
    minHeight: 160,
    borderRadius: 30,
    border: 5,
    /* fallback/image non-cover color */
    backgroundColor: "#a77ffc",
    /* Firefox 3.6+ */
    backgroundImage: "-moz-linear-gradient(left, #a77ffc 0%, #ff6eac 100%)",
    /* Safari 4+, Chrome 1+ */
    backgroundImage:
      "-webkit-gradient(linear, left top, right top, color-stop(0%, #a77ffc), color-stop(100%, #ff6eac))",
    /* Safari 5.1+, Chrome 10+ */
    backgroundImage: "-webkit-linear-gradient(left, #a77ffc 0%, #ff6eac 100%)",
    /* Opera 11.10+ */
    backgroundImage: " -o-linear-gradient(left, #a77ffc 0%, #ff6eac 100%)",
    /* IE10+ */
    background: "-ms-linear-gradient(left, #a77ffc 0%, #ff6eac 100%)",
    /* Standard */
    background: " linear-gradient(to right, #a77ffc 0%, #ff6eac 100%)"
  },
  successgradient: {
    margin: theme.spacing.unit * 2,
    minWidth: 300,
    minHeight: 150,
    borderRadius: 30,
    border: 5,
    /* fallback/image non-cover color */
    backgroundColor: "#11d7e4",
    /* Firefox 3.6+ */
    backgroundImage: "-moz-linear-gradient(left, #11d7e4 0%, #48eaa1 100%)",
    /* Safari 4+, Chrome 1+ */
    backgroundImage:
      "-webkit-gradient(linear, left top, right top, color-stop(0%, #11d7e4), color-stop(100%, #48eaa1))",
    /* Safari 5.1+, Chrome 10+ */
    backgroundImage: "-webkit-linear-gradient(left, #11d7e4 0%, #48eaa1 100%)",
    /* Opera 11.10+ */
    backgroundImage: "-o-linear-gradient(left, #11d7e4 0%, #48eaa1 100%)",
    /* IE10+ */
    background: "-ms-linear-gradient(left, #11d7e4 0%, #48eaa1 100%)",
    /* Standard */
    background: "linear-gradient(to right, #11d7e4 0%, #48eaa1 100%)"
  },
  infogradient: {
    margin: theme.spacing.unit * 2,
    minWidth: 300,
    minHeight: 150,
    borderRadius: 30,
    border: 5,
    /* fallback/image non-cover color */
    backgroundColor: "#7f9bff",
    /* Firefox 3.6+ */
    backgroundImage: "-moz-linear-gradient(left, #7f9bff 0%, #2ddeff 100%)",
    /* Safari 4+, Chrome 1+ */
    backgroundImage:
      "-webkit-gradient(linear, left top, right top, color-stop(0%, #7f9bff), color-stop(100%, #2ddeff))",
    /* Safari 5.1+, Chrome 10+ */
    backgroundImage: "-webkit-linear-gradient(left, #7f9bff 0%, #2ddeff 100%)",
    /* Opera 11.10+ */
    backgroundImage: "-o-linear-gradient(left, #7f9bff 0%, #2ddeff 100%)",
    /* IE10+ */
    background: "-ms-linear-gradient(left, #7f9bff 0%, #2ddeff 100%)",
    /* Standard */
    background: "linear-gradient(to right, #7f9bff 0%, #2ddeff 100%)"
  },
  warninggradient: {
    margin: theme.spacing.unit * 2,
    minWidth: 300,
    minHeight: 150,
    borderRadius: 30,
    border: 5,
    /* fallback/image non-cover color */
    backgroundColor: "#fe8c81",
    /* Firefox 3.6+ */
    backgroundImage: "-moz-linear-gradient(left, #fe8c81 0%, #fbd323 100%)",
    /* Safari 4+, Chrome 1+ */
    backgroundImage:
      "-webkit-gradient(linear, left top, right top, color-stop(0%, #fe8c81), color-stop(100%, #fbd323))",
    /* Safari 5.1+, Chrome 10+ */
    backgroundImage: "-webkit-linear-gradient(left, #fe8c81 0%, #fbd323 100%)",
    /* Opera 11.10+ */
    backgroundImage: "-o-linear-gradient(left, #fe8c81 0%, #fbd323 100%)",
    /* IE10+ */
    background: "-ms-linear-gradient(left, #fe8c81 0%, #fbd323 100%)",
    /* Standard */
    background: "linear-gradient(to right, #fe8c81 0%, #fbd323 100%)"
  },
  image: {
    marginLeft: "auto",
    display: "block"
  }
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CustomersCount: 0,
      currentServicesCount: 0,
      unReadfeedbackCount: 0,
      unReadServicesCount: 0,
      recentGeneralFeedback: [],
      recentServicesFeedback: [],
      customersDemographics: {},
      customersPlatforms: {},
      highestServicesRequested: [],
      lowestServicesRequested: [],
      highestRatedServices: [],
      lowestRatedServices: [],
      servicesRequested: [],
      getServiceRequest: [],
      page: 0,
      rowsPerPage: 5
    };
  }

  /**
   *
   * @TODO: We need to enhance this by not using componentWillReceiveProps
   * since it will get deprecated soon in the next version of React
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.CustomersCount !== this.state.CustomersCount) {
      this.setState({ CustomersCount: nextProps.CustomersCount });
    }
    if (nextProps.currentServicesCount !== this.state.currentServicesCount) {
      this.setState({ currentServicesCount: nextProps.currentServicesCount });
    }
    if (nextProps.unReadfeedbackCount !== this.state.unReadfeedbackCount) {
      this.setState({ unReadfeedbackCount: nextProps.unReadfeedbackCount });
    }
    if (nextProps.unReadServicesCount !== this.state.unReadServicesCount) {
      this.setState({ unReadServicesCount: nextProps.unReadServicesCount });
    }
    if (nextProps.customersDemographics !== this.state.customersDemographics) {
      this.setState({ customersDemographics: nextProps.customersDemographics });
    }

    if (nextProps.customersPlatforms !== this.state.customersPlatforms) {
      this.setState({ customersPlatforms: nextProps.customersPlatforms });
    }
    if (
      nextProps.highestServicesRequested !== this.state.highestServicesRequested
    ) {
      this.setState({
        highestServicesRequested: nextProps.highestServicesRequested
      });
    }
    if (
      nextProps.lowestServicesRequested !== this.state.lowestServicesRequested
    ) {
      this.setState({
        lowestServicesRequested: nextProps.lowestServicesRequested
      });
    }

    if (nextProps.highestRatedServices !== this.state.highestRatedServices) {
      this.setState({
        highestRatedServices: nextProps.highestRatedServices
      });
    }
    if (nextProps.lowestRatedServices !== this.state.lowestRatedServices) {
      this.setState({
        lowestRatedServices: nextProps.lowestRatedServices
      });
    }
    if (nextProps.servicesRequested !== this.state.servicesRequested) {
      this.setState({
        servicesRequested: nextProps.servicesRequested
      });
    }

    if (nextProps.recentGeneralFeedback !== this.state.recentGeneralFeedback) {
      this.setState({
        recentGeneralFeedback: nextProps.recentGeneralFeedback
      });
    }
    if (
      nextProps.recentServicesFeedback !== this.state.recentServicesFeedback
    ) {
      this.setState({
        recentServicesFeedback: nextProps.recentServicesFeedback
      });
    }

    if (nextProps.LowestRatedCleaners !== this.state.LowestRatedCleaners) {
      this.setState({
        LowestRatedCleaners: nextProps.LowestRatedCleaners
      });
    }

    if (nextProps.getServiceRequest !== this.state.getServiceRequest) {
      this.setState({
        getServiceRequest: nextProps.getServiceRequest
      });
    }

    if (nextProps.usersTypes !== this.state.usersTypes) {
      this.setState({
        usersTypes: nextProps.usersTypes
      });
    }
  }
  render() {
    const { classes } = this.props;
    const {
      CustomersCount,
      currentServicesCount,
      unReadfeedbackCount,
      unReadServicesCount,
      recentGeneralFeedback,
      recentServicesFeedback,
      getServiceRequest,
      highestServicesRequested,
      lowestServicesRequested,
      highestRatedServices,
      lowestRatedServices,
      servicesRequested,
      page,
      rowsPerPage
    } = this.state;

    return (
      <div>
        <Grid container spacing={5} className={classes.charts}>
          <Grid item xs={3}>
            <Paper className={classes.paper} elevation={0}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Customers Demographics
              </Typography>
              <Pie data={this.state.customersDemographics} />
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper} elevation={0}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Customers Platforms
              </Typography>
              <Pie data={this.state.customersPlatforms} />
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper} elevation={0}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Services Requested
              </Typography>
              <Doughnut data={servicesRequested} />
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper} elevation={0}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Customers Types
              </Typography>
              <Pie data={this.state.usersTypes} />
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={12} className={classes.container}>
          <Grid item xs={3}>
            <Card className={classes.dangergradient} width="3px">
              <CardContent>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  className={classes.cardTitle}
                  align="left"
                >
                  Total Customers
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  align="left"
                  className={classes.cardTitle}
                >
                  {CustomersCount}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="baseline"
                >
                  <Typography
                    component="h4"
                    className={classes.cardTitle}
                    align="left"
                  >
                    Last Update: few minutes ago
                  </Typography>
                  <img
                    className={classes.image}
                    src={"/TotalCustomers.png"}
                    // right="100%"
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.successgradient} width="3px">
              <CardContent>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  className={classes.cardTitle}
                  align="left"
                >
                  Current Services Count
                </Typography>

                <Typography
                  variant="h3"
                  component="h2"
                  align="left"
                  className={classes.cardTitle}
                >
                  {currentServicesCount}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="baseline"
                >
                  <Typography
                    component="h4"
                    className={classes.cardTitle}
                    align="left"
                  >
                    Last Update: few minutes ago
                  </Typography>
                  <img
                    className={classes.image}
                    src={"/CurrentServicesCount.png"}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.infogradient} width="3px">
              <CardContent>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  className={classes.cardTitle}
                  align="left"
                >
                  Pending Feedback Messages
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  align="left"
                  className={classes.cardTitle}
                >
                  {unReadfeedbackCount}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="baseline"
                >
                  <Typography
                    component="h4"
                    className={classes.cardTitle}
                    align="left"
                  >
                    Last Update: few minutes ago
                  </Typography>
                  <img
                    className={classes.image}
                    src={"/PendingFeedbackMassages.png"}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.warninggradient} width="3px">
              <CardContent>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  className={classes.cardTitle}
                  align="left"
                >
                  Pending Service Rates
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  align="left"
                  className={classes.cardTitle}
                >
                  {unReadServicesCount}
                </Typography>

                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="baseline"
                >
                  <Typography
                    component="h4"
                    className={classes.cardTitle}
                    align="left"
                  >
                    Last Update: few minutes ago
                  </Typography>
                  <img
                    className={classes.image}
                    src={"/PendingFeedbackRates.png"}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={5} className={classes.container}>
          <Paper className={classes.root}>
            <Typography
              variant="h5"
              component="h4"
              className={classes.title}
              color="textSecondary"
              gutterBottom
              align="center"
            >
              Services Request
            </Typography>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="center" className={classes.tableTitle}>
                      Services
                    </TableCell>
                    <TableCell align="center" className={classes.tableTitle}>
                      Full Name
                    </TableCell>
                    <TableCell align="center" className={classes.tableTitle}>
                      Date
                    </TableCell>

                    <TableCell align="center" className={classes.tableTitle}>
                      Unit
                    </TableCell>
                    <TableCell align="center" className={classes.tableTitle}>
                      Customer Comment
                    </TableCell>
                    <TableCell align="center" className={classes.tableTitle}>
                      Admin Comment
                    </TableCell>
                    <TableCell align="center" className={classes.tableTitle}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getServiceRequest
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      return (
                        <TableRow className={classes.row}>
                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {n.item !== null ? n.item.title : "N/A"}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {n.user !== null
                              ? n.user.first_name + " " + n.user.last_name
                              : "N/A"}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {n.date}
                          </TableCell>

                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {n.unit && n.unit.unit_code}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {n.user_comment == ""
                              ? "N/A"
                              : n.user_comment == null
                              ? "N/A"
                              : n.user_comment}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {n.admin_notes !== null ? n.admin_notes : "N/A"}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            className={classes.tableCell}
                          >
                            {parseInt(n.state, 10) === 1
                              ? "Pending"
                              : "Fulfilled"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/servicesRequests"}
              >
                View All Services Requests
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid container spacing={24} className={classes.container}>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Highest Services Requested
              </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableTitle}>
                        Service Item
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {highestServicesRequested
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(n => {
                        return (
                          <TableRow className={classes.row}>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.name !== null ? n.name : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.count}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/servicesRequests"}
              >
                View All Services Requests
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Lowest Services Requested
              </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableTitle}>
                        Service Item
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lowestServicesRequested
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(n => {
                        return (
                          <TableRow className={classes.row}>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.name !== null ? n.name : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.count}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/servicesRequests"}
              >
                View All Services Requests
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={24} className={classes.container}>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Highest Services Requested
              </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableTitle}>
                        Service Item
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Average
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {highestRatedServices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(n => {
                        return (
                          <TableRow className={classes.row}>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.title !== null ? n.title : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.avg}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.count}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/servicesfeedback"}
              >
                View All Services Feedback
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Lowest Services Rated
              </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableTitle}>
                        Service Item
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Average
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lowestRatedServices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(n => {
                        return (
                          <TableRow className={classes.row}>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.title !== null ? n.title : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.avg}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.count}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/servicesfeedback"}
              >
                View All Services Feedback
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={24} className={classes.container}>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                General Feedback
              </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableTitle}>
                        Full Name
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Feedback Type
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Comment
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentGeneralFeedback
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(n => {
                        return (
                          <TableRow className={classes.row}>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.users !== null ? n.users.full_name : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.feedback_type == 1
                                ? "Photo"
                                : n.feedback_type == 2
                                ? "Text"
                                : "Others"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.comment !== null ? n.comment : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {parseInt(n.status, 10) === 1
                                ? "Pending"
                                : parseInt(n.status, 10) === 2
                                ? "In Progress"
                                : "Closed"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/generalfeedback"}
              >
                View All General Feedback
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h4"
                className={classes.title}
                color="textSecondary"
                gutterBottom
                align="center"
              >
                Services Feedback
              </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableTitle}>
                        Full Name
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Service Request ID
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Rating
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Reason
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Comment
                      </TableCell>
                      <TableCell align="center" className={classes.tableTitle}>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentServicesFeedback
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(n => {
                        return (
                          <TableRow className={classes.row}>
                            <TableCell
                              align="center"
                              padding="none"
                              className={classes.tableCell}
                            >
                              {n.users !== null
                                ? n.users.first_name + " " + n.users.last_name
                                : "N/A"}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.service_request_id}
                            </TableCell>
                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.rating}
                            </TableCell>

                            <TableCell
                              padding="none"
                              align="center"
                              className={classes.tableCell}
                            >
                              {n.feedbackreasons != null
                                ? n.feedbackreasons.reason
                                : "N/A"}
                            </TableCell>
                            <TableCell
                              align="center"
                              padding="none"
                              className={classes.tableCell}
                            >
                              {n.comment == ""
                                ? "N/A"
                                : n.comment == null
                                ? "N/A"
                                : n.comment}
                            </TableCell>
                            <TableCell
                              align="center"
                              padding="none"
                              className={classes.tableCell}
                            >
                              {parseInt(n.status, 10) === 1
                                ? "Pending"
                                : parseInt(n.status, 10) === 2
                                ? "In Progress"
                                : "Closed"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <Typography
                component="h4"
                className={classes.title}
                gutterBottom
                align="center"
              >
                Last Update: few minutes ago
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                href={"/servicesfeedback"}
              >
                View All Services Feedback
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(EnhancedTable);
