import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import Security from "@material-ui/icons/Security";
import Widgets from "@material-ui/icons/Widgets";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Smartphone from "@material-ui/icons/Smartphone";
import Tooltip from "@material-ui/core/Tooltip";
import People from "@material-ui/icons/People";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import Dashboard from "@material-ui/icons/Dashboard";
import Feedback from "@material-ui/icons/Feedback";
import Projects from "@material-ui/icons/Layers";
import Category from "@material-ui/icons/Category";
import Poll from "@material-ui/icons/Poll";
import { checkAuthorization } from "./AuthService";
import Event from "@material-ui/icons/Event";
import Build from "@material-ui/icons/Build";
import AccessTime from "@material-ui/icons/AccessTime";
import ImportContacts from "@material-ui/icons/ImportContacts";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";

const activeClassName = {
  backgroundColor: "#92a8d1",
};

export const navigationItems = (
  <Fragment>
    <NavLink
      to="/dashboard"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Dashboard" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Tooltip>
    </NavLink>

    {/* <NavLink
      to="/todoCategories"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Todo Categories" placement="right">
        <ListItem button>
          <ListItemIcon>
            <List />
          </ListItemIcon>
          <ListItemText primary="Todo Categories" />
        </ListItem>
      </Tooltip>
    </NavLink> */}
    <NavLink
      to="/services"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Services" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Build />
          </ListItemIcon>
          <ListItemText primary="Services" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink
      to="/servicesRequests"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Clients Services Requests" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Build />
          </ListItemIcon>
          <ListItemText primary="Clients Services Requests" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink
      to="/timeRanges"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Time Ranges" placement="right">
        <ListItem button>
          <ListItemIcon>
            <AccessTime />
          </ListItemIcon>
          <ListItemText primary="Time Ranges" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink
      to="/news"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="News" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Widgets />
          </ListItemIcon>
          <ListItemText primary="News" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink
      to="/phoneCategories"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Phone Directories" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Category />
          </ListItemIcon>
          <ListItemText primary="Phone Directories" />
        </ListItem>
      </Tooltip>
    </NavLink>

    {/* <NavLink
      to="/polls"
      style={{ textDecoration: "none" }}
      activeClassName={activeClassName.backgroundColor}
    >
      <Tooltip title="Polls" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Poll />
          </ListItemIcon>
          <ListItemText primary="Polls" />
        </ListItem>
      </Tooltip>
    </NavLink> */}

    <NavLink to="/projects" style={{ textDecoration: "none" }}>
      <Tooltip title="Projects" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Projects />
          </ListItemIcon>
          <ListItemText primary="Projects" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/guidelines" style={{ textDecoration: "none" }}>
      <Tooltip title="Guidelines" placement="right">
        <ListItem button>
          <ListItemIcon>
            <ImportContacts />
          </ListItemIcon>
          <ListItemText primary="Guidelines" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/events" style={{ textDecoration: "none" }}>
      <Tooltip title="Events" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Event />
          </ListItemIcon>
          <ListItemText primary="Events" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/albums" style={{ textDecoration: "none" }}>
      <Tooltip title="Albums" placement="right">
        <ListItem button>
          <ListItemIcon>
            <PhotoLibrary />
          </ListItemIcon>
          <ListItemText primary="Albums" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <Divider />
    <NavLink to="/admins" style={{ textDecoration: "none" }}>
      <Tooltip title="Administrators" placement="right">
        <ListItem button>
          <ListItemIcon>
            <SupervisedUserCircle />
          </ListItemIcon>
          <ListItemText primary="Administrators" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/roles" style={{ textDecoration: "none" }}>
      <Tooltip title="Roles" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Security />
          </ListItemIcon>
          <ListItemText primary="Roles" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/constructionupdates" style={{ textDecoration: "none" }}>
      <Tooltip title="Construction Updates" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Smartphone />
          </ListItemIcon>
          <ListItemText primary="Construction Updates" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/users" style={{ textDecoration: "none" }}>
      <Tooltip title="Users" placement="right">
        <ListItem button>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Tooltip>
    </NavLink>
    {/* <NavLink to="/settings" style={{ textDecoration: "none" }}>
      <Tooltip title="Settings" placement="right">
        <ListItem button>
          <ListItemIcon>
            <SettingsSharp />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </Tooltip>
    </NavLink> */}
    {/* <NavLink to="/appcontent" style={{ textDecoration: "none" }}>
      <Tooltip title="App Content" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Smartphone />
          </ListItemIcon>
          <ListItemText primary="App Content" />
        </ListItem>
      </Tooltip>
    </NavLink> */}
    {checkAuthorization("can_view_notifications") && (
      <NavLink to="/notifications" style={{ textDecoration: "none" }}>
        <Tooltip title="Notifications" placement="right">
          <ListItem button>
            <ListItemIcon>
              <NotificationsActive />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
        </Tooltip>
      </NavLink>
    )}
    <NavLink to="/generalfeedback" style={{ textDecoration: "none" }}>
      <Tooltip title="General Feedback" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Feedback />
          </ListItemIcon>
          <ListItemText primary="General Feedback" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/submissions" style={{ textDecoration: "none" }}>
      <Tooltip title="Ownership request" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Feedback />
          </ListItemIcon>
          <ListItemText primary="Ownership request" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/feedbackreasons" style={{ textDecoration: "none" }}>
      <Tooltip title="Feedback Reasons" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Feedback />
          </ListItemIcon>
          <ListItemText primary="Feedback Reasons" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <NavLink to="/servicesfeedback" style={{ textDecoration: "none" }}>
      <Tooltip title="Services Feedback" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Feedback />
          </ListItemIcon>
          <ListItemText primary="Services Feedback" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <Divider />
    {/* <NavLink to="/privilegescategories" style={{ textDecoration: "none" }}>
      <Tooltip title="Privileges Categories" placement="right">
        <ListItem button>
          <ListItemIcon>
            <Feedback />
          </ListItemIcon>
          <ListItemText primary="Privileges Categories" />
        </ListItem>
      </Tooltip>
    </NavLink>
    <Divider /> */}
    <NavLink to="/logout" style={{ textDecoration: "none" }}>
      <Tooltip title="Logout" placement="right">
        <ListItem button>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Tooltip>
    </NavLink>
  </Fragment>
);
