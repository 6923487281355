import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BASE_URL } from "../../constants/Constants";
import Fab from "@material-ui/core/Fab";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  fab: {
    margin: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SubmissionFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    user_id: null,
    phone_number: "",
    full_name: "",
    unit_number: "",
    status: 0
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    let { currentSubmission } = nextProps;
    this.setState({
      id: currentSubmission !== null ? currentSubmission.id : null,
      full_name: currentSubmission !== null ? currentSubmission.full_name : "",
      phone_number:
        currentSubmission !== null ? currentSubmission.phone_number : "",
      unit_number:
        currentSubmission !== null ? currentSubmission.unit_number : "",
      status: currentSubmission !== null ? currentSubmission.status : ""
    });
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.handleClose();
    });
  };

  handleStatusChange = event => {
    let value = event.target.value;
    this.setState({ status: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { id, status } = this.state;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);
    this.props.handleClose();
    this.props.handleUpdate(formData);
  };

  handleClickOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { classes, currentSubmission, dialogTitle } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>Submissions</DialogContentText>
            {/* <form id="feedbackForm"> */}
            <TextField
              id="full_name"
              name="full_name"
              label="Name"
              className={classes.textField}
              margin="normal"
              fullWidth
              disabled
              value={this.state.full_name}
            />
            <TextField
              id="phone_number"
              name="phone_number"
              label="Phone Number"
              className={classes.textField}
              margin="normal"
              fullWidth
              disabled
              value={this.state.phone_number}
            />
            <TextField
              id="unit_number"
              name="unit_number"
              label="Feedback Type"
              className={classes.textField}
              margin="normal"
              fullWidth
              disabled
              value={this.state.unit_number}
            />
            <FormControl className={classes.halfLength}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={this.state.status}
                onChange={this.handleStatusChange}
              >
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>Closed</MenuItem>
              </Select>
            </FormControl>
            {/* </form> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SubmissionFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  currFeedback: PropTypes.object
};

export default withStyles(styles)(SubmissionFormDialog);
