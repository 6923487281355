import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { API_URL, BASE_URL } from "../constants/Constants";

const styles = theme => ({
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  }
});

const TINYMCE_CONFIG = {
  plugins: "link image code paste media",
  toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
  images_upload_url: API_URL + "tinymce/uploadImage",
  images_upload_base_path: BASE_URL,
  paste_retain_style_properties: "none",
  extended_valid_elements: "span"
};

class TinyMce extends React.Component {
  handleEditorChange = e => {
    this.setState({ content: e.target.getContent() });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.fullLength}>
        <Grid item xs={12}>
          <Typography variant="title" gutterBottom>
            {this.props.title}
          </Typography>
          <Editor
            initialValue={this.props.data}
            init={TINYMCE_CONFIG}
            onChange={this.props.handleChange}
          />
          {this.props.error && (
            <Typography variant="subheading" gutterBottom color="error">
              {this.props.helperText}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TinyMce);
