import React, { Component, Fragment } from "react";
import axios from "axios";
import PhoneItemsListing from "./PhoneItemsListing";
import PhoneItemsFormDialog from "./PhoneItemsFormDialog";
import Loader from "./../../components/Loader";
import FAB from "../../components/FAB";
import RefreshLoader from "./../../components/RefreshLoader";
import SnackBar from "./../../components/SnackBar";
import { API_URL, headers } from "./../../constants/Constants";
import { checkAuthorization } from "../../components/AuthService";
import { withRouter } from "react-router-dom";

class PhoneItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneCategories: [],
      phoneItems: [],
      currentPhoneItem: null,
      openPhoneItemsFormDialog: false,
      reset: false,
      isLoading: true,
      isRefreshing: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      errors: {
        name: [],
        phone_number: [],
        icon: []
      }
    };
  }

  componentDidMount() {
    this.getAll();
    this.getAllServicesCategories();
  }

  getAllServicesCategories = () => {
    axios
      .get(API_URL + "PhoneCategory/all", { headers: headers })
      .then(response => {
        let phoneCategories = response.data;
        this.setState({
          phoneCategories: phoneCategories,
          isLoading: false
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getAll = () => {
    axios
      .get(API_URL + "PhoneItems/all", { headers: headers })
      .then(response => {
        let phoneItems = response.data;
        console.log(phoneItems);
        this.setState({
          phoneItems: phoneItems
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_phone")) {
      this.setState({
        snackBarMessage: "You don't have permission to create phone directory",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Create New Phone Directory",
        // currentServiceItem: null,
        openPhoneItemsFormDialog: true,
        reset: true
      });
    }
  };

  handleClosePhoneFormDialog = () => {
    this.setState({
      openPhoneItemsFormDialog: false,
      currentPhoneItem: null,
      // dialogTitle: '',
      // reset: true,
      errors: {
        name: [],
        phone_number: [],
        icon: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = phone_item => {
    let newData = this.state.phoneItems;
    newData.push(phone_item);
    this.setState({ phoneItems: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "PhoneItems/create", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openPhoneItemsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPhoneItem: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          console.log(errors);
          this.setState({ errors: errors, openPhoneItemsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = phoneItem => {
    let newData = this.state.phoneItems;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == phoneItem.id) {
        newData[i] = phoneItem;
        break;
      }
    }
    this.setState({ phoneItems: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentPhoneItem.id;
    axios
      .post(API_URL + "PhoneItems", formData, {
        headers: headers
      })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openPhoneItemsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPhoneItem: null,
            reset: true
          });
        }
      })
      .catch(err => {
        console.log(err);

        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openPhoneItemsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = phoneItem => {
    if (!checkAuthorization("can_edit_phone")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit phone directories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Phone Directory",
        currentPhoneItem: phoneItem,
        openPhoneItemsFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted instanceof Array) {
      let id;
      for (id of toBeDeleted) {
        axios
          .delete(API_URL + "PhoneItems/" + id, {
            headers: headers
          })
          .then(response => {
            return response.data;
          })
          .then(response => {
            if (response.status === true) {
              this.setState({
                snackBarOpen: true,
                snackBarType: "success",
                snackBarMessage: response.message
              });
              this.removeDeletedFromData(toBeDeleted);
            } else if (response.status === false) {
              this.setState({
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: response.message
              });
            }
          });
      }
    } else {
      axios
        .delete(API_URL + "PhoneItems/" + toBeDeleted, { headers: headers })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "success",
              snackBarMessage: response.data.message
            });
            this.removeDeletedFromData(toBeDeleted);
          } else if (response.status === 500) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "error",
              snackBarMessage: response.data.message
            });
          }
        });
    }
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.phoneItems;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ phoneItems: newData });
    this.handleRefreshing(false);
  };

  deleteSelectedItems = selectedItems => {
    if (!checkAuthorization("can_delete_phone")) {
      this.setState({
        snackBarMessage:
          "You don't have permission to delete phone directories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedItems: selectedItems
      });
    }
  };

  handleSnackBarMessage = (msg, type) => {
    this.setState({
      snackBarMessage: msg,
      snackBarType: type,
      snackBarOpen: true
    });
  };

  render() {
    return (
      <Fragment>
        <PhoneItemsListing
          tableTitle="Phone Directories"
          data={this.state.phoneItems}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <PhoneItemsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleClosePhoneFormDialog}
          isOpen={this.state.openPhoneItemsFormDialog}
          phoneItem={this.state.currentPhoneItem}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          phoneCategories={this.state.phoneCategories}
          errors={this.state.errors}
          reset={this.state.reset}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />{" "}
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}
export default withRouter(PhoneItems);
