import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TinyMce from "../../components/TinyMce";
import { DateFormatInput } from "material-ui-next-pickers";
import { isImage } from "../../components/FileValidation";
import { BASE_URL } from "../../constants/Constants";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)",
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px",
  },
  uploadInput: {
    display: "none",
  },
  imageError: {
    marginTop: theme.spacing.unit * 3,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class NewsFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      news: [],
      id: null,
      title: "",
      publish_date: new Date(),
      image: "",
      description: "",
      is_active: "",
      is_main: "",
      in_home: "",
      have_main: null,
      uploadedImage: null,
      album_id: null,
      albums: [],
      errors: {
        title: [],
        publish_date: [],
        description: [],
        image: [],
        is_active: [],
        is_main: [],
        in_home: [],
        album_id: [],
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.news != this.state.news)
      this.setState({ news: nextProps.news });

    if (nextProps.have_main != this.state.have_main)
      this.setState({ have_main: nextProps.have_main });

    if (nextProps.item != null) {
      let item = nextProps.item;
      this.setState({
        id: item.id,
        title: item.title,
        publish_date: new Date(item.publish_date),
        description: item.description,
        image: item.image,
        in_home: parseInt(item.in_home),
        is_main: parseInt(item.is_main),
        is_active: parseInt(item.is_active),
        have_main: this.props.have_main,
        album_id: item.album_id,
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      title: "",
      description: "",
      publish_date: new Date(),
      image: "",
      uploadedImage: "",
      is_active: "",
      is_main: "",
      in_home: "",
      album_id: null,
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleDateChange = (date) => {
    let value = date;
    let errors = this.state.errors;
    if (value == "") errors.publish_date = ["Please Enter Valid Value"];
    else errors.publish_date = "";
    this.setState({ publish_date: value });
  };

  handleTitleChange = (event) => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.title = ["Please Enter Valid Value"];
    else errors.title = "";
    this.setState({ title: value });
  };

  handleDescriptionChange = (value) => {
    let errors = this.state.errors;
    if (value === "") errors.description = ["Please Enter Valid Value"];
    else errors.description = "";
    this.setState({ description: value.target.getContent() });
  };

  handleIsActiveChange = (event) => {
    this.state.is_active == 0
      ? this.setState({ is_active: 1 })
      : this.setState({ is_active: 0 });
  };

  handleIsMainChange = (event) => {
    this.state.is_main == 0
      ? this.setState({ is_main: 1 })
      : this.setState({ is_main: 0 });
  };

  handleInHomeChange = (event) => {
    this.state.in_home == 0
      ? this.setState({ in_home: 1 })
      : this.setState({ in_home: 0 });
  };

  handleImageChange = (event) => {
    let file = event.target.files[0];
    let errors = this.state.errors;
    if (file) {
      if (isImage(file)) {
        errors.image = [];
        this.setState({
          image: file,
          uploadedImage: file,
        });
      } else {
        errors.icon = ["Please Upload Valid image"];
      }
    } else {
      errors.icon = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleAlbumChange = (event) => {
    this.setState({ album_id: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("is_main", this.state.is_main);
    formData.append("in_home", this.state.in_home);
    formData.append("is_active", this.state.is_active);
    formData.append("description", this.state.description);
    if (this.state.album_id) formData.append("album_id", this.state.album_id);
    let date = new Date(this.state.publish_date);
    let formatedDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    formData.append("publish_date", formatedDate);
    if (this.state.uploadedImage) {
      formData.append("image", this.state.uploadedImage);
    }
    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {
      title,
      image,
      publish_date,
      description,
      is_active,
      is_main,
      in_home,
      album_id,
    } = this.state;
    console.log({
      title,
      image,
      publish_date,
      description,
      is_active,
      is_main,
      in_home,
      album_id,
    });
    if (
      title === "" ||
      image === "" ||
      publish_date === "" ||
      description === ""
    ) {
      disabled = true;
    }

    let mainImage = "";
    if (this.state.uploadedImage)
      mainImage = URL.createObjectURL(this.state.uploadedImage);
    else if (this.state.image) mainImage = BASE_URL + this.state.image;
    else mainImage = mainImage = "temp.png";

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={(event) => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="itemForm"
              onSubmit={(event) => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Title"
                      value={this.state.title}
                      onChange={(event) => this.handleTitleChange(event)}
                      error={
                        this.state.errors.title &&
                        this.state.errors.title.length > 0
                      }
                      helperText={
                        this.state.errors.title && this.props.errors.title[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <label htmlFor="IsActive">Is Active</label>
                    <Checkbox
                      id="IsActive"
                      checked={this.state.is_active}
                      onClick={(event) => this.handleIsActiveChange(event)}
                    />
                  </Grid>
                  {!this.state.have_main || this.state.is_main ? (
                    <Grid item xs={3}>
                      <label htmlFor="IsMain">Is Main</label>
                      <Checkbox
                        id="IsMain"
                        checked={this.state.is_main}
                        onClick={(event) => this.handleIsMainChange(event)}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={3}>
                    <label htmlFor="InHome">In Home</label>
                    <Checkbox
                      id="InHome"
                      checked={this.state.in_home}
                      onClick={(event) => this.handleInHomeChange(event)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateFormatInput
                      name="date-input"
                      label="publish Date"
                      value={this.state.publish_date}
                      onChange={this.handleDateChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="icon-input"
                      type="file"
                      onChange={(event) => this.handleImageChange(event)}
                    />
                    <label htmlFor="icon-input">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Upload Image
                      </Button>
                    </label>
                    <img className={classes.preview} src={mainImage} alt="" />
                    {this.state.errors.image && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                      >
                        {this.state.errors.image[0]}
                      </Typography>
                    )}
                  </Grid>
                  {/* <Grid item xs={6}>
                    <InputLabel>Album</InputLabel>
                    <Select
                      value={album_id}
                      onChange={this.handleAlbumChange}
                      error={
                        this.state.errors.album_id &&
                        this.state.errors.album_id.length > 0
                      }
                      helperText={
                        this.state.errors.album_id &&
                        this.state.errors.album_id[0]
                      }
                    >
                      <MenuItem value={0}>No Albums</MenuItem>
                      {this.props.albums &&
                        this.props.albums.length > 0 &&
                        this.props.albums.map((album, index) => (
                          <MenuItem key={"album_" + index} value={album.id}>
                            {album.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid> */}
                  <TinyMce
                    title="Description"
                    data={this.state.description}
                    handleChange={this.handleDescriptionChange}
                    error={
                      this.state.errors.description &&
                      this.state.errors.description.length > 0
                    }
                    helperText={
                      this.state.errors.description &&
                      this.state.errors.description[0]
                    }
                  />
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

NewsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(NewsFormDialog);
