import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { isEmail } from "../../components/FileValidation";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AdminsFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    is_active: true,
    is_super_admin: false,
    role_name: "",
    errors: {
      first_name: [],
      last_name: [],
      email: [],
      password: [],
      confirm_password: []
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }

    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors !== this.state.errors)
      this.setState({ errors: nextProps.errors });

    let { admin } = nextProps;

    if (admin) {
      this.setState({
        id: admin.id,
        first_name: admin.first_name,
        last_name: admin.last_name,
        email: admin.email,
        role_name: admin.roles[0].name,
        is_active: admin.is_active,
        is_super_admin: admin.is_super_admin
      });
    }
  }

  handleInputChange = (id, event, index, payload) => {
    const isSelectField = payload !== undefined;

    if (isSelectField) {
      this.setState({
        [id]: payload
      });
    } else {
      const target = id.target;
      const value =
        target.type === "checkbox" ? Number(target.checked) : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }
  };

  handlefirst_nameChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value === "") errors.first_name = ["Please Enter Valid Value"];
    else errors.first_name = "";
    this.setState({ first_name: value });
  };

  handlelast_nameChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value === "") errors.last_name = ["Please Enter Valid Value"];
    else errors.last_name = "";
    this.setState({ last_name: value });
  };

  handleEmailChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.email = ["Please Enter Valid Value"];
    else if (!isEmail(value)) errors.email = ["Please Enter Valid email"];
    else errors.email = "";
    this.setState({ email: value });
  };

  handleRoleChange = event => {
    let value = event.target.value;
    this.setState({ role_name: value });
  };

  handlePasswordChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (!this.state.id) {
      if (value === "")
        errors.password = ["password is required for admin creation"];
      else errors.password = [];
    }
    this.setState({ password: value, errors: errors });
  };

  handleConfirmPasswordChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value !== this.state.password) {
      errors.confirm_password = ["Password confirmation doesn't match"];
    } else {
      errors.confirm_password = [];
    }
    this.setState({ confirm_password: value, errors: errors });
  };

  handleReset = () => {
    this.setState({
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      is_active: true,
      is_super_admin: false,
      role_name: "",
      errors: {
        first_name: [],
        last_name: [],
        email: [],
        password: []
      }
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData(document.getElementById("adminsForm"));
    formData.set("is_active", formData.has("is_active") ? "1" : "0");
    formData.set("is_super_admin", formData.has("is_super_admin") ? "1" : "0");
    formData.set("role_name", this.state.role_name);
    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdateAdmin(formData);
    } else {
      this.props.handleSubmitAdmin(formData);
    }
    this.props.handleClose();
  };

  render() {
    const { classes, roles } = this.props;

    let disabled = false;
    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      id
    } = this.state;
    if (first_name === "" || last_name === "" || email === "") {
      disabled = true;
    }

    if (!id) {
      if (password === "" || confirm_password === "") {
        disabled = true;
      }
    }

    if (password !== confirm_password) {
      disabled = true;
    }

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                disabled={disabled}
                onClick={this.handleSubmit}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <form id="adminsForm" action="/" onSubmit={this.handleSubmit}>
              <Grid container spacing={24} className={classes.container}>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    placeholder="First Name"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    onChange={event => this.handlefirst_nameChange(event)}
                    value={this.state.first_name}
                    error={
                      this.state.errors.first_name &&
                      this.state.errors.first_name.length > 0
                    }
                    helperText={
                      this.state.errors.first_name &&
                      this.state.errors.first_name[0]
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    onChange={event => this.handlelast_nameChange(event)}
                    value={this.state.last_name}
                    error={
                      this.state.errors.last_name &&
                      this.state.errors.last_name.length > 0
                    }
                    helperText={
                      this.state.errors.last_name &&
                      this.state.errors.last_name[0]
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    type="email"
                    onChange={event => this.handleEmailChange(event)}
                    value={this.state.email}
                    error={
                      this.state.errors.email &&
                      this.state.errors.email.length > 0
                    }
                    helperText={
                      this.state.errors.email && this.state.errors.email[0]
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel htmlFor="roleID">Role</InputLabel>
                    <Select
                      value={this.state.role_name}
                      onChange={event => this.handleRoleChange(event)}
                      inputProps={{ name: "role_name", id: "roleID" }}
                      name="role_name"
                    >
                      {this.props != null &&
                        roles.map((role, index) => (
                          <MenuItem key={index} value={role.name}>
                            {role.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    type="password"
                    onChange={event => this.handlePasswordChange(event)}
                    error={
                      this.state.errors.password &&
                      this.state.errors.password.length > 0
                    }
                    helperText={
                      this.state.errors.password &&
                      this.state.errors.password[0]
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    id="confirmpassword"
                    name="password_confirmation"
                    label="Confirm password"
                    placeholder="Confirm password"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    type="password"
                    onChange={event => this.handleConfirmPasswordChange(event)}
                    error={
                      this.state.errors.confirm_password &&
                      this.state.errors.confirm_password.length > 0
                    }
                    helperText={
                      this.state.errors.confirm_password &&
                      this.state.errors.confirm_password[0]
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_super_admin"
                        onChange={this.handleInputChange}
                        checked={this.state.is_super_admin === 1 ? true : false}
                      />
                    }
                    label="Super Admin"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_active"
                        onChange={this.handleInputChange}
                        checked={this.state.is_active === 1 ? true : false}
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

AdminsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  admin: PropTypes.object
};

export default withStyles(styles)(AdminsFormDialog);
