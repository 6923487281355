import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import NewsFormDialog from "./NewsFormDialog";
import NewsListing from "./NewsListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
import { withRouter } from "react-router-dom";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      albums: [],
      have_main: null,
      currentNews: null,
      selectedNews: [],
      openNewsFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New News",
      reset: false,
      errors: {
        title: [],
        description: [],
        publish_date: [],
        image: [],
        is_active: [],
        is_main: [],
        in_home: [],
        albums: []
      }
    };
  }

  componentDidMount() {
    this.getAll();
    this.getAlbums();
  }

  getAll = () => {
    axios
      .get(API_URL + "news", {
        headers: headers
      })
      .then(response => {
        return response.data;
      })
      .then(response => {
        let news = response.data;
        let have_main = response.have_main;
        if (news)
          this.setState({ news: news, have_main: have_main, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getAlbums = () => {
    axios
      .get(API_URL + "albums", {
        headers: headers
      })
      .then(response => {
        return response.data;
      })
      .then(response => {
        console.log(response.data);
        let albums = response.data;
        this.setState({
          albums: albums
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_news")) {
      this.setState({
        snackBarMessage: "You don't have permission to create news",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Add News",
        openNewsFormDialog: true,
        reset: true
      });
    }
  };

  handleCloseNewsFormDialog = () => {
    this.setState({
      openNewsFormDialog: false,
      currentNews: null,
      errors: {
        title: [],
        description: [],
        publish_date: [],
        image: [],
        is_active: [],
        is_main: [],
        in_home: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = item => {
    let newData = this.state.news;
    newData.push(item);
    this.setState({ news: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "news", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openNewsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            have_main: response.data.have_main
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentNews: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openNewsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = item => {
    let newData = this.state.news;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === item.id) {
        newData[i] = item;
        break;
      }
    }
    this.setState({ news: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentNews.id;
    axios
      .post(API_URL + "news/" + id, formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openNewsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            have_main: response.data.have_main
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentNews: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openNewsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = item => {
    if (!checkAuthorization("can_edit_news")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit news",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit News",
        currentNews: item,
        openNewsFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = () => {
    this.handleRefreshing(true);
    let id = this.state.currentNews.id;
    axios
      .delete(API_URL + "news/" + id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openNewsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentNews: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openNewsFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = news => {
    let data = this.state.news;
    let newData = [];
    data.forEach((element, index) => {
      if (news instanceof Array) {
        let found = news.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (news === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ news: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedNews = selectedNews => {
    if (!checkAuthorization("can_delete_news")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete news",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedNews: selectedNews
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted.length < 1) {
      let formData = new FormData();
      formData.append("newsids", JSON.stringify(toBeDeleted));
      axios
        .post(API_URL + "news/batchDelete", formData, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openNewsFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentNews: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openNewsFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    } else {
      axios
        .delete(API_URL + "news/" + toBeDeleted, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openNewsFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentNews: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openNewsFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <NewsListing
          tableTitle="News"
          data={this.state.news}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <NewsFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseNewsFormDialog}
          isOpen={this.state.openNewsFormDialog}
          item={this.state.currentNews}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          news={this.state.news}
          have_main={this.state.have_main}
          errors={this.state.errors}
          reset={this.state.reset}
          albums={this.state.albums}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default withRouter(News);
