import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import StepsResult from "./StepsResult";
import Snackbar from "@material-ui/core/Snackbar";
import moment from "moment";
const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  btnRight: {
    float: "right",
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  stepsContent: {
    position: "relative",
    marginLeft: "20%",
    marginRight: "22%",
  },
  stepperControl: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});

function getSteps() {
  return ["Message Data", "Filter Users", "Schedule", "Confirm"];
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class NotificationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      activeStep: 0,
      type: "",
      segement_device: 0,
      segement_gender: 0,
      minAge: 13,
      maxAge: 99,
      message: "",
      deepLink: "",
      sendDateTime: "",
      disableButton: true,
      ageChecked: false,
      stepsNumber: 3,
      contentID: "",
      openSnackBar: false,
      snackbarMessage: "",
      date: "",
      time: "",
      scheduleType: false,
      user_id: null,
      filtrationType: 0,
      title: "",
      user_type: [1, 2, 3],
      module_id: null,
    };
  }

  handleNext = () => {
    const { activeStep } = this.state;
    const { stepsNumber } = this.state;
    if (activeStep === stepsNumber) {
      this.createNotification();
      this.setState({ activeStep: 0 });
    } else {
      this.setState({
        activeStep: activeStep + 1,
      });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep == 2) {
      this.enableNextStepButton();
    }
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      message: "",
      ageChecked: false,
      type: "",
      segement_device: 0,
      segement_gender: 0,
      minAge: 13,
      maxAge: 99,
      disableButton: true,
      contentID: "",
      tableContent: [],
      deepLink: "",
      sendDateTime: "",
      showContentSelect: false,
      date: "",
      time: "",
      user_type: 0,
      scheduleType: false,
    });
  };

  disableNextStepButton = () => {
    this.setState({ disableButton: true });
  };

  enableNextStepButton = () => {
    this.setState({ disableButton: false });
  };

  handleTypeChange = (value) => {
    this.setState({ type: value }, () => {
      this.disableNextButton();
    });
  };

  handleMessageChange = (value) => {
    this.setState({ message: value }, () => {
      value === "" ? this.disableNextButton() : this.enableNextStepButton();
    });
  };

  handleModuleChange = (value, tableContent, showContentSelect) => {
    this.setState({
      module_id: value,
      tableContent: tableContent,
      showContentSelect: showContentSelect,
    });
  };

  handleDeviceChange = (value) => {
    this.setState({ segement_device: value });
  };

  handleUserTypeChange = (value) => {
    this.setState({ user_type: value });
  };

  handleGenderChange = (value) => {
    this.setState({ segement_gender: value });
  };

  handleMinAgeChange = (value) => {
    this.setState({ minAge: value });
  };

  handleMaxAgeChange = (value) => {
    this.setState({ maxAge: value });
  };

  handleAgeCheckChange = (value) => {
    if (value) this.setState({ ageChecked: value, minAge: 13, maxAge: 99 });
    else this.setState({ ageChecked: value, minAge: 13, maxAge: 99 });
  };

  handleContentChange = (value) => {
    this.setState({ contentID: value }, () => {
      value === "" ? this.disableNextButton() : this.enableNextStepButton();
    });
  };

  handeDeepLinkChange = (value) => {
    this.setState({ deepLink: value });
  };

  handleChangeDateTime = (value) => {
    if (value)
      this.setState({ sendDateTime: value }, this.enableNextStepButton());
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  handleTimeChange = (time) => {
    this.setState({ time });
  };

  handleTitleChange = (title) => {
    this.setState({ title });
  };

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStep
            tableContent={this.state.tableContent}
            showContentSelect={this.state.showContentSelect}
            contentID={this.state.contentID}
            modules={this.props.modules}
            type={this.state.type}
            module_id={this.state.module_id}
            message={this.state.message}
            title={this.state.title}
            handleDialogTypeChange={this.handleTypeChange}
            handleDialogMessageChange={this.handleMessageChange}
            handleDialogTitleChange={this.handleTitleChange}
            handleDialogModuleChange={this.handleModuleChange}
            handleDialogContentChange={this.handleContentChange}
            handleDialogDeepLinkChange={this.handeDeepLinkChange}
          />
        );
      case 1:
        return (
          <SecondStep
            segement_device={this.state.segement_device}
            segement_gender={this.state.segement_gender}
            minAge={this.state.minAge}
            maxAge={this.state.maxAge}
            ageChecked={this.state.ageChecked}
            filtrationType={this.state.filtrationType}
            user_id={this.state.user_id}
            users={this.props.users}
            user_type={this.state.user_type}
            handleDialogDeviceChange={this.handleDeviceChange}
            handleUserTypeChange={this.handleUserTypeChange}
            handleDialogGenderChange={this.handleGenderChange}
            handleDialogMinAgeChange={this.handleMinAgeChange}
            handleDialogMaxAgeChange={this.handleMaxAgeChange}
            handleDialogAgeChecked={this.handleAgeCheckChange}
            enableNextStepButton={this.enableNextStepButton}
            disableNextButton={this.disableNextButton}
            handleFilterationType={this.handleFilterationType}
            handleUserChange={this.handleUserChange}
          />
        );
      case 2:
        return (
          <ThirdStep
            date={this.state.date}
            time={this.state.time}
            type={this.state.scheduleType}
            handleScheduleTimeType={this.handleScheduleTimeType}
            handleDateChange={this.handleDateChange}
            handleTimeChange={this.handleTimeChange}
            handleChangeDateTime={this.handleChangeDateTime}
            enableNextStepButton={this.enableNextStepButton}
            disableNextButton={this.disableNextButton}
          />
        );
      case 3:
        return (
          <StepsResult
            device={this.state.segement_device}
            gender={this.state.segement_gender}
            module_id={this.state.module_id}
            min={this.state.minAge}
            max={this.state.maxAge}
            message={this.state.message}
            sendDateTime={this.state.sendDateTime}
            scheduleType={this.state.scheduleType}
            filtrationType={this.state.filtrationType}
            user_id={this.state.user_id}
            users={this.props.users}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  handleClose = () => {
    this.handleReset();
    this.setState(
      {
        isOpen: false,
        activeStep: 0,
        type: "",
        segement_device: 0,
        segement_gender: 0,
        minAge: 13,
        maxAge: 99,
        message: "",
        deepLink: "",
        sendDateTime: "",
        disableButton: true,
        ageChecked: false,
        stepsNumber: 3,
        contentID: "",
        openSnackBar: false,
        snackbarMessage: "",
        date: "",
        time: "",
        scheduleType: false,
        user_id: null,
        filtrationType: 0,
        title: "",
        user_type: [1, 2, 3],
        module_id: null,
      },
      () => {
        this.props.handleClose();
      }
    );
  };

  handleStepsSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  disableNextButton = () => {
    this.setState({ disableButton: true });
    return true;
  };

  handleSnackBarClose = () => {
    this.setState({ openSnackBar: false });
  };

  createNotification = () => {
    let {
      segement_device,
      segement_gender,
      minAge,
      maxAge,
      message,
      title,
      module_id,
      scheduleType,
      sendDateTime,
      user_type,
      contentID,
      deepLink,
      filtrationType,
      user_id,
    } = this.state;
    let formData = new FormData();
    let filters = {};
    if (filtrationType == 1) {
      filters = {
        id: user_id,
      };
    } else {
      filters = {
        devices: segement_device,
        gender: segement_gender,
        ageMin: minAge,
        ageMax: maxAge,
        type: user_type,
      };
    }

    formData.append("filters", JSON.stringify(filters));
    if (message) formData.append("message", message);
    if (contentID) formData.append("content_id", contentID);
    if (deepLink) formData.append("deep_link", deepLink);
    if (module_id) formData.append("module_id", module_id);
    if (title) formData.append("title", title);
    if (!scheduleType) {
      let formattedDate = moment(sendDateTime).format("YYYY-MM-DD HH:mm:ss");
      formData.append("send_date_time", formattedDate);
    }
    this.handleClose();
    this.props.createNotification(formData);
  };

  handleScheduleTimeType = (scheduleType) => {
    this.setState({ scheduleType });
  };

  handleFilterationType = (filtrationType) => {
    this.setState({ filtrationType });
  };

  handleUserChange = (user_id) => {
    this.setState({ user_id });
  };
  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              {/* <Button color="inherit" onClick={this.handleClose}>
                save
              </Button> */}
            </Toolbar>
          </AppBar>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className={classes.stepsContent}>
            {this.state.activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                {/* <Button onClick={this.handleReset}>Reset</Button> */}
              </div>
            ) : (
              <div>
                {/* <Typography className={classes.instructions}> */}
                {this.getStepContent(activeStep)}
                {/* </Typography> */}
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    disabled={this.state.disableButton}
                    className={classes.btnRight}
                  >
                    {activeStep === steps.length - 1 ? "Send" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.openSnackBar}
          onClose={this.handleSnackBarClose}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}

NotificationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(NotificationDialog);
