import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DateFormatInput, TimeFormatInput } from "material-ui-next-pickers";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    width: "50%"
  },
  fullLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "100%"
  }
});

class ThirdStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      type: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.props.disableNextButton();
  }

  onChangeDate = date => {
    this.setState({ date }, () => {
      this.props.handleDateChange(date);
      this.setDateTime();
    });
  };

  onChangeTime = time => {
    this.setState({ time }, () => {
      this.props.handleTimeChange(time);
      this.setDateTime();
    });
  };

  setDateTime = () => {
    let { date, time } = this.state;
    if (date && time) {
      time = new Date(time);
      time =
        time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();
      date = new Date(date);
      date =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let dateTime = date + " " + time;
      if (isNaN(Date.parse(dateTime)) === false) {
        dateTime = new Date(dateTime);
        if (dateTime.getTime() < new Date().getTime()) {
          this.setState({ errorMessage: "Time cannot be in past" }, () => {
            this.props.disableNextButton();
          });
        } else {
          this.setState({ errorMessage: null });
          this.props.handleChangeDateTime(dateTime);
        }
      }
    }
  };

  handleTypeChange = event => {
    let checked = event.target.checked;
    if (checked) {
      this.setState({ type: checked, date: null, time: null }, () => {
        this.props.handleScheduleTimeType(checked);
        this.props.handleDateChange(null);
        this.props.handleTimeChange(null);
        this.props.enableNextStepButton();
      });
    } else {
      this.setState({ type: checked }, () => {
        this.props.handleScheduleTimeType(checked);
        this.props.disableNextButton();
      });
    }
  };

  render() {
    const { classes, date, time, type } = this.props;
    const { errorMessage } = this.state;
    return (
      <div>
        <FormControl className={classes.fullLength}>
          <FormControlLabel
            label="Send Now"
            control={
              <Checkbox checked={type} onChange={this.handleTypeChange} />
            }
          />
        </FormControl>
        {!type && (
          <div classes={classes.root}>
            <FormControl className={classes.formControl}>
              <DateFormatInput
                fullWidth
                min={new Date()}
                label="Choose Date"
                value={date}
                onChange={this.onChangeDate}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TimeFormatInput
                fullWidth
                label="Choose Time"
                value={time}
                onChange={this.onChangeTime}
              />
            </FormControl>
            {errorMessage && <h4>{errorMessage}</h4>}
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(ThirdStep);
