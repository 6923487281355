import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Mates extends Component {
  render() {
    const { classes, mates, open, handleClose } = this.props;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Home Mates
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" padding="none">
                  Name
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Phone
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Relation
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  Unit Code
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mates.map(item => (
                <TableRow>
                  <TableCell component="th" scope="row" padding="none">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.phone_number}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.relationship}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.unit_code}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}
export default withStyles(styles)(Mates);
