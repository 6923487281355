import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../components/AuthService";
import SnackBar from "../../components/SnackBar";
import TimeRangesFormDialog from "./TimeRangesFormDialog";
import TimeRangesListing from "./TimeRangesListing";
import FAB from "../../components/FAB";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import { API_URL, headers } from "../../constants/Constants";
class TimeRanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ranges: [],
      currentRanges: null,
      selectedRanges: [],
      openTimeRangesFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Ranges",
      reset: false,
      errors: {
        from: [],
        to: [],
        is_active: []
      }
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    axios
      .get(API_URL + "timeRanges", {
        headers: headers
      })
      .then(response => {
        return response.data;
      })
      .then(response => {
        let ranges = response.data;
        if (ranges) this.setState({ ranges: ranges, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_ranges")) {
      this.setState({
        snackBarMessage: "You don't have permission to create ranges",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Add Ranges",
        openTimeRangesFormDialog: true,
        reset: true
      });
    }
  };

  handleCloseRangesFormDialog = () => {
    this.setState({
      openTimeRangesFormDialog: false,
      currentRanges: null,
      errors: {
        from: [],
        to: [],
        is_active: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = request => {
    let newData = this.state.ranges;
    newData.push(request);
    this.setState({ ranges: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "timeRanges", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openTimeRangesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentRanges: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openTimeRangesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = request => {
    let newData = this.state.ranges;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === request.id) {
        newData[i] = request;
        break;
      }
    }
    this.setState({ ranges: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentRanges.id;
    axios
      .post(API_URL + "timeRanges/" + id, formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openTimeRangesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentRanges: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openTimeRangesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = request => {
    if (!checkAuthorization("can_edit_ranges")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit ranges",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Ranges",
        currentRanges: request,
        openTimeRangesFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = () => {
    this.handleRefreshing(true);
    let id = this.state.currentRanges.id;
    axios
      .delete(API_URL + "timeRanges/" + id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openTimeRangesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentRanges: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openTimeRangesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.ranges;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ ranges: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedTimeRanges = selectedRanges => {
    if (!checkAuthorization("can_delete_ranges")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete ranges",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedRanges: selectedRanges
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted.length < 1) {
      let formData = new FormData();
      formData.append("itemsids", JSON.stringify(toBeDeleted));
      axios
        .post(API_URL + "timeRanges/batchDelete", formData, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openTimeRangesFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentRanges: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openTimeRangesFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    } else {
      axios
        .delete(API_URL + "timeRanges/" + toBeDeleted, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openTimeRangesFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentRanges: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openTimeRangesFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <TimeRangesListing
          tableTitle="TimeRanges"
          data={this.state.ranges}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <TimeRangesFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseRangesFormDialog}
          isOpen={this.state.openTimeRangesFormDialog}
          request={this.state.currentRanges}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          ranges={this.state.ranges}
          errors={this.state.errors}
          reset={this.state.reset}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isRefreshing && <RefreshLoader />}
        {this.state.isLoading && <Loader />}
      </Fragment>
    );
  }
}

export default TimeRanges;
