import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { API_URL } from "../../constants/Constants";
import RefreshLoader from "../../components/RefreshLoader";
import TinyMce from "../../components/TinyMce";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    minWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class FirstStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      module_id: "",
      title: "",
      message: "",
      contentID: "",
      showContentSelect: false,
      tableContent: [],
      deepLink: "",
      customMessage: "",
      isRefreshing: false,
    };

    this.state = this.props;
  }

  handleModuleChange = (event) => {
    let value = event.target.value;
    if (value) {
      // let title = this.props.modules.find(x => x.id == value).title;
      this.setState({ module_id: value, isRefreshing: true }, () => {
        axios
          .get(API_URL + "notifications/getContentByModule/" + value)
          .then((resposne) => {
            return resposne.data;
          })
          .then((data) => {
            this.setState(
              {
                tableContent: data.data,
                showContentSelect: true,
                isRefreshing: false,
              },
              () => {
                this.props.handleDialogModuleChange(
                  value,
                  this.state.tableContent,
                  this.state.showContentSelect
                );
              }
            );
          })
          .catch((error) => {
            this.setState({ isRefreshing: false });
          });
      });
    }
  };

  handleTypeChange = (event) => {
    let value = event.target.value;
    if (value === 1) {
      this.setState({ showContentSelect: false, tableContent: [] }, () => {
        this.props.handleDialogModuleChange("", [], false);
        if (this.state.customMessage) {
          this.setState({ message: this.state.customMessage }, () => {
            this.props.handleDialogMessageChange(this.state.message);
          });
        }
      });
    } else if (value === 2) {
      this.setState({ message: "", customMessage: "" }, () => {
        this.props.handleDialogMessageChange("");
      });
    } else if (value === "") {
      this.setState({ showContentSelect: false, tableContent: [] });
    }
    this.setState({ type: event.target.value }, () => {
      this.props.handleDialogTypeChange(value);
    });
  };

  handleTitleChanged = (event) => {
    let value = event.target.value;
    this.setState({ title: value }, () => {
      this.props.handleDialogTitleChange(value);
    });
  };

  handleMessageChanged = (input) => {
    // let value = input.target.getContent();
    let value = input.target.value;
    this.setState({ message: value, customMessage: value }, () => {
      this.props.handleDialogMessageChange(value);
    });
  };

  handleContentChange = (event) => {
    let value = event.target.value;
    this.setState({ contentID: value }, () => {
      this.props.handleDialogContentChange(value);
    });
  };

  handleDeepLinkChanged = (event) => {
    let value = event.target.value;
    this.setState({ deepLink: value }, () => {
      this.props.handleDialogDeepLinkChange(value);
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.isRefreshing && <RefreshLoader />}
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="type">
            Please Select Notification Type
          </InputLabel>
          <Select
            value={this.state.type}
            onChange={this.handleTypeChange}
            input={<Input name="type" />}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value={1} selected={this.state.type === 1}>
              Custom Message
            </MenuItem>
            <MenuItem value={2} selected={this.state.type === 2}>
              Exsisting Module
            </MenuItem>
          </Select>
        </FormControl>
        {this.state.type === 1 && (
          <FormControl className={classes.formControl}>
            <TextField
              label="Title"
              value={this.state.title}
              onChange={this.handleTitleChanged}
              className={classes.textField}
              margin="normal"
            />
          </FormControl>
        )}

        {this.state.type === 2 && (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="module">Please Select Module</InputLabel>
            <Select
              value={this.state.module_id}
              onChange={this.handleModuleChange}
              input={<Input name="module_id" />}
            >
              <MenuItem value="">None</MenuItem>
              {this.props.modules.map((module) => (
                <MenuItem value={module.id} key={"module_" + module.id}>
                  {module.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {this.state.showContentSelect && (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="contentID">
              Please Select Notification Content
            </InputLabel>
            <Select
              value={this.state.contentID}
              onChange={this.handleContentChange}
              input={<Input name="contentID" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {this.state.tableContent.map((item) => {
                return <MenuItem value={item.id}>{item.title}</MenuItem>;
              })}
            </Select>
          </FormControl>
        )}
        {this.state.type === 1 && (
          // <TinyMce
          //   title="Message"
          //   data={this.state.message}
          //   handleChange={this.handleMessageChanged}
          // />
          <FormControl className={classes.formControl}>
            <TextField
              id="multiline-static"
              label="Message"
              multiline
              rows="4"
              value={this.state.message}
              onChange={this.handleMessageChanged}
              className={classes.textField}
              margin="normal"
            />
          </FormControl>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(FirstStep);
