import React, { Component, Fragment } from "react";
import axios from "axios";
import { checkAuthorization } from "../../../components/AuthService";
import SnackBar from "../../../components/SnackBar";
import CategoriesFormDialog from "./MainCategoriesFormDialog";
import CategoriesListing from "./MainCategoriesListing";
import FAB from "../../../components/FAB";
import Loader from "../../../components/Loader";
import RefreshLoader from "../../../components/RefreshLoader";
import { API_URL, headers } from "../../../constants/Constants";
class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      projects: [],
      currentCategory: null,
      selectedCategory: [],
      openCategoriesFormDialog: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      isLoading: true,
      isRefreshing: false,
      dialogTitle: "Create New Category",
      reset: false,
      errors: {
        title: [],
        title_ar: [],
        icon: [],
        is_active: [],
        sort: [],
        orchestra_id: []
      }
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    axios
      .get(API_URL + "mainServicesCategories", {
        headers: headers
      })
      .then(response => {
        return response.data;
      })
      .then(response => {
        let categories = response.data;
        if (categories)
          this.setState({ categories: categories, isLoading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_services")) {
      this.setState({
        snackBarMessage: "You don't have permission to create categories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Add Category",
        openCategoriesFormDialog: true,
        reset: true
      });
    }
  };

  handleCloseCategoryFormDialog = () => {
    this.setState({
      openCategoriesFormDialog: false,
      currentCategory: null,
      errors: {
        title: [],
        title_ar: [],
        type: [],
        icon: [],
        sla_time: [],
        cc_emails: [],
        parent_id: [],
        visibility: [],
        project_id: [],
        description: [],
        in_home_screen: [],
        escalation_emails: [],
        is_active: [],
        sort: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = category => {
    let newData = this.state.categories;
    newData.push(category);
    this.setState({ categories: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "mainServicesCategories", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openCategoriesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentCategory: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openCategoriesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = category => {
    let newData = this.state.categories;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id === category.id) {
        newData[i] = category;
        break;
      }
    }
    this.setState({ categories: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentCategory.id;
    axios
      .post(API_URL + "mainServicesCategories/" + id, formData, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openCategoriesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentCategory: null,
            reset: true
          });
        }
      })
      .catch(err => {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openCategoriesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = category => {
    if (!checkAuthorization("can_edit_services")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit categories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Category",
        currentCategory: category,
        openCategoriesFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = () => {
    this.handleRefreshing(true);
    let id = this.state.currentCategory.id;
    axios
      .delete(API_URL + "mainServicesCategories/" + id, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openCategoriesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentCategory: null,
            reset: true
          });
        }
      })
      .catch(function(err) {
        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({ errors: errors, openCategoriesFormDialog: true });
          this.handleRefreshing(false);
        }
      });
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.categories;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ categories: newData });
    this.handleRefreshing(false);
  };

  deleteSlectedCategories = selectedCategory => {
    if (!checkAuthorization("can_delete_services")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete categories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedCategory: selectedCategory
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted.length < 1) {
      let formData = new FormData();
      formData.append("itemsids", JSON.stringify(toBeDeleted));
      axios
        .post(API_URL + "mainServicesCategories/batchDelete", formData, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openCategoriesFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentCategory: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openCategoriesFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    } else {
      axios
        .delete(API_URL + "mainServicesCategories/" + toBeDeleted, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.removeDeletedFromData(toBeDeleted);
            this.setState({
              openCategoriesFormDialog: false,
              reset: true,
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "success"
            });
          } else {
            this.setState({
              snackBarMessage: response.data.message,
              snackBarOpen: true,
              snackBarType: "error",
              isRefreshing: false,
              currentCategory: null,
              reset: true
            });
          }
        })
        .catch(function(err) {
          let response = err.response;
          if (response.status === 401 && response.data.success === false) {
            let messages = response.data.message;
            let errors = [];
            Object.entries(messages).map(([key, value]) => {
              errors[key] = value;
            });
            this.setState({ errors: errors, openCategoriesFormDialog: true });
            this.handleRefreshing(false);
          }
        });
    }
  };

  render() {
    return (
      <Fragment>
        <CategoriesListing
          tableTitle="Categories"
          data={this.state.categories}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
        />
        <CategoriesFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleCloseCategoryFormDialog}
          isOpen={this.state.openCategoriesFormDialog}
          category={this.state.currentCategory}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          errors={this.state.errors}
          reset={this.state.reset}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}

export default Categories;
