import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import {BASE_URL} from "../../../constants/Constants.js";
import {isImage} from "../../../components/FileValidation";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2
    },
    container: {
        paddingLeft: theme.spacing.unit * 20,
        paddingRight: theme.spacing.unit * 20
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    },
    uploadInput: {
        display: "none"
    },
    preview: {
        color: "white",
        maxWidth: "100%",
        width: "120px !important",
        maxHeight: "initial",
        padding: "0px !important",
        paddingTop: "-100% !important",
        position: "relative !important",
        marginBottom: " -40px",
        marginLeft: "20px"
    }
});
const userTypes = [{value:'1',name:'Owner'},
                    {value:'2',name:'Guest'},
                    {value:'3',name:'Secondary'}];



function Transition(props) {
    return <Slide direction="up" {...props}/>;
}

class AlbumItemsFormDialog extends Component {
	state = {
		isOpen: this.props.isOpen,
		ID: null,
		Image: '',
		uploadedImage: '',
		//same error format that comes from the backend
		errors: {
			asset: [],
		}
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.reset) {
			this.handleReset();
		}
		if (nextProps.isOpen !== this.state.isOpen) {
			this.setState({ isOpen: nextProps.isOpen });
		}

		if (nextProps.errors != this.state.errors) {
			this.setState({ errors: nextProps.errors });
		}

		let { albumID } = nextProps;
		if (albumID) {
			this.setState({
				ID: albumID
			});
		}
	}

	handleReset = () => {
		this.setState({
			ID: null,
			Image: '',
			uploadedImage: '',
		});
	};


	handleClose = () => {
		this.setState(
			{
				isOpen: false
			},
			() => {
				this.props.handleClose();
			}
		);
		this.handleReset();
	};




	handleSubmit = event => {
		event.preventDefault();
		let formData = new FormData(document.getElementById('albumsForm'));
        if (this.state.ID) formData.append('album_id', this.state.ID);
		if (this.state.uploadedImage) formData.append('asset', this.state.Image);
		this.props.handleAdd(formData);
		this.props.handleClose();
	};
	handleImageChange = event => {
		let errors = this.state.errors;
		let file = event.target.files[0];
		if (file) {
			if (isImage(file)) {
				errors.Image = [];
				this.setState({
					Image: file,
					uploadedImage: URL.createObjectURL(file)
				});
			} else {
				errors.asset = ['Please Upload Valid Image'];
			}
		} else {
			errors.asset = ['Nothing is Uploaded'];
		}
		this.setState({ errors: errors });
	};


	render() {
		const { classes } = this.props;
		let disabled = false;
		let mainImage = '';
		if (this.state.uploadedImage) mainImage = this.state.uploadedImage;
		else if (this.state.Image) mainImage = BASE_URL + this.state.Image;
		else mainImage = mainImage = 'temp.png';
		return (
			<div>
				<Dialog fullScreen open={this.state.isOpen} onClose={this.handleClose} TransitionComponent={Transition}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
								<CloseIcon />
							</IconButton>
							<Typography variant="title" color="inherit" className={classes.flex}>
								{this.props.dialogTitle}
							</Typography>
							<Button color="inherit" disabled={disabled} onClick={this.handleSubmit}>
								save
							</Button>
						</Toolbar>
					</AppBar>
					<div className={classes.root}>
						<form id="albumsForm" action="/" onSubmit={this.handleSubmit}>

								<Grid container spacing={24} className={classes.addMargins}>
									<Grid item xs={6}>
										<input
											accept="image/*"
											className={classes.uploadInput}
											id="contained-button-file"
											multiple
											type="file"
											onChange={event => this.handleImageChange(event)}
										/>
										<label htmlFor="contained-button-file">
											<Button
												variant="extendedFab"
												component="span"
												className={classes.buttonUpload}
												color="secondary"
											>
												Upload Photo
											</Button>
										</label>
										{this.props.errors.asset && (
											<Typography
												variant="subheading"
												gutterBottom
												color="error"
												className={classes.imageError}
											>
												{this.props.errors.asset[0]}
											</Typography>
										)}
									</Grid>
									<Grid item xs={6}>
										<img className={classes.preview} src={mainImage} />
									</Grid>
								</Grid>
						</form>
					</div>
				</Dialog>
			</div>
		);
	}
}

AlbumItemsFormDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    dialogTitle: PropTypes.string.isRequired,
    albumID: PropTypes.number
};

export default withStyles(styles)(AlbumItemsFormDialog);
