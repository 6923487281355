import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { API_URL, headers } from "../../constants/Constants";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import SnackBar from "../../components/SnackBar";
const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  button: {
    margin: theme.spacing.unit * 2,
    overflowX: "auto"
  }
});

class Settings extends Component {
  state = {
    ID: 1,
    isLoading: false,
    isRefreshing: false,
    settings: {},
    snackBarOpen: false,
    snackBarMessage: "",
    snackBarType: "success",
    errors: {
      SquarePerCleaner: [],
      MaxSquareMeter: [],
      StartVacationDay: [],
      EndVacationDay: [],
      MaxVacationPerMonth: []
    }
  };

  componentDidMount() {
    this.getSettings();
  }

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  getSettings() {
    this.handleLoading(true);
    axios
      .get(API_URL + "settings/getSettingsForAdmin")
      .then(response => {
        this.setState({ settings: response.data.data }, () => {
          this.handleLoading(false);
        });
      })
      .catch(function(error) {
        this.handleLoading(false);
        console.log(error);
      });
  }

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        [name]: value
      }
    }));
  };

  handleSubmit = event => {
    this.handleRefreshing(true);
    event.preventDefault();
    let formData = new FormData();
    formData.append("ID", this.state.ID);
    formData.append("SquarePerCleaner", this.state.settings.SquarePerCleaner);
    formData.append("MaxSquareMeter", this.state.settings.MaxSquareMeter);
    formData.append("StartVacationDay", this.state.settings.StartVacationDay);
    formData.append("EndVacationDay", this.state.settings.EndVacationDay);
    formData.append(
      "MaxVacationPerMonth",
      this.state.settings.MaxVacationPerMonth
    );
    formData.append("MorningSlot", this.state.settings.MorningSlot);
    formData.append("AfternoonSlot", this.state.settings.AfternoonSlot);

    axios
      .post(API_URL + "settings", formData, { headers: headers })
      .then(response => {
        return response.data;
      })
      .then(response => {
        if (response.res === 0) {
          this.setState({
            snackBarMessage: response.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false
          });
        } else {
          this.setState(
            {
              snackBarMessage: response.message,
              snackBarOpen: true,
              snackBarType: "success"
            },
            () => {
              this.handleRefreshing(false);
            }
          );
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  render() {
    const { classes } = this.props;
    const {
      SquarePerCleaner,
      MaxSquareMeter,
      StartVacationDay,
      EndVacationDay,
      MaxVacationPerMonth,
      MorningSlot,
      AfternoonSlot
    } = this.state.settings;
    return (
      <React.Fragment>
        {SquarePerCleaner && (
          <TextField
            id="SquarePerCleaner"
            name="SquarePerCleaner"
            label="Square Meter Per Cleaner"
            type="number"
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={SquarePerCleaner}
            error={
              this.state.errors.SquarePerCleaner &&
              this.state.errors.SquarePerCleaner.length > 0
            }
            helperText={
              this.state.errors.SquarePerCleaner &&
              this.state.errors.SquarePerCleaner[0]
            }
          />
        )}
        {(MaxSquareMeter || MaxSquareMeter == 0) && (
          <TextField
            id="MaxSquareMeter"
            name="MaxSquareMeter"
            label="Max Supported Square Meter ( 0 for infinite )"
            type="number"
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={MaxSquareMeter}
            error={
              this.state.errors.MaxSquareMeter &&
              this.state.errors.MaxSquareMeter.length > 0
            }
            helperText={
              this.state.errors.MaxSquareMeter &&
              this.state.errors.MaxSquareMeter[0]
            }
          />
        )}

        {StartVacationDay && (
          <TextField
            id="StartVacationDay"
            name="StartVacationDay"
            label="Start Day for Vacations"
            type="number"
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={StartVacationDay}
            error={
              this.state.errors.StartVacationDay &&
              this.state.errors.StartVacationDay.length > 0
            }
            helperText={
              this.state.errors.StartVacationDay &&
              this.state.errors.StartVacationDay[0]
            }
          />
        )}
        {EndVacationDay && (
          <TextField
            id="EndVacationDay"
            name="EndVacationDay"
            label="End Day for Vacations"
            type="number"
            max={30}
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={EndVacationDay}
            error={
              this.state.errors.EndVacationDay &&
              this.state.errors.EndVacationDay.length > 0
            }
            helperText={
              this.state.errors.EndVacationDay &&
              this.state.errors.EndVacationDay[0]
            }
          />
        )}
        {MaxVacationPerMonth && (
          <TextField
            id="MaxVacationPerMonth"
            name="MaxVacationPerMonth"
            label="End Day for Vacations"
            type="number"
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={MaxVacationPerMonth}
            error={
              this.state.errors.MaxVacationPerMonth &&
              this.state.errors.MaxVacationPerMonth.length > 0
            }
            helperText={
              this.state.errors.MaxVacationPerMonth &&
              this.state.errors.MaxVacationPerMonth[0]
            }
          />
        )}
        {MorningSlot && (
          <TextField
            id="MorningSlot"
            name="MorningSlot"
            label="End Day for Vacations"
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={MorningSlot}
            error={
              this.state.errors.MorningSlot &&
              this.state.errors.MorningSlot.length > 0
            }
            helperText={
              this.state.errors.MorningSlot && this.state.errors.MorningSlot[0]
            }
          />
        )}
        {AfternoonSlot && (
          <TextField
            id="AfternoonSlot"
            name="AfternoonSlot"
            label="End Day for Vacations"
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
            value={AfternoonSlot}
            error={
              this.state.errors.AfternoonSlot &&
              this.state.errors.AfternoonSlot.length > 0
            }
            helperText={
              this.state.errors.AfternoonSlot &&
              this.state.errors.AfternoonSlot[0]
            }
          />
        )}
        <Button
          variant="contained"
          size="medium"
          color="primary"
          className={classes.button}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Settings);
