import React, { Component } from "react";
import { withStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BASE_URL } from "../../../constants/Constants";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
  card: {
    maxWidth: 400
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
});

class Item extends Component {
	state = { expanded: false };
	constructor(props) {
		super(props);
	}
	handleDelete= item => {
		 this.props.delete(item);
	};



	handleExpandClick = () => {
		this.setState(state => ({ expanded: !state.expanded }));
	};

	render() {
		const { classes, item } = this.props;
		console.log(BASE_URL + item.asset);
		return (
			<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
				<Card className={classes.card}>
					<CardMedia
						className={classes.media}
						image={BASE_URL + item.asset}
					/>
					<CardActions className={classes.actions} disableActionSpacing>

						<IconButton
							className={classes.button}
							aria-label="Delete"
							onClick={() => this.handleDelete(item)}
						>
							<DeleteIcon />
						</IconButton>
					</CardActions>
				</Card>
			</Grid>
		);
	}
}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
  album: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default withStyles(styles)(Item);
