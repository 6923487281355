import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TinyMce from "../../components/TinyMceForm";
import Grid from "@material-ui/core/Grid";
import {BASE_URL} from "../../constants/Constants.js";
import {isImage} from "../../components/FileValidation";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },

  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },

  uploadInput: {
    display: "none"
  },
  addMargins: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FeedbackReasonsFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    reason: "",
    errors: {
      reason: [],

    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }
    if (nextProps.isOpen !== this.state.isOpen) {
      console.log(nextProps.isOpen);
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors !== this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.reason !== null) {
      let reason = nextProps.reason;
      console.log("reason form" , reason);

      this.setState({
        id: reason.id,
        reason: reason.reason,
      });
    } else {
      this.handleReset();
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {}

  handleReset = () => {
    this.setState({
      id: null,
      reason: "",

    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleReasonChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value === "") errors.reason = ["Please Enter Valid Value"];
    else errors.reason = "";
    this.setState({ reason: value, errors: errors });
  };


  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("reason", this.state.reason);
    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {reason} = this.state;
    if (reason === "") { disabled = true; }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="reasonForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Reason"
                      value={this.state.reason}
                      onChange={event => this.handleReasonChange(event)}
                      error={
                        this.state.errors.reason &&
                        this.state.errors.reason.length > 0
                      }
                      helperText={
                        this.state.errors.reason && this.state.errors.reason[0]
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

FeedbackReasonsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(FeedbackReasonsFormDialog);
