import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { BASE_URL } from "../../constants/Constants.js";
import { isicon } from "../../components/FileValidation";
const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  uploadInput: {
    display: "none"
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class PhoneCategoriesFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    name: "",
    icon: "",
    sort: 0,
    uploadedicon: null,
    errors: {
      name: [],
      icon: [],
      sort: []
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors != this.state.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.phoneCategories != this.state.phoneCategories)
      this.setState({ phoneCategories: nextProps.phoneCategories });

    let { phoneCategory } = nextProps;
    if (phoneCategory) {
      this.setState({
        id: phoneCategory.id,
        name: phoneCategory.name,
        icon: phoneCategory.icon,
        sort: phoneCategory.sort
      });
    }
  }

  handleReset = () => {
    this.setState({
      id: null,
      name: "",
      icon: "",
      sort: 0,
      uploadedicon: null
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handlenameChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value == "") errors.name = ["Please Enter Valid Value"];
    else errors.name = "";
    this.setState({ name: value });
  };

  handleiconChange = event => {
    let errors = this.state.errors;
    let file = event.target.files[0];
    if (file) {
      if (isicon(file)) {
        errors.icon = [];
        this.setState({
          icon: file,
          uploadedicon: file
        });
      } else {
        errors.icon = ["Please Upload Valid icon"];
      }
    } else {
      errors.icon = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleSortChange = event => {
    let value = event.target.value;
    if (value > 0) this.setState({ sort: value });
    else {
      let errors = this.state.errors;
      errors.sort = ["Sort number must be more than or equal 0"];
      this.setState({ errors: errors });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("name", this.state.name);
    if (this.state.uploadedicon)
      formData.append("icon", this.state.uploadedicon);

    if (this.state.sort) formData.append("sort", this.state.sort);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const { name, icon, sort } = this.state;
    if (name === "" || sort === "") {
      disabled = true;
    }

    let mainicon = "";
    if (this.state.uploadedicon)
      mainicon = URL.createObjectURL(this.state.uploadedicon);
    else if (this.state.icon) mainicon = BASE_URL + this.state.icon;
    else mainicon = mainicon = "temp.png";

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                disabled={disabled}
                onClick={this.handleSubmit}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <form id="phoneItemsForm" onSubmit={this.handleSubmit}>
              <Grid container spacing={24} className={classes.container}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Phone Category Name"
                    placeholder="Phone Category Name"
                    margin="normal"
                    fullWidth
                    onChange={this.handlenameChange}
                    value={this.state.name}
                    error={
                      this.state.errors.name &&
                      this.state.errors.name.length > 0
                    }
                    helperText={this.state.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Sort"
                    type="number"
                    value={this.state.sort}
                    onChange={this.handleSortChange}
                    error={
                      this.state.errors.sort &&
                      this.state.errors.sort.length > 0
                    }
                    helperText={
                      this.state.errors.sort && this.state.errors.sort[0]
                    }
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <input
                    accept="image/*"
                    className={classes.uploadInput}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={event => this.handleiconChange(event)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="extendedFab"
                      component="span"
                      className={classes.buttonUpload}
                      color="secondary"
                    >
                      Upload icon
                    </Button>
                  </label>
                  <img className={classes.preview} src={mainicon} />
                  {this.props.errors.icon && (
                    <Typography
                      variant="subheading"
                      gutterBottom
                      color="error"
                      className={classes.imageError}
                    >
                      {this.props.errors.icon[0]}
                    </Typography>
                  )}
                </Grid> */}
              </Grid>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

PhoneCategoriesFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
  // service: PropTypes.object
};

export default withStyles(styles)(PhoneCategoriesFormDialog);
