import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BASE_URL } from "../../constants/Constants";
import Fab from "@material-ui/core/Fab";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    paddingLeft: theme.spacing.unit * 20,
    paddingRight: theme.spacing.unit * 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  fab: {
    margin: theme.spacing.unit * 3
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class GeneralFeedbackFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    user_id: "",
    feedback_type: "",
    media_path: "",
    comment: "",
    status: "",
    // reason_id: "",
    userName: "",
    isModalOpen: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    let { currFeedback } = nextProps;

    this.setState({
      id: currFeedback !== null ? currFeedback.id : null,
      user_id:
        currFeedback !== null
          ? currFeedback.users !== null
            ? currFeedback.users.full_name
            : ""
          : "",
      feedback_type: currFeedback !== null ? currFeedback.feedback_type : "",
      media_path: currFeedback !== null ? currFeedback.media_path : "",
      comment: currFeedback !== null ? currFeedback.comment : "",
      status: currFeedback !== null ? currFeedback.status : ""
      // reason_id: currFeedback !== null ? currFeedback.reason_id : ""
    });
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.handleClose();
    });
  };

  handleStatusChange = event => {
    let value = event.target.value;
    this.setState({ status: value });
  };

  handleSubmit = event => {
    event.preventDefault();

    let formData = new FormData(document.getElementById("feedbackForm"));
    formData.append("status", this.state.status);

    if (this.state.id) {
      formData.append("id", this.state.id);
      this.props.handleClose();
      this.props.handleUpdateFeedback(formData);
    }
  };
  handleClickOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { classes, currFeedback } = this.props;
    let Image = "";
    if (this.state.media_path) Image = BASE_URL + this.state.media_path;
    else Image = Image = "temp.png";
    // console.log("id", this.state.id);
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">General Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>Feedback</DialogContentText>
            <form id="feedbackForm" action="/" onSubmit={this.handleSubmit}>
              <TextField
                id="user_id"
                name="user_id"
                label="User Name"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.user_id}
              />
              <TextField
                id="feedback_type"
                name="feedback_type"
                label="Feedback Type"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={
                  this.state.feedback_type == 1
                    ? "Photo"
                    : this.state.feedback_type == 2
                    ? "Text"
                    : "Others"
                }
              />
              {/* <InputLabel htmlFor="type">Media Path</InputLabel>
              <img style={{ width: "100%" }} src={Image} /> */}
              {this.state.media_path && (
                // <Button
                //   variant="outlined"
                //   color="primary"
                //   onClick={this.handleClickOpen}
                // >
                //   Open Feedback Image
                // </Button>
                <Fab
                  variant="extended"
                  aria-label="Add"
                  color="primary"
                  className={classes.fab}
                  onClick={this.handleClickOpen}
                >
                  Open Feedback Image
                </Fab>
              )}
              <Dialog
                open={this.state.isModalOpen}
                onClose={this.handleCloseModal}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Image</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <img style={{ width: "100%" }} src={Image} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseModal} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              <TextField
                id="comment"
                name="comment"
                label="Comment"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.comment}
                multiline
              />

              {/* <TextField
                id="reason_id"
                name="reason_id"
                label="Reason ID"
                className={classes.textField}
                margin="normal"
                fullWidth
                disabled
                value={this.state.reason_id}
              /> */}
              <FormControl className={classes.halfLength}>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleStatusChange}
                  inputProps={{ name: "status", id: "status" }}
                >
                  <MenuItem value={1}>Pending</MenuItem>
                  <MenuItem value={2}>In Progress</MenuItem>
                  <MenuItem value={3}>Closed</MenuItem>
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

GeneralFeedbackFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  currFeedback: PropTypes.object
};

export default withStyles(styles)(GeneralFeedbackFormDialog);
