import decode from "jwt-decode";

export function isLoggedIn() {
  const token = localStorage.getItem("jwtToken");
  return token && !isTokenExpired(token);
}

export function logout() {
  localStorage.removeItem("jwtToken");
  window.location.href = "/";
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export function getPermissions() {
  if (!isLoggedIn()) {
    return null;
  }

  return JSON.parse(localStorage.getItem("permissions"));
}

export function checkAuthorization(permission) {
  let permissions = getPermissions();

  return (
    permissions &&
    permissions.length > 0 &&
    permissions.indexOf(permission) !== -1
  );
}
