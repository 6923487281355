import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TinyMce from "../../components/TinyMceForm";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ImageUploader from "../../components/ImageUploader";
import { BASE_URL } from "../../constants/Constants.js";
import { isImage } from "../../components/FileValidation";
import Select from "@material-ui/core/Select";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  container: {
    padding: theme.spacing.unit * 2
  },

  halfLength: {
    margin: theme.spacing.unit * 2,
    minWidth: "calc(50% - 32px)"
  },
  fullLength: {
    marginTop: theme.spacing.unit * 2,
    minWidth: "100%"
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px"
  },

  uploadInput: {
    display: "none"
  },
  addMargins: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EventsFormDialog extends Component {
  state = {
    isOpen: false,
    ID: null,
    Title: "",
    Description: "",
    CoverPhoto: "",
    uploadedCoverPhoto: null,
    From: "",
    TO: "",
    projects: [],
    albums: [],
    ProjectID: "",
    AlbumID: "",
    errors: {
      title: [],
      description: [],
      cover_photo: [],
      from: [],
      to: [],
      project_id: [],
      album_id: []
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.handleReset();
    }
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.errors !== this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.errors !== this.state.errors)
      this.setState({ errors: nextProps.errors });

    if (nextProps.projects !== this.state.projects)
      this.setState({ projects: nextProps.projects });

    if (nextProps.albums !== this.state.albums)
      this.setState({ albums: nextProps.albums });

    if (nextProps.event !== null) {
      let event = nextProps.event;
      this.setState({
        ID: event.id,
        Title: event.title,
        Description: event.description,
        CoverPhoto: event.cover_photo,
        From: moment(event.from).format("YYYY-MM-DD"),
        TO: moment(event.to).format("YYYY-MM-DD"),
        ProjectID: event.project_id,
        AlbumID: event.album_id
      });
    } else {
      this.handleReset();
    }
  }

  handleReset = () => {
    this.setState({
      ID: null,
      Title: "",
      Description: "",
      CoverPhoto: "",
      uploadedCoverPhoto: null,
      From: "",
      TO: "",
      ProjectID: "",
      AlbumID: ""
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleTitleChange = event => {
    let value = event.target.value;
    let errors = this.state.errors;
    if (value === "") errors.Title = ["Please Enter Valid Value"];
    else errors.Title = "";
    this.setState({ Title: value, errors: errors });
  };

  handleDescriptionChange = event => {
    let value = event.target.value;
    this.setState({ Description: value });
  };

  handleFromChange = event => {
    let value = event.target.value;
    this.setState({ From: value });
  };

  handleToChange = event => {
    let value = event.target.value;
    this.setState({ TO: value });
  };

  handleImageChange = event => {
    let file = event.target.files[0];
    let errors = this.state.errors;
    if (file) {
      if (isImage(file)) {
        errors.CoverPhoto = [];
        this.setState({
          CoverPhoto: file,
          uploadedCoverPhoto: file
        });
      } else {
        errors.CoverPhoto = ["Please Upload Valid Image"];
      }
    } else {
      errors.CoverPhoto = ["Nothing is Uploaded"];
    }
    this.setState({ errors: errors });
  };

  handleSubmit = event => {
    console.log(this.state);
    event.preventDefault();
    let formData = new FormData();
    formData.append("title", this.state.Title);
    formData.append("description", this.state.Description);
    formData.append("from", this.state.From);
    formData.append("to", this.state.TO);
    formData.append("project_id", this.state.ProjectID);
    formData.append("album_id", this.state.AlbumID);
    if (this.state.uploadedCoverPhoto) {
      formData.append("cover_photo", this.state.uploadedCoverPhoto);
    }

    if (this.state.ID) {
      formData.append("id", this.state.ID);
      formData.append("_method", "put");
      this.props.handleUpdate(formData);
    } else {
      this.props.handleAdd(formData);
    }
    this.handleClose();
  };

  handleProjectChange = event => {
    this.setState({ ProjectID: event.target.value });
  };

  handleAlbumChange = event => {
    this.setState({ AlbumID: event.target.value });
  };

  render() {
    const { classes } = this.props;
    let disabled = false;
    const {
      Title,
      Description,
      CoverPhoto,
      From,
      TO,
      ProjectID,
      projects,
      AlbumID,
      albums
    } = this.state;
    if (
      Title === "" ||
      ProjectID === "" ||
      Description === "" ||
      CoverPhoto === "" ||
      From === "" ||
      TO === "" ||
      AlbumID === ""
    ) {
      disabled = true;
    }

    let mainImage = "";
    if (this.state.uploadedCoverPhoto)
      mainImage = URL.createObjectURL(this.state.uploadedCoverPhoto);
    else if (this.state.CoverPhoto)
      mainImage = BASE_URL + this.state.CoverPhoto;
    else mainImage = mainImage = "temp.png";

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={() => this.handleClose()}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {this.props.dialogTitle}
              </Typography>
              <Button
                color="inherit"
                onClick={event => this.handleSubmit(event)}
                disabled={disabled}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <form
              id="EventForm"
              onSubmit={event => this.handleSubmit(event)}
              className={classes.root}
            >
              <div className={classes.container}>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Title"
                      value={this.state.Title}
                      onChange={event => this.handleTitleChange(event)}
                      error={
                        this.state.errors.title &&
                        this.state.errors.title.length > 0
                      }
                      helperText={
                        this.state.errors.title && this.state.errors.title[0]
                      }
                    />

                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth={false}
                      label="From"
                      value={this.state.From}
                      onChange={event => this.handleFromChange(event)}
                      error={
                        this.state.errors.from &&
                        this.state.errors.from.length > 0
                      }
                      helperText={
                        this.state.errors.from && this.state.errors.from[0]
                      }
                    />

                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth={false}
                      label="To"
                      value={this.state.TO}
                      onChange={event => this.handleToChange(event)}
                      error={
                        this.state.errors.to && this.state.errors.to.length > 0
                      }
                      helperText={
                        this.state.errors.to && this.state.errors.to[0]
                      }
                    />
                    <InputLabel htmlFor="project-simple">Project</InputLabel>
                    <Select
                      value={ProjectID}
                      onChange={this.handleProjectChange}
                      inputProps={{
                        name: "project",
                        id: "project-simple"
                      }}
                      error={
                        this.state.errors.project_id &&
                        this.state.errors.project_id.length > 0
                      }
                      helperText={
                        this.state.errors.project_id &&
                        this.state.errors.project_id[0]
                      }
                    >
                      {projects &&
                        projects.length > 0 &&
                        projects.map((project, index) => (
                          <MenuItem key={"project_" + index} value={project.id}>
                            {project.title}
                          </MenuItem>
                        ))}
                    </Select>

                    <InputLabel htmlFor="albun-simple">Album</InputLabel>
                    <Select
                      value={AlbumID}
                      onChange={this.handleAlbumChange}
                      inputProps={{
                        name: "album",
                        id: "album-simple"
                      }}
                      error={
                        this.state.errors.album_id &&
                        this.state.errors.album_id.length > 0
                      }
                      helperText={
                        this.state.errors.album_id &&
                        this.state.errors.album_id[0]
                      }
                    >
                      {albums &&
                        albums.length > 0 &&
                        albums.map((album, index) => (
                          <MenuItem key={"album_" + index} value={album.id}>
                            {album.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={24} className={classes.addMargins}>
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      className={classes.uploadInput}
                      id="image-input"
                      type="file"
                      onChange={event => this.handleImageChange(event)}
                    />
                    <label htmlFor="image-input">
                      <Button
                        variant="extendedFab"
                        component="span"
                        className={classes.buttonUpload}
                        color="secondary"
                      >
                        Cover Photo
                      </Button>
                    </label>
                    <img className={classes.preview} src={mainImage} alt="" />
                    {this.state.errors.cover_photo && (
                      <Typography
                        variant="subheading"
                        gutterBottom
                        color="error"
                      >
                        {this.state.errors.cover_photo[0]}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <TextField
                  fullWidth={true}
                  label="Description"
                  value={this.state.Description}
                  onChange={event => this.handleDescriptionChange(event)}
                  error={
                    this.state.errors.description &&
                    this.state.errors.description.length > 0
                  }
                  helperText={
                    this.state.errors.description &&
                    this.state.errors.description[0]
                  }
                />
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

EventsFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired
};

export default withStyles(styles)(EventsFormDialog);
