import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  }
});

class ConstructionUpdatesFormDialog extends Component {
  state = {
    isOpen: this.props.isOpen,
    id: null,
    message: "",
    album_id: "",
    albums: []
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.albums !== this.state.albums)
      this.setState({ albums: nextProps.albums });

    let { currConstructionUpdates } = nextProps;
    if (currConstructionUpdates) {
      if (currConstructionUpdates.id != this.state.id) {
        console.log(currConstructionUpdates);
        this.setState({
          id: currConstructionUpdates.id,
          message: currConstructionUpdates.message,
          album_id: currConstructionUpdates.album_id
        });
      }
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.handleClose();
    });
  };

  handleMessageChange = event => {
    this.setState({ message: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();

    let formData = new FormData(document.getElementById("constructionupdates"));

    if (this.state.id) {
      formData.append("id", this.state.id);
      formData.append("message", this.state.message);
      formData.append("album_id", this.state.album_id);
      this.props.handleClose();
      this.props.handleUpdateConstructionUpdates(formData);
    }
  };

  handleAlbumChange = event => {
    this.setState({ album_id: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Construction Updates</DialogTitle>
          <DialogContent>
            <form
              id="constructionupdates"
              action="/"
              onSubmit={this.handleSubmit}
            >
              <TextField
                margin="normal"
                id="message"
                label="message"
                placeholder="message"
                fullWidth
                onChange={this.handleMessageChange}
                value={this.state.message}
                className={classes.textField}
                multiline
              />
              <Select
                value={this.state.album_id}
                onChange={this.handleAlbumChange}
                inputProps={{
                  name: "album_id",
                  id: "album-simple"
                }}
              >
                {this.state.albums &&
                  this.state.albums.length > 0 &&
                  this.state.albums.map((album, index) => (
                    <MenuItem key={"album_" + index} value={album.id}>
                      {album.title}
                    </MenuItem>
                  ))}
              </Select>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConstructionUpdatesFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  currConstructionUpdates: PropTypes.object
};

export default withStyles(styles)(ConstructionUpdatesFormDialog);
