import React, {Component} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Filters from "../../components/Filters";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {checkAuthorization} from "../../components/AuthService";
import HelpOutlined from "@material-ui/icons/HelpOutlined";

function getSorting(order, orderBy) {
  if (orderBy === "id") {
    return order === "desc"
      ? (a, b) => (parseInt(b[orderBy]) < parseInt(a[orderBy]) ? -1 : 1)
      : (a, b) => (parseInt(a[orderBy]) < parseInt(b[orderBy]) ? -1 : 1);
  } else if (orderBy === "created_at" || orderBy === "updated_at") {
    return order === "desc"
      ? (a, b) => (new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1)
      : (a, b) => (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1);
  }
}

const columnData = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "ID",
    sortable: true
  },
  {
    id: "message",
    numeric: false,
    disablePadding: true,
    label: "Message",
    sortable: false
  },
  {
    id: "sent",
    numeric: true,
    disablePadding: false,
    label: "Sent",
    sortable: false
  },
  {
    id: "send_date_time",
    numeric: false,
    disablePadding: true,
    label: "SendDateTime",
    sortable: false
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "CreatedOn",
    sortable: true
  },
  {
    id: "info",
    numeric: false,
    disablePadding: false,
    label: "Recipients",
    sortable: false
  },
  {
    id: "resend",
    numeric: false,
    disablePadding: false,
    label: "Re-Send",
    sortable: false
  }
];

let filters = [
  {
    Field: "sent",
    Type: "SELECT",
    Title: "Sent",
    Values: "",
    Operator: "EQUALS",
    Options: [{ title: "Sent", value: 1 }, { title: "Not Sent", value: 0 }]
  },
  {
    Field: "send_date_time",
    Label: "Send Date Time",
    Type: "DATE_RANGE",
    Values: [],
    Operator: "DATE_BETWEEN"
  },
  {
    Field: "created_at",
    Label: "Created On",
    Type: "DATE_RANGE",
    Values: [],
    Operator: "DATE_BETWEEN"
  }
];
class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.sortable ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <div>{column.label}</div>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  root2: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  root3: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class EnhancedTableToolbar extends Component {
  state = {
    status: "All",
    start: "",
    end: "",
    open: false
  };

  render() {
    const { numSelected, classes } = this.props;
    return (
      <div>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subheading">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="title" id="tableTitle">
                Notifications
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={this.props.delete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <React.Fragment />
            )}
          </div>
        </Toolbar>
        <div className={classes.root2}>
          <Filters
            allData={this.props.allData}
            filters={filters}
            handleDataChange={this.props.handleDataChange}
          />
        </div>
      </div>
    );
  }
}
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  tableWrapper: {
    overflowX: "auto"
  },
  resendButton: {
    marginLeft: theme.spacing.unit * 2
  }
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "CreatedOn",
      selected: [],
      sending: [],
      data: [],
      allData: [],
      page: 0,
      rowsPerPage: 5,
      filterBySent: null,
      start: "",
      end: "",
      openDeleteConfirmationDialog: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data, allData: nextProps.data });
  }

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleConfirmDelete = () => {
    let selected = this.state.selected;
    this.handleCloseDeleteConfirmationDialog();
    this.setState({ selected: [] }, () => {
      this.props.handleDeleteNoification(selected);
    });
  };

  deleteNotifications = () => {
    if (!checkAuthorization("can_delete_notifications")) {
      this.props.showSnackBarMessage(
        "You don't have permission to delete Notification",
        "error"
      );
    } else {
      this.setState({ openDeleteConfirmationDialog: true });
    }
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  // isSending = id => this.state.sending.indexOf(id) !== -1;
  isSending = id => this.props.resendID == id;

  handleResend = id => {
    if (!checkAuthorization("can_add_notifications")) {
      this.props.showSnackBarMessage(
        "You don't have permission to send Notification",
        "error"
      );
    } else {
      this.props.handleResend(id);
    }
  };

  handleDataChange = data => {
    this.setState({ data: data });
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <DeleteConfirmationDialog
          title="Delete Notification"
          text="Are you sure that you want to permanently delete this Notifications?"
          isOpen={this.state.openDeleteConfirmationDialog}
          handleCloseDeleteConfirmationDialog={
            this.handleCloseDeleteConfirmationDialog
          }
          handleConfirmDelete={this.handleConfirmDelete}
        />
        <EnhancedTableToolbar
          allData={this.state.allData}
          handleDataChange={this.handleDataChange}
          numSelected={selected.length}
          handleStatus={status => this.handleChangeSentFilter(status)}
          handleDate={(start, end) => this.handleDate(start, end)}
          delete={() => this.deleteNotifications()}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={(event, property) =>
                this.handleRequestSort(event, property)
              }
              rowCount={data.length}
            />
            <TableBody>
              {data
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  const isSending = this.isSending(n.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onClick={event => this.handleClick(event, n.id)}
                        />
                      </TableCell>
                      <TableCell numeric>{n.id}</TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.message}
                      </TableCell>
                      <TableCell numeric>
                        {n.Sent === "1" ? (
                          <DoneIcon color="primary" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.send_date_time ? n.send_date_time : n.created_text}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.created_text}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {n.sent == 1 ? (
                          <Tooltip title="Show Recipients">
                            <IconButton
                              className={classes.resendButton}
                              onClick={() => this.props.showRecipients(n.id)}
                              color="secondary"
                            >
                              <HelpOutlined />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          "scheduled"
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {checkAuthorization("can_add_notifications") && (
                          <React.Fragment>
                            {isSending ? (
                              <CircularProgress
                                className={classes.resendButton}
                              />
                            ) : (
                              <IconButton
                                className={classes.resendButton}
                                onClick={() => this.handleResend(n.id)}
                                color="secondary"
                              >
                                <RefreshIcon />
                              </IconButton>
                            )}
                          </React.Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnhancedTable);
