import React, { Component, Fragment } from "react";
import axios from "axios";
import PhoneCategoriesListing from "./PhoneCategoriesListing";
import PhoneCategoriesFormDialog from "./PhoneCategoriesFormDialog";
import Loader from "./../../components/Loader";
import FAB from "../../components/FAB";
import RefreshLoader from "./../../components/RefreshLoader";
import SnackBar from "./../../components/SnackBar";
import { API_URL, headers } from "./../../constants/Constants";
import { checkAuthorization } from "../../components/AuthService";

export default class PhoneCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneCategories: [],
      currentPhoneCategory: null,
      openPhoneCategoriesFormDialog: false,
      reset: false,
      isLoading: true,
      isRefreshing: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      errors: {
        name: [],
        icon: [],
        sort: []
      }
    };
  }

  componentDidMount() {
    this.getAllPhoneCategories();
  }

  getAllPhoneCategories = () => {
    axios
      .get(API_URL + "PhoneCategory/all", { headers: headers })
      .then(response => {
        let phoneCategories = response.data;
        this.setState({
          phoneCategories: phoneCategories,
          isLoading: false
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fabCallback = () => {
    if (!checkAuthorization("can_add_phone")) {
      this.setState({
        snackBarMessage: "You don't have permission to create phone directory",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Create New Phone Directory",
        openPhoneCategoriesFormDialog: true,
        reset: true
      });
    }
  };

  handleClosePhoneFormDialog = () => {
    this.setState({
      openPhoneCategoriesFormDialog: false,
      currentPhoneCategory: null,
      errors: {
        name: [],
        icon: []
      }
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  addToState = phone_category => {
    let newData = this.state.phoneCategories;
    newData.push(phone_category);
    this.setState({ phoneCategories: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "PhoneCategory/create", formData, { headers: headers })
      .then(response => {
        if (response.status === 201) {
          this.addToState(response.data.data);
          this.setState({
            openPhoneCategoriesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPhoneCategory: null,
            reset: true
          });
        }
      })
      .catch(err => {
        console.log(err);
        let response = err.response;

        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          console.log(errors);
          this.setState({
            errors: errors,
            openPhoneCategoriesFormDialog: true
          });
          this.handleRefreshing(false);
        }
      });
  };

  updateInState = phoneCategory => {
    let newData = this.state.phoneCategories;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == phoneCategory.id) {
        newData[i] = phoneCategory;
        break;
      }
    }
    this.setState({ phoneCategories: newData, isRefreshing: false });
  };

  handleUpdate = formData => {
    formData.append("_method", "put");
    this.setState({ isRefreshing: true, reset: false });
    let id = this.state.currentPhoneCategory.id;
    axios
      .post(API_URL + "PhoneCategory", formData, {
        headers: headers
      })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          this.updateInState(response.data.data);
          this.setState({
            openPhoneCategoriesFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success"
          });
        } else {
          this.setState({
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false,
            currentPhoneCategory: null,
            reset: true
          });
        }
      })
      .catch(err => {
        console.log(err);

        let response = err.response;
        if (response.status === 401 && response.data.success === false) {
          let messages = response.data.message;
          let errors = [];
          Object.entries(messages).map(([key, value]) => {
            errors[key] = value;
          });
          this.setState({
            errors: errors,
            openPhoneCategoriesFormDialog: true
          });
          this.handleRefreshing(false);
        }
      });
  };

  handleEdit = phoneCategory => {
    if (!checkAuthorization("can_edit_phone")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit phone directories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Edit Phone Directory",
        currentPhoneCategory: phoneCategory,
        openPhoneCategoriesFormDialog: true,
        reset: true
      });
    }
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);
    if (toBeDeleted instanceof Array) {
      let id;
      for (id of toBeDeleted) {
        axios
          .delete(API_URL + "PhoneCategory/" + id, {
            headers: headers
          })
          .then(response => {
            return response.data;
          })
          .then(response => {
            if (response.status === true) {
              this.setState({
                snackBarOpen: true,
                snackBarType: "success",
                snackBarMessage: response.message
              });
              this.removeDeletedFromData(toBeDeleted);
            } else if (response.status === false) {
              this.setState({
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: response.message
              });
            }
          });
      }
    } else {
      axios
        .delete(API_URL + "PhoneCategory/" + toBeDeleted, { headers: headers })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "success",
              snackBarMessage: response.data.message
            });
            this.removeDeletedFromData(toBeDeleted);
          } else if (response.status === 500) {
            this.setState({
              snackBarOpen: true,
              snackBarType: "error",
              snackBarMessage: response.data.message
            });
          }
        });
    }
  };

  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };

  removeDeletedFromData = items => {
    let data = this.state.phoneCategories;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ phoneCategories: newData });
    this.handleRefreshing(false);
  };

  deleteSelectedItems = selectedItems => {
    if (!checkAuthorization("can_delete_phone")) {
      this.setState({
        snackBarMessage:
          "You don't have permission to delete phone directories",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedItems: selectedItems
      });
    }
  };

  handleSnackBarMessage = (msg, type) => {
    this.setState({
      snackBarMessage: msg,
      snackBarType: type,
      snackBarOpen: true
    });
  };

  render() {
    return (
      <Fragment>
        <PhoneCategoriesListing
          tableTitle="Phone Categories"
          data={this.state.phoneCategories}
          orderBy="id"
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
          // handleSnackBarMessage={this.handleSnackBarMessage}
        />
        <PhoneCategoriesFormDialog
          dialogTitle={this.state.dialogTitle}
          handleClose={this.handleClosePhoneFormDialog}
          isOpen={this.state.openPhoneCategoriesFormDialog}
          phoneCategory={this.state.currentPhoneCategory}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
          handleLoading={this.handleLoading}
          handleRefreshing={this.handleRefreshing}
          phoneCategories={this.state.phoneCategories}
          errors={this.state.errors}
          reset={this.state.reset}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />{" "}
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}
