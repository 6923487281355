import React, { Component, Fragment } from "react";
import axios from "axios";
import AlbumsListing from "./../albums/AlbumsListing";
import AlbumItemsListing from "./../albumItems/AlbumItemsListing";
import AlbumsFormDialog from "./../albums/AlbumsFormDialog";
import Loader from "../../../components/Loader";
import FAB from "../../../components/FAB";
import RefreshLoader from "../../../components/RefreshLoader";
import SnackBar from "../../../components/SnackBar";
import { API_URL, headers } from "../../../constants/Constants";

import { checkAuthorization } from "../../../components/AuthService";

export default class Albums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: [],
      albumItems: [],
      currentAlbum: null,
      albumID: "",
      openAlbumsFormDialog: false,
      openAlbumItems: false,
      openDeleteConfirmationDialog: false,
      openDeleteSelectedConfirmationDialog: false,
      reset: false,
      isLoading: true,
      isRefreshing: false,
      snackBarOpen: false,
      snackBarMessage: "",
      snackBarType: "success",
      errors: {
        title: [],
        cover_photo: []
      }
    };
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.getAll();
      }
    );
  }

  fabCallback = () => {
    if (!checkAuthorization("can_add_albums")) {
      this.setState({
        snackBarMessage: "You don't have permission to create albums",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        dialogTitle: "Create New Album",
        currentAlbum: null,
        openAlbumsFormDialog: true
      });
    }
  };

  getAll = () => {
    axios
      .get(API_URL + "albums", { headers: headers })
      .then(response => {
        let albums = response.data.data;
        this.setState({
          albums: albums,
          isLoading: false
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getAlbumItems = id => {
    this.setState({
      isRefreshing: true
    });
    axios
      .get(API_URL + "galleries/" + id, { headers: headers })
      .then(response => {
        let albumItems = response.data;
        this.setState({
          albumItems: albumItems,
          albumID: id,
          isRefreshing: false,
          openAlbumItems: true
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleEdit = currentAlbum => {
    if (!checkAuthorization("can_edit_albums")) {
      this.setState({
        snackBarMessage: "You don't have permission to edit albums",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        currentAlbum: currentAlbum,
        openAlbumsFormDialog: true,
        dialogTitle: "Edit Album"
      });
    }
  };

  updateInState = album => {
    let newData = this.state.albums;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == album.id) {
        newData[i] = album;
        break;
      }
    }
    this.setState({ albums: newData, isRefreshing: false });
  };
  handleDelete = album => {
    if (!checkAuthorization("can_delete_albums")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete albums",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        currentAlbum: album,
        openDeleteConfirmationDialog: true
      });
    }
  };
  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ openDeleteConfirmationDialog: false });
  };

  handleConfirmDelete = toBeDeleted => {
    this.handleRefreshing(true);

    axios
      .delete(API_URL + "album/" + toBeDeleted, { headers: headers })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          this.setState({
            snackBarOpen: true,
            snackBarType: "success",
            snackBarMessage: response.data.message
          });
        }
        this.removeDeletedFromData(toBeDeleted);
      });
  };

  handleConfirmAlbumItemDelete = toBeDeleted => {
    this.handleRefreshing(true);

    axios
      .delete(API_URL + "gallery/" + toBeDeleted, { headers: headers })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          this.setState({
            snackBarOpen: true,
            snackBarType: "success",
            snackBarMessage: response.data.message
          });
        }
        this.removeDeletedItemFromData(toBeDeleted);
      });
  };

  deleteSelectedCategories = selectedAlbums => {
    if (!checkAuthorization("can_delete_albums")) {
      this.setState({
        snackBarMessage: "You don't have permission to delete albums",
        snackBarType: "error",
        snackBarOpen: true
      });
    } else {
      this.setState({
        openDeleteSelectedConfirmationDialog: true,
        selectedAlbums: selectedAlbums
      });
    }
  };
  removeDeletedFromData = items => {
    let data = this.state.albums;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ albums: newData });
    this.handleRefreshing(false);
  };

  removeDeletedItemFromData = items => {
    let data = this.state.albumItems;
    let newData = [];
    data.forEach((element, index) => {
      if (items instanceof Array) {
        let found = items.find(item => {
          return item === element.id;
        });
        if (found === undefined) {
          newData.push(element);
        }
      } else {
        newData = data;
        if (items === element.id) {
          newData.splice(index, 1);
        }
      }
    });
    this.setState({ albumItems: newData });
    this.handleRefreshing(false);
  };

  handleCloseDeleteSelectedConfirmationDialog = () => {
    this.setState({ openDeleteSelectedConfirmationDialog: false });
  };
  handleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleRefreshing = value => {
    this.setState({ isRefreshing: value });
  };
  addToState = album => {
    let newData = this.state.albums;
    newData.push(album);
    this.setState({ albums: newData, isRefreshing: false });
  };

  handleAdd = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "album", formData, {
        headers: headers
      })
      .then(response => {
        if (response.status == 201) {
          this.setState({
            openAlbumsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            isRefreshing: false
          });
          this.addToState(response.data.data);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.setState({
            reset: false,
            isRefreshing: false,
            openAlbumsFormDialog: true,
            errors: error.response.data.message
          });
        } else {
          this.setState({
            openAlbumsFormDialog: false,
            reset: true,
            snackBarMessage: error.response.statusText,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false
          });
        }
      });
  };

  handleUpdate = formData => {
    this.setState({ isRefreshing: true, reset: false });
    axios
      .post(API_URL + "album/" + this.state.currentAlbum.id, formData, {
        headers: headers
      })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          this.setState({
            openAlbumsFormDialog: false,
            reset: true,
            snackBarMessage: response.data.message,
            snackBarOpen: true,
            snackBarType: "success",
            isRefreshing: false
          });
          this.updateInState(response.data.data);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.setState({
            reset: false,
            isRefreshing: false,
            openAlbumsFormDialog: true,
            errors: error.response.data.message
          });
        } else {
          this.setState({
            openAlbumsFormDialog: false,
            reset: true,
            snackBarMessage: error.response.statusText,
            snackBarOpen: true,
            snackBarType: "error",
            isRefreshing: false
          });
        }
      });
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  handleCloseAlbumsFormDialog = () => {
    this.setState({
      openAlbumsFormDialog: false,
      currentAlbum: null,
      dialogTitle: "",
      errors: {
        title: [],
        cover_photo: []
      }
    });
  };

  handleCloseAlbumItems = () => {
    this.setState({
      openAlbumItems: false,
      albumItems: [],
      dialogTitle: ""
    });
  };

  handleSnackBarMessage = (message, type) => {
    this.setState({
      snackBarOpen: true,
      snackBarType: type,
      snackBarMessage: message
    });
  };

  handelGalleries = albumID => {
    this.getAlbumItems(albumID);
  };

  render() {
    console.log(this.state.openAlbumItems);
    return (
      <Fragment>
        <AlbumsListing
          data={this.state.albums}
          handleEdit={this.handleEdit}
          handleDelete={toBeDeleted => this.handleConfirmDelete(toBeDeleted)}
          deleteSelectedAlbums={this.deleteSelectedAlbums}
          handleSnackBarMessage={this.handleSnackBarMessage}
          handelGalleries={this.handelGalleries}
        />
        <AlbumItemsListing
          data={this.state.albumItems}
          albumID={this.state.albumID}
          handleDelete={toBeDeleted =>
            this.handleConfirmAlbumItemDelete(toBeDeleted)
          }
          handleSnackBarMessage={this.handleSnackBarMessage}
          isOpen={this.state.openAlbumItems}
          dialogTitle={"Album Items"}
          handleClose={this.handleCloseAlbumItems}
        />
        <AlbumsFormDialog
          dialogTitle={this.state.dialogTitle}
          isOpen={this.state.openAlbumsFormDialog}
          album={this.state.currentAlbum}
          handleUpdate={this.handleUpdate}
          handleAdd={this.handleAdd}
          handleClose={this.handleCloseAlbumsFormDialog}
          reset={this.state.reset}
          errors={this.state.errors}
        />
        <FAB callback={this.fabCallback} />
        <SnackBar
          type={this.state.snackBarType}
          open={this.state.snackBarOpen}
          autoHideDuration={4000}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackbar}
        />{" "}
        {this.state.isLoading && <Loader />}
        {this.state.isRefreshing && <RefreshLoader />}
      </Fragment>
    );
  }
}
